import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Cookies from "js-cookie";

import { AppContext } from "../context/AppContext";

import permoniqLogo from "../img/logo-inverse.svg";
import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const NavbarLogin = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const languageRef = useRef(null);
  const [showBackToApp, setShowBackToApp] = useState(false);

  const {
    isAdmin,
    selectedShop,
    setSelectedShop,
    userData,
    setUuid,
    setIsUserDataLoading,
    isLogged,
    appContentType,
    // Nově přidáme funkci pro nastavení zobrazení modalu
    setShowAdminModal,
  } = useContext(AppContext);

  useEffect(() => {
    if (
      userData?.shops &&
      Object.keys(userData.shops).length > 1 &&
      userData.shops.some((shop) => shop.status_create === "end") &&
      !location.pathname.includes("shop-option")
    ) {
      setShowBackToApp(true);
    }
  }, [userData]);

  const handleBackToApp = () => {
    const shopWithEndStatus = userData.shops.find(
      (shop) => shop.status_create === "end"
    );
    setIsUserDataLoading(true);
    setUuid(shopWithEndStatus.uuid);
    setSelectedShop(shopWithEndStatus);
    sessionStorage.setItem("x23uU09I098D", shopWithEndStatus.uuid);
    setTimeout(() => {
      navigate(`/${lng}/app/dashboard/`);
    }, 1000);
  };

  const handleLanguageChange = (language) => {
    const currentPath = location.pathname.replace(`/${lng}`, `/${language}`);
    i18n.changeLanguage(language.toLowerCase());
    navigate(currentPath);
    setIsLanguageSwitchVisible(false);
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const handleClickOutside = (event) => {
    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Při kliknutí zavoláme funkci, která nastaví příznak pro zobrazení modalu
  const handleShowAdminModal = () => {
    setShowAdminModal(true);
  };

  return (
    <div className="container" data-navbar="login">
      <div className="header__logo">
        <a
          href={`${window.location.origin}/${lng}/`}
          title={t("loginNav.home")}
        >
          <img
            src={permoniqLogo}
            alt="PermoniQ"
            width="151"
            height="32"
            onClick={() => {
              if (location.pathname.includes("two-factor")) {
                Cookies.remove("aTkn_0yD9K");
              }
            }}
          />
        </a>
      </div>
      <nav className="app-nav-header">
        {isAdmin &&
          location.pathname.split("/")[3] !== "users" &&
          selectedShop &&
          Object.keys(selectedShop).length > 0 && (
            <>
              <div style={{ cursor: "pointer" }} onClick={handleShowAdminModal}>
                {selectedShop.name || selectedShop.website}
              </div>
            </>
          )}
        {location.pathname.split("/")[3] === "users" && (
          <span
            className="link"
            onClick={() => {
              navigate(`/${lng}/app/shop-option/`);
            }}
          >
            Zpět do výběru
          </span>
        )}
        {showBackToApp && (
          <div className="back__to__app">
            <span onClick={handleBackToApp} className="link">
              {t("loginNav.backToApp")}
            </span>
          </div>
        )}
      </nav>
    </div>
  );
};

export default NavbarLogin;
