import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "../css/Modal.module.css";

const Modal = ({
  title,
  children,
  onClose,
  onConfirm,
  confirmText,
  cancelText,
  isDelete,
  onDelete
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className={styles.container} onClick={onClose}>
      <div className={styles.modal_box} onClick={(e) => e.stopPropagation()}>
        <div className={styles.textBox}>
          <h2 className={styles.text}>{title}</h2>
          {children || <p>{t("modalConfirmChange.question")}</p>}
        </div>
        <div className={styles.btn_box}>
          <button className={`${styles.btn}`} onClick={onClose}>
            {cancelText || t("modalConfirmChange.cancel")}
          </button>
          {confirmText !== "" && (
            <button className={`${styles.btn}`} onClick={onConfirm}>
              {confirmText || t("modalConfirmChange.accept")}
            </button>
          )}
          {isDelete && (
            <button
              className={`${styles.btn} ${styles.delete} `}
              onClick={(e) => {
                e.target.blur();
                onDelete();
              }}
            >
              Vypnout
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
