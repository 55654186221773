import React from "react";

const CustomActiveDot = ({ cx, cy, small = false, stroke, fill }) => {
  return (
    <svg
      x={cx - 15} // upravte posun podle potřeby
      y={cy - 40} // upravte posun podle potřeby
      width="35"
      height="45"
      viewBox="0 0 44.336643 59.645264"
      version="1.1"
      id={small ? "svg2534-small" : "svg2534"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2531" />
      <g id="layer1">
        <g id={small ? "g4042-small" : "g4042"}>
          <path
            style={{
              fill: fill,
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 10,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            d="m -7.9,10.1 c 3.832243,3.8384 12.031157,3.99422 15.593388,0.11188 7.76529,-7.67734 3.30122,-16.76106 0.0737,-26.90711 -2.974694,-11.04942 -7.224124,-13.24935 -16.725816,-15.55759 12.221307,14.54582 -12.470226,27.01793 1.058738,42.35282 z"
            id="path4942"
            className="UnoptimicedTransforms"
          />
          <path
            style={{
              fill: "#ffffff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 10,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            d="m 4.5,11.45 c -1.9161215,1.9192 -6.209548,2.216975 -8.725819,0.12131 -2.91934,-3.359845 -0.721485,-8.4459 0.89228,-13.518925 1.487347,-5.52471 3.612062,-6.624675 8.362908,-7.778795 -6.1106535,7.27291 6.235113,13.508965 -0.529369,21.17641 z"
            id="path4942-7"
            className="UnoptimicedTransforms"
          />
        </g>
      </g>
    </svg>
  );
};

export default CustomActiveDot;
