import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import { useTranslation, Trans } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";

import SimpleAlert from "../components/SimpleAlert";
import ModalLoading from "../components/ModalLoading";
import ProgressTracker from "../components/ProgressTracker";

import axiosInstance from "../api/AxiosConfig";

import gtmLogo from "../img/logos/GTM.svg";
import shoptetLogo from "../img/logos/Shoptet.svg";
import upgatesLogo from "../img/logos/Upgates.svg";
import shopifyLogo from "../img/logos/Shopify.svg";
import woocommerceLogo from "../img/logos/WooCommerce.svg";
import prestashopLogo from "../img/logos/PrestaShop.svg";
import eshopRychleLogo from "../img/logos/Eshop-rychle.svg";
import shopsysLogo from "../img/logos/Shopsys.svg";
import webarealLogo from "../img/logos/Webareal.svg";
import nodyLogo from "../img/logos/Nody.svg";
import fastCentrikLogo from "../img/logos/logo-fascentrik_certify.png";

import useUserRegisterDetails from "../hooks/useUserRegisterDetails";

import ModalContactUs from "../components/ModalContactUs";
import Modal from "../components/Modal";
import InfoBoxItem from "../components/InfoBoxItem";

import sendEvent from "../utils/functions/sendEvent";
import { sendMessage } from "../utils/axios_functions/sendMessage";

import { FaRegCopy } from "react-icons/fa";

import styles from "../css/InstallTrackingCode.module.css";

const InstallTrackingCode = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [shopInPixel, setShopInPixel] = useState({});
  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});
  const [message, setMessage] = useState("Pracuju na tom");
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const {
    uuid,
    userData,
    setUserData,
    code,
    setCode,
    isUserDataLoading,
    setIsUserDataLoading,
    setVisitedInstallTrackingCode,
    appContentType,
    setAppContentType,
    setActiveItem,
    steps,
    isAlertVisible,
    showAlert,
    closeAlert,
    alertMessage,
    selectedShop,
    setSelectedShop,
    setIsBilling,
    setShowWelcomeModal,
    setShowModalAfterRegistration,
    existingCampaigns,
    setExistingCampaigns,
    showAdminModal,
    setShowAdminModal,
    infoItems,
  } = useContext(AppContext);

  useEffect(() => {
    sendEvent(`rg_${location.pathname.split("/")[3]}`);
  }, []);

  let gtmSteps = t("installTrackingCodeForm.gtmSteps", {
    returnObjects: true,
  });
  let shoptetSteps = t("installTrackingCodeForm.shoptetSteps", {
    returnObjects: true,
  });
  let upgatesSteps = t("installTrackingCodeForm.upgatesSteps", {
    returnObjects: true,
  });
  let shopifySteps = t("installTrackingCodeForm.shopifySteps", {
    returnObjects: true,
  });
  let woocommerceSteps = t("installTrackingCodeForm.woocommerceSteps", {
    returnObjects: true,
  });
  let prestashopSteps = t("installTrackingCodeForm.prestashopSteps", {
    returnObjects: true,
  });
  let eshopRychleSteps = t("installTrackingCodeForm.eshopRychleSteps", {
    returnObjects: true,
  });
  let shopsysSteps = t("installTrackingCodeForm.shopsysSteps", {
    returnObjects: true,
  });
  let webarealSteps = t("installTrackingCodeForm.webarealSteps", {
    returnObjects: true,
  });
  let nodySteps = t("installTrackingCodeForm.nodySteps", {
    returnObjects: true,
  });
  let fastCentrikSteps = t("installTrackingCodeForm.fastCentrikSteps", {
    returnObjects: true,
  });

  gtmSteps = Array.isArray(gtmSteps) ? gtmSteps : [];
  shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
  upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];
  shopifySteps = Array.isArray(shopifySteps) ? shopifySteps : [];
  woocommerceSteps = Array.isArray(woocommerceSteps) ? woocommerceSteps : [];
  prestashopSteps = Array.isArray(prestashopSteps) ? prestashopSteps : [];
  eshopRychleSteps = Array.isArray(eshopRychleSteps) ? eshopRychleSteps : [];
  shopsysSteps = Array.isArray(shopsysSteps) ? shopsysSteps : [];
  webarealSteps = Array.isArray(webarealSteps) ? webarealSteps : [];
  nodySteps = Array.isArray(nodySteps) ? nodySteps : [];
  fastCentrikSteps = Array.isArray(fastCentrikSteps) ? fastCentrikSteps : [];

  const faqItems = [
    {
      logo: gtmLogo,
      altText: t("installTrackingCodeForm.gtmTitle"),
      steps: gtmSteps,
      anchor: (
        <a
          href="https://www.shoptet.cz"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: shoptetLogo,
      altText: t("installTrackingCodeForm.shoptetTitle"),
      steps: shoptetSteps,
      anchor: (
        <a
          href="https://www.shoptet.cz"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: upgatesLogo,
      altText: t("installTrackingCodeForm.upgatesTitle"),
      steps: upgatesSteps,
      anchor: (
        <a
          href="https://www.upgates.cz"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: shopifyLogo,
      altText: t("installTrackingCodeForm.shopifyTitle"),
      steps: shopifySteps,
      anchor: (
        <a
          href="https://www.shopify.com"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: woocommerceLogo,
      altText: t("installTrackingCodeForm.woocommerceTitle"),
      steps: woocommerceSteps,
    },
    {
      logo: prestashopLogo,
      altText: t("installTrackingCodeForm.prestashopTitle"),
      steps: prestashopSteps,
      anchor: (
        <a
          href="https://www.prestashop.com"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: eshopRychleLogo,
      altText: t("installTrackingCodeForm.eshopRychleTitle"),
      steps: eshopRychleSteps,
      anchor: (
        <a
          href="https://www.eshop-rychle.cz"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: shopsysLogo,
      altText: t("installTrackingCodeForm.shopsysTitle"),
      steps: shopsysSteps,
      anchor: (
        <a
          href="https://www.shopsys.cz"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: webarealLogo,
      altText: t("installTrackingCodeForm.webarealTitle"),
      steps: webarealSteps,
      anchor: (
        <a
          href="https://www.shopsys.cz"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: nodyLogo,
      altText: t("installTrackingCodeForm.nodyTitle"),
      steps: nodySteps,
      anchor: (
        <a
          href="https://nody.cz/"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    },
    {
      logo: fastCentrikLogo,
      altText: t("installTrackingCodeForm.fastCentrikTitle"),
      steps: fastCentrikSteps,
      anchor: (
        <a
          href="https://www.fastcentrik.cz/"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        ></a>
      ),
    }
  ];

  useUserRegisterDetails();

  const answerRefs = useRef([]);
  const copyButtonRef = useRef(null);
  const urlID = window.location.pathname.split("/").slice(-2, -1)[0];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      Object.keys(userData).length > 0 &&
      Object.keys(userData.shops).length > 0
    ) {
      if (uuid) {
        setSelectedShop(
          userData.shops.find((oneShop) => oneShop.uuid === uuid)
        );
      }
    }
  }, [uuid]);

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     setIsUserDataLoading(true);
  //     try {
  //       const { data } = await axiosInstance.get(`shop/detail/${urlID}/`);
  //       setShop(data);
  //       setCountry(lng === 'en' ? 'cs' : lng);
  //     } catch (error) {
  //       console.error('Error fetching user details:', error);
  //       navigate(`/404/`);
  //     } finally {
  //       setIsUserDataLoading(false);
  //     }
  //   };

  //   fetchUserData();
  // }, [urlID, lng, navigate]);

  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  useEffect(() => {
    setHeights((prevHeights) => {
      const newHeights = { ...prevHeights };
      openIndices.forEach((index) => {
        if (answerRefs.current[index]) {
          newHeights[index] = answerRefs.current[index].scrollHeight;
        }
      });
      return newHeights;
    });
  }, [openIndices]);

  const handleToggle = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!code.trim()) {
      showAlert("Please add the tracking code");
      return;
    }
    window.scrollTo(0, 0);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code).then(() => {
      showAlert(
        t("installTrackingCodeForm.infoMessages.codeCopiedInfoMsg"),
        "info"
      );
      // copyButtonRef.current.blur();
    });
  };

  const updateShop = async (updates) => {
    try {
      setVisitedInstallTrackingCode(true);
      // Aktualizace konkrétního obchodu v poli `shops`
      const updatedShops = userData.shops.map((shop) =>
        shop.uuid === urlID ? { ...shop, ...updates } : shop
      );

      // Aktualizace stavu `userData` s novým polem `shops`
      setUserData((prevState) => ({
        ...prevState,
        shops: updatedShops,
      }));

      // Odeslání aktualizovaných dat na server
      await axiosInstance.patch(`shop/update/${uuid}/`, updates);
    } catch (error) {
      console.error("Failed to update shop data", error.response);
    }
  };

  const campaignCountSendMessage = () => {
    const baseMessageObject = {
      email: userData.email,
      subject: "internal message - too many Pmax",
      message: `
                Tento uživatel má více než 3 PMax kampaně a je potřeba se mu věnovat individuálně.<br>
                e-mail: ${userData.email}<br>
                name: ${userData.first_name}<br>
                surname: ${userData.last_name}<br>
                time: ${new Date().toLocaleString("cs-CZ")}<br>
                URL: ${window.location.pathname}
            `,
      send_email: true,
      language: lng,
    };
    if (Object.keys(existingCampaigns).length >= 3) {
      sendMessage(baseMessageObject);
    }
  };

  const gmcConnected = () => {
    const baseMessageObject = {
      email: userData.email,
      subject: "internal message - GMC connected",
      message: `
                GMC tohoto uživatele bylo připojeno k GMC PermoniQ.
                <br>
                e-mail: ${userData.email}<br>
                name: ${userData.first_name}<br>
                surname: ${userData.last_name}<br>
                time: ${new Date().toLocaleString("cs-CZ")}<br>
                URL: ${window.location.pathname}<br>
                GMC: ${
                  selectedShop?.google_merchant?.client_id +
                  " " +
                  selectedShop?.google_merchant?.client_name
                }
            `,
      send_email: true,
      language: lng,
    };
    if (selectedShop?.google_merchant_status === "connected") {
      sendMessage(baseMessageObject);
    }
  };

  const handleFinish = async (e) => {
    e.preventDefault();
    e.target.blur();

    sessionStorage.setItem("fi_1", true);

    setIsUserDataLoading(true);

    if (!selectedShop.google_analytics) {
      try {
        // Vytvoření Google Analytics účtu
        let analyticsData;
        try {
          setMessage(
            t(
              "installTrackingCodeForm.loadingMessages.creatingAnalyticsLoadMsg"
            )
          );
          const { data } = await axiosInstance.post(
            `googleanalytics/account/create/`,
            {
              customer_name: selectedShop.website.replace(/^https?:\/\//, ""),
              currency_code: selectedShop.currency,
              customer_url: selectedShop.website,
            }
          );
          analyticsData = data;
        } catch (error) {
          console.error("Error creating Google Analytics account:", error);
          showAlert(
            t("installTrackingCodeForm.errorMessages.createAnalyticsErrorMsg"),
            "danger"
          );
          return; // Ukončíme proces, pokud tento request selže
        }

        try {
          const updateShopAnalyticsResponse = await axiosInstance.patch(
            `shop/update/${selectedShop.uuid}/`,
            {
              google_analytics: analyticsData.customer_id,
            }
          );
        } catch (error) {
          console.log(error);
          return;
        }

        // Propojení Google Analytics s Google Ads
        try {
          setMessage(
            t(
              "installTrackingCodeForm.loadingMessages.connectingServicesLoadMsg"
            )
          );

          if (analyticsData.customer_id) {
            await axiosInstance.post(`googleanalytics/connect/googleads/`, {
              google_analytics_id: analyticsData.customer_id,
              google_ads_id: selectedShop.google_ads.client_id,
              google_ads_status: selectedShop?.google_ads_status || "not_set",
            });
          }
        } catch (error) {
          console.error(
            "Error connecting Google Analytics with Google Ads:",
            error
          );
          showAlert(
            t(
              "installTrackingCodeForm.errorMessages.connectAnalyticsAdsErrorMsg"
            ),
            "danger"
          );
          return; // Ukončíme proces, pokud tento request selže
        }

        try {
          setMessage(
            t("installTrackingCodeForm.loadingMessages.creatingCampaignLoadMsg")
          );
          let campaignCreateObject = {
            shop_id: uuid,
            budget: selectedShop.budget,
            roas: Number((100 / selectedShop.pno).toFixed(2)),
            campaign_type: "google-pmax",
            country:
              selectedShop.country.toLowerCase() === "cz" ||
              selectedShop.country.toLowerCase() === "cs"
                ? "2203"
                : selectedShop.country.toLowerCase() === "sk"
                ? "2703"
                : selectedShop.country.toLowerCase() === "pl"
                ? "2616"
                : selectedShop.country.toLowerCase() === "hu"
                ? "2348"
                : selectedShop.country.toLowerCase() === "ro"
                ? "2642"
                : "2203", // Fallback na CZ
            language:
              selectedShop.country.toLowerCase() === "cz" ||
              selectedShop.country.toLowerCase() === "cs"
                ? "1021"
                : selectedShop.country.toLowerCase() === "sk"
                ? "1033"
                : selectedShop.country.toLowerCase() === "pl"
                ? "1030"
                : selectedShop.country.toLowerCase() === "hu"
                ? "1024"
                : selectedShop.country.toLowerCase() === "ro"
                ? "1032"
                : "1021", // Fallback na CZ
          };

          if (selectedCampaign !== null) {
            campaignCreateObject.old = selectedCampaign;
          }

          const campaignCreateResponse = await axiosInstance.post(
            "campaign/create/",
            campaignCreateObject
          );

          if (selectedCampaign === null) {
            try {
              setMessage(
                t(
                  "installTrackingCodeForm.loadingMessages.activatingCampaignLoadMsg"
                )
              );

              const setCampaignToActiveResponse = await axiosInstance.put(
                `campaign/${campaignCreateResponse.data.campaign_id}/update/`,
                {
                  campaign_status: "active",
                }
              );
            } catch (error) {
              console.log("Campaign update error: ", error);
              showAlert(
                t(
                  "installTrackingCodeForm.errorMessages.campaignStatusErrorMsg"
                ),
                "danger"
              );
              return;
            }
          }
        } catch (error) {
          console.log(error);
          showAlert(
            t("installTrackingCodeForm.errorMessages.campaignCreateErrorMsg"),
            "danger"
          );
          return;
        }

        try {
          let billingResult = "cancelled";
          if (selectedCampaign === null) {
            setMessage(
              t(
                "installTrackingCodeForm.loadingMessages.controllingBillingLoadMsg"
              )
            );

            const checkBillingResponse = await axiosInstance.post(
              `shop/billing/${uuid}/`,
              {
                source_type: "google-ads",
              }
            );

            billingResult =
              checkBillingResponse.data[0]?.billingSetup?.status?.toLowerCase();
          } else {
            billingResult = "approved";
          }

          try {
            campaignCountSendMessage();
            gmcConnected();
          } catch (error) {
            console.log(error);
          }

          if (billingResult === "approved") {
            setShowWelcomeModal(true);
            navigate(`/${lng}/app/${appContentType}/`);
            showAlert(
              t("appCredit.infoMessages.billingApprovedInfoMsg"),
              "info"
            );
            // updateShop - aktualizace obchodu s Google Analytics ID
            await updateShop({ status_create: "end" });
          } else {
            setIsBilling(false);
            setShowModalAfterRegistration(true);
            setAppContentType("credit");
            setActiveItem("credit");
            navigate(`/${lng}/app/credit/`);
            window.scrollTo(0, 0);
            updateShop({ status_create: "end" });
          }
        } catch (error) {
          console.log("Check billing error: ", error);
          showAlert(
            t("appCredit.errorMessages.billingNotApprovedErrorMsg"),
            "danger"
          );
          return;
        }
      } catch (error) {
        console.error("Error during finish setup:", error);
        showAlert(
          t("installTrackingCodeForm.errorMessages.proccessErrorMsg"),
          "danger"
        );
        setIsUserDataLoading(false);
      }
    } else {
      try {
        // Ověření propojení GA4 s Google Ads
        setMessage(
          t("installTrackingCodeForm.loadingMessages.connectingServicesLoadMsg")
        );
        await axiosInstance.post(`googleanalytics/connect/googleads/`, {
          google_analytics_id: selectedShop.google_analytics.client_id,
          google_ads_id: selectedShop.google_ads.client_id,
          google_ads_status: selectedShop?.google_ads_status || "not_set",
        });

        // Kontrola, zda existují aktivní kampaně
        const response = await axiosInstance.get(
          `shop/campaign_all/${selectedShop.uuid}/`
        );
        if (Object.keys(response?.data).length === 0) {
          // Pokud nejsou aktivní kampaně, vytvoříme novou kampaň a aktivujeme ji
          setMessage(
            t("installTrackingCodeForm.loadingMessages.creatingCampaignLoadMsg")
          );
          let campaignCreateObject = {
            shop_id: selectedShop.uuid,
            budget: selectedShop.budget,
            roas: Number((100 / selectedShop.pno).toFixed(2)),
            campaign_type: "google-pmax",
            country:
              selectedShop.country.toLowerCase() === "cz" ||
              selectedShop.country.toLowerCase() === "cs"
                ? "2203"
                : selectedShop.country.toLowerCase() === "sk"
                ? "2703"
                : selectedShop.country.toLowerCase() === "pl"
                ? "2616"
                : selectedShop.country.toLowerCase() === "hu"
                ? "2348"
                : selectedShop.country.toLowerCase() === "ro"
                ? "2642"
                : "2203", // Fallback na CZ
            language:
              selectedShop.country.toLowerCase() === "cz" ||
              selectedShop.country.toLowerCase() === "cs"
                ? "1021"
                : selectedShop.country.toLowerCase() === "sk"
                ? "1033"
                : selectedShop.country.toLowerCase() === "pl"
                ? "1030"
                : selectedShop.country.toLowerCase() === "hu"
                ? "1024"
                : selectedShop.country.toLowerCase() === "ro"
                ? "1032"
                : "1021", // Fallback na CZ
          };
          const campaignCreateResponse = await axiosInstance.post(
            "campaign/create/",
            campaignCreateObject
          );
          setMessage(
            t(
              "installTrackingCodeForm.loadingMessages.activatingCampaignLoadMsg"
            )
          );
          await axiosInstance.put(
            `campaign/${campaignCreateResponse.data.campaign_id}/update/`,
            {
              campaign_status: "active",
            }
          );
        }

        // Kontrola stavu billingu
        setMessage(
          t("installTrackingCodeForm.loadingMessages.controllingBillingLoadMsg")
        );
        let billingResult = "cancelled";
        const checkBillingResponse = await axiosInstance.post(
          `shop/billing/${selectedShop.uuid}/`,
          {
            source_type: "google-ads",
          }
        );
        billingResult =
          checkBillingResponse.data[0]?.billingSetup?.status?.toLowerCase();

        try {
          campaignCountSendMessage();
          gmcConnected();
        } catch (error) {
          console.log(error);
        }

        if (billingResult === "approved") {
          setShowWelcomeModal(true);
          showAlert(t("appCredit.infoMessages.billingApprovedInfoMsg"), "info");
        } else {
          setIsBilling(false);
          setShowModalAfterRegistration(true);
          setAppContentType("credit");
          setActiveItem("credit");
          navigate(`/${lng}/app/credit/`);
          window.scrollTo(0, 0);
          updateShop({ status_create: "end" });
        }

        // Aktualizace obchodu a navigace do aplikace
        if (selectedShop) await updateShop({ status_create: "end" });
        navigate(`/${lng}/app/${appContentType}/`);
      } catch (error) {
        console.log(error);
        setIsUserDataLoading(false);
      }
    }
  };

  // Přizpůsobení labels v steps
  const localizedSteps = steps.map((step) => ({
    ...step,
    label: t(`${step.label}`),
    alertMessage: t(step.alertMessage),
  }));

  const handleScroll = (event) => {
    event.preventDefault();
    const element = document.getElementById("code");
    const offset = -100; // například 100px nad prvek
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition + offset;

    // Scrolluje na pozici
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    // Přidá CSS třídu s pulzující animací
    element.classList.add("pulse-border");

    // Po 3 sekundách třídu odebere
    setTimeout(() => {
      element.classList.remove("pulse-border");
    }, 3000); // 3000 ms = 3 sekundy
  };

  const getCampaigns = async (e) => {
    e.preventDefault();
    e.target.blur();
    try {
      setMessage("Hledám aktivní kampaně");
      setIsUserDataLoading(true);
      const response = await axiosInstance.get(
        `shop/campaign_all/${selectedShop.uuid}/`
      );
      if (Object.keys(response?.data).length === 0) {
        handleFinish(e);
      } else if (Object.keys(response?.data).length > 0) {
        setSelectedCampaign(response?.data[0]);
        setExistingCampaigns(response?.data);
        setIsUserDataLoading(false);
      }
    } catch (error) {
      console.log(error);
      showAlert("An error occured while getting existing campaigns.", "danger");
      setIsUserDataLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Instalace měřícího kódu - PermoniQ</title>
      </Helmet>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {isUserDataLoading && <ModalLoading message={message} />}
      {/* <div className="progress">
        <div className="container">
          <ul>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>1.</strong> {t('installTrackingCodeProgress.addShop')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>2.</strong> {t('installTrackingCodeProgress.companyDetails')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>3.</strong> {t('installTrackingCodeProgress.uploadProducts')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-checked"><strong>4.</strong> {t('installTrackingCodeProgress.connectGoogle')}</li>
            <li onClick={() => showAlert(t("installTrackingCodeForm.contact.support"))} className="is-active"><strong>5.</strong> {t('installTrackingCodeProgress.installCode')}</li>
          </ul>
        </div>
      </div> */}

      <ProgressTracker steps={localizedSteps} />
      <ModalContactUs setMessage={setMessage} />

      <section className="section register">
        <article className="container">
          <h1 className="title">{t("installTrackingCodeTitle")}</h1>
          <form
            action="#"
            method="post"
            autoComplete="off"
            className="form"
            onSubmit={getCampaigns}
          >
            <div className="box">
              <div
                className="box"
                style={{
                  margin: "0 0 10px 0",
                  padding: 20,
                  background: "#F0F0F0",
                }}
              >
                <h4 style={{ margin: "0 0 10px 0" }}>
                  <Trans
                    i18nKey={t("installTrackingCodeForm.dataplusTitle")}
                    components={[
                      <a
                        className="link"
                        rel="noopener"
                        target="_blank"
                        href="https://dataplus.advisio.cz/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=registration_code"
                      ></a>,
                    ]}
                  />
                </h4>
                <p>
                  <Trans
                    i18nKey={t("installTrackingCodeForm.codeInstructions")}
                    components={[
                      <a
                        className="link"
                        rel="noopener"
                        target="_blank"
                        href="https://dataplus.advisio.cz/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=registration_code"
                      ></a>,
                    ]}
                  />
                </p>
              </div>

              <pre
                id="code"
                className={`form__code ${styles.codeBox}`}
                contentEditable
                suppressContentEditableWarning={true}
                onInput={(e) => setCode(e.currentTarget.textContent)}
                spellCheck="false"
              >
                {code || ""}
                <FaRegCopy
                  className={styles.copyIcon}
                  onClick={() => copyToClipboard()}
                />
              </pre>

              {/* <p>
                <button
                  className="btn js-copy"
                  type="button"
                  onClick={copyToClipboard}
                  ref={copyButtonRef}
                >
                  {t("installTrackingCodeForm.copyCode")}
                </button>
              </p> */}

              <br />

              <p>{t("installTrackingCodeForm.helpTitle")}</p>

              <div className="faq faq--compact">
                {faqItems.map((item, index) => (
                  <div
                    key={index}
                    className={`faq__item ${
                      openIndices.includes(index) ? "is-open" : ""
                    }`}
                    onClick={() => handleToggle(index)}
                  >
                    <h3 className="faq__question">
                      <img
                        className="logo__icon"
                        src={item.logo}
                        alt={item.altText}
                      />
                    </h3>
                    <div
                      className="faq__answer"
                      ref={(el) => (answerRefs.current[index] = el)}
                      style={{
                        maxHeight: openIndices.includes(index)
                          ? `${heights[index] - 30}px`
                          : "0",
                      }}
                    >
                      <ol>
                        {item.steps.map((step, stepIndex) => (
                          <li key={stepIndex}>
                            <Trans
                              i18nKey={step}
                              components={[
                                <a
                                  href="https://tagmanager.google.com/"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://www.shoptet.cz"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://www.upgates.cz"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://www.shopify.com"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://addons.prestashop.com/"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://www.eshop-rychle.cz"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://www.shopsys.cz"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  className="link"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleScroll(e);
                                  }}
                                />,
                                <a
                                  href="https://www.webareal.cz"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://www.nody.cz"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                                <a
                                  href="https://www.fastcentrik.cz/"
                                  target="_blank"
                                  className="link"
                                  rel="noopener noreferrer"
                                  onClick={(e) => e.stopPropagation()}
                                ></a>,
                              ]}
                            />
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="register__submit">
              <p>
                <button className="btn" type="submit">
                  {t("installTrackingCodeForm.finish")}
                </button>
              </p>
            </div>
          </form>
        </article>
      </section>
      {Object.keys(existingCampaigns).length > 0 && (
        <Modal
          onClose={() => {
            setExistingCampaigns([]);
            setSelectedCampaign(null);
          }}
          title={"Volba kampaně pro maximální výkon"}
          confirmText={"Vybrat kampaň"}
          onConfirm={handleFinish}
        >
          <div>
            <p>
              Abychom zajistili co nejlepší výsledky, je potřeba zvolit jednu
              hlavní PMax kampaň, která zůstane aktivní. Ostatní PMax kampaně
              budou deaktivovány – klasické vyhledávací a obsahové kampaně
              zůstanou beze změny.
            </p>
            <p>
              Kampaně jsme seřadili podle jejich výkonu – nejlepší je na prvním
              místě.
            </p>
            <strong>
              <p style={{ marginBottom: "5px" }}>
                Vyberte tu, která nejlépe odpovídá vašim cílům:
              </p>
              {/* <span>{`${existingCampaigns[0].campaign_name}`}</span> */}
            </strong>
          </div>

          <select
            style={{ cursor: "pointer" }}
            onChange={(e) =>
              setSelectedCampaign(
                existingCampaigns.find(
                  (campaign) => campaign.campaign_id === e.target.value
                )
              )
            }
          >
            {existingCampaigns.map((campaign, id) => (
              <option key={campaign.campaign_id} value={campaign.campaign_id}>
                {`${id + 1}. ${campaign.campaign_name}`}
              </option>
            ))}
          </select>
        </Modal>
      )}
      {userData.email === "info@permoniq.com" && showAdminModal && (
        <Modal
          title="Informace o obchodu"
          cancelText="Zavřít"
          confirmText=""
          onClose={() => setShowAdminModal(false)}
        >
          {infoItems.map((item, index) => (
            <InfoBoxItem key={index} label={item.label} value={item.value} />
          ))}
        </Modal>
      )}
    </>
  );
};

export default InstallTrackingCode;
