import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";
import SimpleAlert from "../components/SimpleAlert";

import styles from "../css/ErrorPage.module.css";

import axiosInstance from "../api/AxiosConfig";

const ErrorPage = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  const {
    setIsUserDataLoading,
    showAlert,
    closeAlert,
    isAlertVisible,
    alertMessage,
  } = useContext(AppContext);

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setIsUserDataLoading(false);
  }, [setIsUserDataLoading]);

  const onSendError = async () => {
    try {
      const response = await axiosInstance.post(`send-message/`, {
        email: email || "jentestovaciucet@gmail.com",
        message,
        subject: "app error",
        send_email: true,
        language: lng,
      });

      showAlert("Zpráva byla odeslána!", "info");
    } catch (error) {
      console.log(error);
      showAlert("Při odesílání zprávy došlo k chybě!");
    } finally {
      setMessage("");
      setEmail("");
    }
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Ups, něco se pokazilo! - PermoniQ</title>
      </Helmet>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <div className={`${styles.not_found_container}`} dat-navbar="not_found">
        <h1 className={`${styles.heading}`}>{t("errorPage.title")}</h1>
        <div className={`box ${styles.box}`}>
          <p className={styles.text}>{t("errorPage.message")}</p>
          <p className={styles.text}>{t("errorPage.helpRequest")}</p>
          <label htmlFor="error-message-textarea"></label>
          <textarea
            className={`${styles.textArea}`}
            id="error-message-textarea"
            rows={4}
            cols={50}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ resize: "none" }}
            maxLength={500}
          />
          <p className={styles.text} style={{ marginTop: "1rem" }}>
            {t("errorPage.mailRequestText")}
          </p>
          <p>
            <input
              type="text"
              id="email_billing"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`${styles.emailInput}`}
            />
          </p>
        </div>
        <div className={styles.btnBox}>
          <button
            className={`btn ${styles.btn}`}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(`/${lng}/`);
            }}
          >
            {t("errorPage.backToMainPageBtn")}
          </button>
          <button
            className={`btn ${styles.btn}`}
            onClick={(e) => {
              e.target.blur();
              onSendError();
            }}
          >
            {t("errorPage.sendBtn")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
