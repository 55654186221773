import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext";
import styles from "../css/ModalContactUsStyles.module.css";
import { sendMessage } from "../utils/axios_functions/sendMessage";
import SimpleAlert from "./SimpleAlert";
import ModalLoading from "./ModalLoading";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CloseBtn from "./CloseBtn";
import questions from "../data/questions/questions.json"; // Import JSON souboru s otázkami

const ModalContactUs = ({ setMessage }) => {
  const { t } = useTranslation();
  const { lng } = useParams();

  const {
    userData,
    selectedShop,
    isAlertVisible,
    showAlert,
    closeAlert,
    alertMessage,
    isUserDataLoading,
    setIsUserDataLoading,
  } = useContext(AppContext);

  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [currentLng, setCurrentLng] = useState(lng || "cs");
  // Pokud není vybrána žádná otázka, zobrazí se formulář
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  // Stavová proměnná pro ovládání zobrazení FAQ
  const [showFAQ, setShowFAQ] = useState(false);

  const today = new Date();
  const currentHour = today.getHours();
  // V JavaScriptu: 0 = neděle, 6 = sobota
  const isWeekend = today.getDay() === 0 || today.getDay() === 6;
  const isOnline = currentHour >= 9 && currentHour < 15 && !isWeekend;

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (selectedShop?.phone) {
      setPhone(selectedShop.phone);
    }
  }, [selectedShop]);

  useEffect(() => {
    if (userData?.email) {
      setEmail(userData.email);
    }
  }, [userData]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setNote("");
        setOpenContactUsModal(false);
      }
    };
    if (openContactUsModal) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openContactUsModal]);

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains(styles.contact_us_modal_layout)) {
      setOpenContactUsModal(false);
      setNote("");
    }
  };

  const onContactMe = async () => {
    if (!phone) {
      showAlert(
        t("modalContactUs.errorMessages.phoneRequired") ||
          "Telefonní číslo je povinné.",
        "danger"
      );
      return;
    }
    if (!email) {
      showAlert(
        t("modalContactUs.errorMessages.emailRequired") || "E-mail je povinný.",
        "danger"
      );
      return;
    }

    const allowedPrefixes = ["+420", "+421", "+48", "+36", "+40"];
    const hasAllowedPrefix = allowedPrefixes.some((prefix) =>
      phone.startsWith(prefix)
    );
    let phoneToUse = phone;
    if (!hasAllowedPrefix) {
      const digitsOnlyRegex = /^\d{9}$/;
      if (!digitsOnlyRegex.test(phone)) {
        showAlert(
          t("modalContactUs.errorMessages.invalidPhoneFormat") ||
            "Telefonní číslo bez předvolby musí obsahovat přesně 9 číslic.",
          "danger"
        );
        return;
      }
    } else {
      const matchedPrefix = allowedPrefixes.find((prefix) =>
        phone.startsWith(prefix)
      );
      const rest = phone.substring(matchedPrefix.length);
      const restDigitsRegex = /^\d+$/;
      if (!restDigitsRegex.test(rest)) {
        showAlert(
          t("modalContactUs.errorMessages.invalidPhoneFormat") ||
            "Telefonní číslo s předvolbou musí obsahovat pouze číslice za předvolbou.",
          "danger"
        );
        return;
      }
    }

    if (!userData) return;
    setMessage("Odesílám vaši zprávu.");
    setIsUserDataLoading(true);

    const baseMessageObject = {
      email: email,
      subject: "PermoniQ - kontaktujte mě!",
      message: `
                Žádost o pomoc<br>
                e-mail: ${email}<br>
                time: ${new Date().toLocaleString("cs-CZ")}<br>
                URL: ${window.location.pathname}
            `,
      send_email: true,
      language: lng,
    };

    const shopDetails = selectedShop
      ? {
          UUID: selectedShop.uuid || "",
          name: selectedShop.name || "",
          website: selectedShop.website || "",
          phone: phoneToUse,
          first_name: selectedShop.first_name || "",
          last_name: selectedShop.last_name || "",
        }
      : {};

    const noteText = note ? `<br>Poznámka: ${note}` : "";
    const messageObject = {
      ...baseMessageObject,
      message: `${baseMessageObject.message}<br>${Object.entries(shopDetails)
        .map(([key, value]) => `${key}: ${value}`)
        .join("<br>")}${noteText}`,
    };

    try {
      const response = await sendMessage(messageObject);
      if (response?.message === "Message received!") {
        showAlert(t("modalContactUs.infoMessages.messageSentInfoMsg"), "info");
      }
    } catch (error) {
      console.log(error);
      showAlert(
        t("modalContactUs.errorMessages.messageSentErrorMsg"),
        "danger"
      );
    } finally {
      setIsUserDataLoading(false);
      setNote("");
      setOpenContactUsModal(false);
    }
  };

  // Filtrace otázek dle atributu "all" nebo "contact"
  const filteredQuestions = questions.filter(
    (q) => q.attribute === "all" || q.attribute === "contact"
  );

  return (
    <>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {isSmallScreen ? (
        <div
          data-btn-type="small"
          className={`${styles.send_message_plugin}`}
          onClick={() => setOpenContactUsModal(true)}
          aria-label="Otevřít formulář Kontaktujte nás"
          role="button"
          tabIndex="0"
        >
          <span>?</span>
        </div>
      ) : (
        <button
          type="button"
          data-btn-type="big"
          className="btn btn--secondary"
          style={{ color: "black" }}
          onClick={(e) => {
            e.target.blur();
            setOpenContactUsModal(true);
          }}
        >
          {t("modalContactUs.openBtn")}
        </button>
      )}
      {openContactUsModal && (
        <div
          className={`${styles.contact_us_modal_layout}`}
          onClick={handleBackdropClick}
          role="dialog"
          aria-labelledby="contactUsTitle"
          aria-describedby="contactUsDescription"
          aria-modal="true"
        >
          <CloseBtn
            onClick={() => {
              setNote("");
              setOpenContactUsModal(false);
            }}
          />
          <div className={`${styles.contact_us_modal}`}>
            <div className={`${styles.contact_us_wrapper}`}>
              {selectedQuestion ? (
                // Zobrazení odpovědi dle vybraného jazyka
                <section className={styles.contactUsForm}>
                  <h4>{selectedQuestion[lng]?.question}</h4>
                  <p>{selectedQuestion[lng]?.answer}</p>
                  <button
                    type="button"
                    onClick={() => setSelectedQuestion(null)}
                    className={`btn ${styles.backButton}`}
                  >
                    Zpět
                  </button>
                </section>
              ) : (
                <section className={styles.contactUsForm}>
                  <h3 id="contactUsTitle">{t("modalContactUs.title")}</h3>
                  <p id="contactUsDescription" className={styles.par}>
                    {t("modalContactUs.description")}
                  </p>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      onContactMe();
                    }}
                  >
                    <div className={styles.contact_us_fields}>
                      <div className={styles.input_wrapper}>
                        <label htmlFor="contact_us_tel">
                          {t("modalContactUs.phone")}
                        </label>
                        <input
                          id="contact_us_tel"
                          className={styles.contactUsPhone}
                          type="tel"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className={styles.input_wrapper}>
                        <label htmlFor="contact_us_email">
                          {t("modalContactUs.mail")}
                        </label>
                        <input
                          id="contact_us_email"
                          type="email"
                          className={styles.contactUsEmail}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className={styles.input_wrapper}>
                      <textarea
                        id="contact_us_note"
                        className={styles.contactUsTextarea}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        placeholder={t("modalContactUs.notePlaceholder")}
                      />
                    </div>
                    <div className={styles.btn_box}>
                      {isOnline ? (
                        <p className={styles.parStatus}>
                          <span className={styles.greenDot}></span>
                          {t("modalContactUs.onlineStatus")}
                        </p>
                      ) : (
                        <p className={styles.parStatus}>
                          <span className={styles.redDot}></span>
                          {t("modalContactUs.offlineStatus")}
                        </p>
                      )}
                      <button
                        type="submit"
                        className={`${styles.contact_me_btn} btn`}
                        aria-label="Ozvěte se mi"
                      >
                        {t("modalContactUs.contactMeBtn")}
                      </button>
                    </div>
                  </form>
                </section>
              )}
              {/* Podmíněné vykreslení FAQ dle stavu showFAQ */}
              {showFAQ && (
                <>
                  <hr className={styles.separator} />
                  <section className={styles.contactUsQuestions}>
                    <h4>{t("modalContactUs.faqTitle")}</h4>
                    <div className={styles.questionsContainer}>
                      {filteredQuestions.map((q) => (
                        <div key={q.id} className={styles.animatedBorder}>
                          <dl
                            className={styles.questionPair}
                            onClick={() => setSelectedQuestion(q)}
                            tabIndex="0"
                          >
                            <dt>{q[currentLng]?.question}</dt>
                            <dd>{q[currentLng]?.answer}</dd>
                          </dl>
                        </div>
                      ))}
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContactUs;

// import React, { useState, useEffect, useContext } from "react";
// import { AppContext } from "../context/AppContext";
// import styles from "../css/ModalContactUsStyles.module.css";
// import { sendMessage } from "../utils/axios_functions/sendMessage";
// import SimpleAlert from "./SimpleAlert";
// import ModalLoading from "./ModalLoading";
// import { useTranslation } from "react-i18next";
// import { useParams } from "react-router-dom";

// const ModalContactUs = ({ setMessage }) => {
//   const { t } = useTranslation();
//   const { lng } = useParams();

//   const {
//     userData,
//     selectedShop,
//     isAlertVisible,
//     showAlert,
//     closeAlert,
//     alertMessage,
//     isUserDataLoading,
//     setIsUserDataLoading,
//   } = useContext(AppContext);

//   const [openContactUsModal, setOpenContactUsModal] = useState(false);
//   const [phone, setPhone] = useState("");

//   useEffect(() => {
//     if (selectedShop?.phone) {
//       setPhone(selectedShop?.phone);
//     }
//   }, [selectedShop]);

//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (e.key === "Escape") {
//         setOpenContactUsModal(false);
//       }
//     };

//     if (openContactUsModal) {
//       document.body.style.overflow = "hidden";
//       document.addEventListener("keydown", handleKeyDown);
//     } else {
//       document.body.style.overflow = "auto";
//     }

//     return () => {
//       document.body.style.overflow = "auto";
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [openContactUsModal]);

//   const handleBackdropClick = (e) => {
//     if (e.target.classList.contains(styles.contact_us_modal_layout)) {
//       setOpenContactUsModal(false);
//     }
//   };

//   const onContactMe = async () => {
//     if (!phone) {
//       showAlert(
//         t("modalContactUs.errorMessages.phoneRequired") || "Telefonní číslo je povinné.",
//         "danger"
//       );
//       return;
//     }

//     const allowedPrefixes = ["+420", "+421", "+48", "+36", "+40"];
//     const hasAllowedPrefix = allowedPrefixes.some((prefix) => phone.startsWith(prefix));
//     let phoneToUse = phone;

//     if (!hasAllowedPrefix) {
//       // Telefonní číslo bez předvolby: musí obsahovat přesně 9 číslic
//       const digitsOnlyRegex = /^\d{9}$/;
//       if (!digitsOnlyRegex.test(phone)) {
//         showAlert(
//           t("modalContactUs.errorMessages.invalidPhoneFormat") ||
//             "Telefonní číslo bez předvolby musí obsahovat přesně 9 číslic.",
//           "danger"
//         );
//         return;
//       }
//     } else {
//       // Číslo obsahuje předvolbu – ověříme, že předvolba odpovídá jedné z povolených a zbytek obsahuje jen číslice
//       const matchedPrefix = allowedPrefixes.find((prefix) => phone.startsWith(prefix));
//       const rest = phone.substring(matchedPrefix.length);
//       const restDigitsRegex = /^\d+$/;
//       if (!restDigitsRegex.test(rest)) {
//         showAlert(
//           t("modalContactUs.errorMessages.invalidPhoneFormat") ||
//             "Telefonní číslo s předvolbou musí obsahovat pouze číslice za předvolbou.",
//           "danger"
//         );
//         return;
//       }
//     }

//     if (!userData) return;
//     setMessage("Odesílám vaši zprávu.");
//     setIsUserDataLoading(true);
//     const baseMessageObject = {
//       email: userData.email,
//       subject: "PermoniQ - kontaktujte mě!",
//       message: `
//                 Žádost o pomoc<br>
//                 e-mail: ${userData.email}<br>
//                 time: ${new Date().toLocaleString("cs-CZ")}<br>
//                 URL: ${window.location.pathname}
//             `,
//       send_email: true,
//       language: lng,
//     };

//     const shopDetails = selectedShop
//       ? {
//           UUID: selectedShop.uuid || "",
//           name: selectedShop.name || "",
//           website: selectedShop.website || "",
//           phone: phoneToUse, // použijeme validované telefonní číslo
//           first_name: selectedShop.first_name || "",
//           last_name: selectedShop.last_name || "",
//         }
//       : {};

//     const messageObject = {
//       ...baseMessageObject,
//       message: `${baseMessageObject.message}<br>${Object.entries(shopDetails)
//         .map(([key, value]) => `${key}: ${value}`)
//         .join("<br>")}`,
//     };

//     try {
//       const response = await sendMessage(messageObject);
//       if (response?.message === "Message received!") {
//         showAlert(t("modalContactUs.infoMessages.messageSentInfoMsg"), "info");
//       }
//     } catch (error) {
//       console.log(error);
//       showAlert(
//         t("modalContactUs.errorMessages.messageSentErrorMsg"),
//         "danger"
//       );
//     } finally {
//       setIsUserDataLoading(false);
//       setOpenContactUsModal(false);
//     }
//   };

//   return (
//     <>
//       {isAlertVisible && (
//         <SimpleAlert
//           message={alertMessage.text}
//           onClose={closeAlert}
//           type={alertMessage.type}
//         />
//       )}
//       <div
//         className={`${styles.send_message_plugin}`}
//         onClick={() => setOpenContactUsModal(true)}
//         aria-label="Otevřít formulář Kontaktujte nás"
//         role="button"
//         tabIndex="0"
//       >
//         <span>?</span>
//       </div>
//       {openContactUsModal && (
//         <div
//           className={`${styles.contact_us_modal_layout}`}
//           onClick={handleBackdropClick}
//           role="dialog"
//           aria-labelledby="contactUsTitle"
//           aria-describedby="contactUsDescription"
//           aria-modal="true"
//         >
//           <span
//             className={`${styles.close_btn}`}
//             onClick={() => setOpenContactUsModal(false)}
//             role="button"
//             aria-label="Zavřít formulář Kontaktujte nás"
//             tabIndex="0"
//           >
//             &times;
//           </span>
//           <div className={`${styles.contact_us_modal}`}>
//             <div className={`${styles.contact_us_wrapper}`}>
//               <h4 id="contactUsTitle">{t("modalContactUs.title")}</h4>
//               <p id="contactUsDescription">{t("modalContactUs.description")}</p>
//               <label htmlFor="contact_us_tel">{t("registerForm.phone")}</label>
//               <input
//                 id="contact_us_tel"
//                 className={styles.contactUsPhone}
//                 type="tel"
//                 value={phone}
//                 onChange={(e) => setPhone(e.target.value)}
//               />
//             </div>
//             <div className={`${styles.btn_box}`}>
//               <button
//                 className={`${styles.contact_me_btn} btn`}
//                 onClick={onContactMe}
//                 aria-label="Ozvěte se mi"
//               >
//                 {t("modalContactUs.contactMeBtn")}
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ModalContactUs;
