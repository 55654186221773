import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";

import { AppContext } from "../context/AppContext";

import sendEvent from "../utils/functions/sendEvent";

const FAQSection = () => {
  const { t } = useTranslation();
  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});
  const answerRefs = useRef([]);
  const { openModal, isLogged } = useContext(AppContext);

  const faqItems = [
    "spendingOverview",
    "googleAdsBonus",
    "supportedCountries",
    "budgetAdherence"
  ];

  const handleToggle = (index) => {
    setOpenIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, [openIndices]);

  return (
    <section className="section" id={t("faq.id")}>
      <article className="container">
        <h2 className="title">{t("faq.title")}</h2>
        <div className="box faq">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className={`faq__item ${
                openIndices.includes(index) ? "is-open" : ""
              }`}
            >
              <h3 className="faq__question" onClick={() => handleToggle(index)}>
                {t(`faq.items.${item}.question`)}
              </h3>
              <div
                className="faq__answer"
                ref={(el) => (answerRefs.current[index] = el)}
                style={{
                  maxHeight: openIndices.includes(index)
                    ? `${heights[index]}px`
                    : "0",
                  marginTop: "10px",
                  cursor: "default"
                }}
              >
                {item === "googleAdsBonus" ? (
                  <Trans
                    components={[
                      <a
                        href="https://support.google.com/google-ads/answer/15329625"
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    ]}
                  >
                    {t(`faq.items.${item}.answer`)}
                  </Trans>
                ) : (
                  <span>{t(`faq.items.${item}.answer`)}</span>
                )}
              </div>
            </div>
          ))}
        </div>
        {!isLogged && (
          <p className="section__more">
            <a
              onClick={() => {
                sendEvent("click_try-free");
              }}
              href={`#${t("paths.tryFree")}`}
              className="btn"
            >
              {t("faq.ctaTryFree")}
            </a>
            <a
              href={`#${t("paths.consultation")}`}
              className="btn btn--inverse js-modal"
              onClick={() => {
                sendEvent("click_consultation");
                openModal();
              }}
            >
              {t("faq.ctaConsultation")}
            </a>
          </p>
        )}
      </article>
    </section>
  );
};

export default FAQSection;
