import React, { useContext, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import styles from "../css/AppMenu.module.css";

import homeIcon from "../img/icons/home.svg";
import statisticsIcon from "../img/icons/statistics.svg";
import creditDarkIcon from "../img/icons/credit_dark.svg";
import settingsIcon from "../img/icons/settings.svg";
import helpIcon from "../img/icons/help.svg";
import phoneIcon from "../img/icons/phone.svg";
import productsIcon from "../img/icons/products.svg";
import documentIcon from "../img/icons/document.svg"
import Progressbar from "./progressBars/ProgressBar";

const AppMenu = () => {
  const navigate = useNavigate();
  const { lng } = useParams();
  const { t } = useTranslation();

  const { setAppContentType, activeItem, setActiveItem, selectedShop } =
    useContext(AppContext);

  // Vypočítáme počet zbývajících dní do trial_end_date.
  const daysRemaining = useMemo(() => {
    if (!selectedShop?.trial_end_date) return 0;
    const trialEndDate = new Date(selectedShop.trial_end_date);
    const today = new Date();
    const diff = trialEndDate.getTime() - today.getTime();
    return diff > 0 ? Math.ceil(diff / (1000 * 60 * 60 * 24)) : 0;
  }, [selectedShop]);

  const handleItemClick = (contentType) => {
    setAppContentType(contentType);
    setActiveItem(contentType);
    navigate(`/${lng}/app/${contentType}/`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <nav className="app-nav">
        <ul>
          <li>
            <Link
              to={`/${lng}/app/dashboard/`}
              onClick={() => handleItemClick("dashboard")}
              className={activeItem === "dashboard" ? "is-active" : ""}
            >
              <span className="icon icon--home">
                <img src={homeIcon} alt="home" className="icon__svg" />
              </span>
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to={`/${lng}/app/statistics/`}
              onClick={() => handleItemClick("statistics")}
              className={activeItem === "statistics" ? "is-active" : ""}
            >
              <span className="icon icon--statistics">
                <img
                  src={statisticsIcon}
                  alt="statistics"
                  className="icon__svg"
                />
              </span>
              {t("appMenu.statistics")}
            </Link>
          </li>
          {/* <li>
            <Link
              to={`/${lng}/app/products/`}
              onClick={() => handleItemClick("products")}
              className={activeItem === "products" ? "is-active" : ""}
            >
              <span className="icon icon--products">
                <img
                  src={productsIcon}
                  alt="products"
                  className="icon__svg"
                />
              </span>
              {t("appMenu.products")}
            </Link>
          </li> */}
          <li>
            <Link
              to={`/${lng}/app/credit/`}
              onClick={() => handleItemClick("credit")}
              className={activeItem === "credit" ? "is-active" : ""}
            >
              <span className="icon icon--credit">
                <img src={creditDarkIcon} alt="credit" className="icon__svg" />
              </span>
              {t("appMenu.credit")}
            </Link>
          </li>
          <li>
            <Link
              to={`/${lng}/app/settings/`}
              onClick={() => handleItemClick("settings")}
              className={activeItem === "settings" ? "is-active" : ""}
            >
              <span className="icon icon--settings">
                <img src={settingsIcon} alt="settings" className="icon__svg" />
              </span>
              {t("appMenu.settings")}
            </Link>
          </li>
          <li>
            <Link
              to={`/${lng}/app/help/`}
              onClick={() => handleItemClick("help")}
              className={activeItem === "help" ? "is-active" : ""}
            >
              <span className="icon icon--help">
                <img src={helpIcon} alt="help" className="icon__svg" />
              </span>
              {t("appMenu.help")}
            </Link>
          </li>
          <li>
            <Link
              to={`/${lng}/app/contact-us/`}
              onClick={() => handleItemClick("contact-us")}
              className={activeItem === "contact-us" ? "is-active" : ""}
            >
              <span className="icon icon--phone">
                <img src={phoneIcon} alt="phone" className="icon__svg" />
              </span>
              {t("appMenu.contactUs")}
            </Link>
          </li>
          {Object.keys(selectedShop?.billing_info || {}).length > 0 && (
            <li>
              <Link
                to={`/${lng}/app/invoice/`}
                onClick={() => handleItemClick("invoice")}
                className={activeItem === "invoice" ? "is-active" : ""}
              >
                <span className="icon icon--document">
                  <img
                    src={documentIcon}
                    alt="Fakturační informace"
                    className="icon__svg"
                  />
                </span>
                {t("appMenu.invoice")}
              </Link>
            </li>
          )}
        </ul>
        {daysRemaining > 0 && (
          <Progressbar
            value={daysRemaining}
            colorAbove="#22e06c"
            colorBelow="#ffda44"
            title="Pracuji zdarma"
          >
            ještě{" "}
            {daysRemaining > 1
              ? `${daysRemaining} dní`
              : `${daysRemaining} den`}
          </Progressbar>
        )}
      </nav>
    </>
  );
};

export default AppMenu;
