import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import AppContextProvider from './context/AppContext';
import "./css/styles.css";
import "./css/app.css";
import App from './App';

const RootComponent = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    // Flag to disable password protection during testing
    const isTestingMode = true; // Set this to true to bypass the password

    useEffect(() => {
        // Check sessionStorage for authentication status
        const storedAuthStatus = sessionStorage.getItem('isAuthenticated');
        if (storedAuthStatus === 'true') {
            setIsAuthenticated(true);
        } else if (isTestingMode) {
            setIsAuthenticated(true);
            sessionStorage.setItem('isAuthenticated', 'true');
        } else {
            const correctPassword = 'vip'; // Replace with your desired password
            let enteredPassword = null;
    
            while (enteredPassword !== correctPassword) {
                enteredPassword = prompt('Please enter the password:');
                if (enteredPassword === correctPassword) {
                    setIsAuthenticated(true);
                    sessionStorage.setItem('isAuthenticated', 'true');
                    break;
                } else {
                    alert('Incorrect Password. Please try again.');
                }
            }
        }
    }, [isTestingMode]);
    

    return (
        <>
            {isAuthenticated ? (
                <I18nextProvider i18n={i18n}>
                    <AppContextProvider>
                        <App />
                    </AppContextProvider>
                </I18nextProvider>
            ) : (
                <div>
                    <h2>Access Denied</h2>
                    <p>You have not entered the correct password.</p>
                </div>
            )}
        </>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<RootComponent />);
