/**
 * Vypočítá počet celých dní, které uplynuly od zadaného data (v ISO 8601 formátu) do dneška.
 *
 * @param {string} dateStr - Řetězec představující datum v ISO 8601 formátu
 * (např. "YYYY-MM-DDTHH:mm:ss.sssZ" nebo jiné varianty,
 * které new Date() zpracuje).
 * Písmeno 'Z' na konci značí časové pásmo UTC.
 * @returns {number} Počet celých dní od zadaného data.
 * @throws {Error} Pokud vstupní řetězec chybí, není řetězec, je prázdný,
 * nelze jej převést na platné datum, nebo pokud je datum v budoucnosti.
 */
const calculateDaysSinceISO = (dateStr) => {
    // 1. Kontrola, zda byl vstup vůbec poskytnut
    if (dateStr === null || dateStr === undefined) {
      throw new Error("Vstupní datumový řetězec nesmí být null ani undefined.");
    }
  
    // 2. Kontrola, zda je vstup řetězec
    if (typeof dateStr !== 'string') {
      throw new Error("Vstup musí být datumový řetězec.");
    }
  
    // 3. Kontrola, zda řetězec není prázdný
     if (dateStr.trim() === '') {
       throw new Error("Vstupní datumový řetězec nesmí být prázdný.");
     }
  
    // 4. Pokus o převedení řetězce na datum a kontrola validity
    //    Konstruktor Date() spolehlivě zpracovává ISO 8601 formát.
    const registrationDate = new Date(dateStr);
  
    // Kontrola, zda parsování proběhlo úspěšně (pro případ okrajových chyb formátu)
    if (isNaN(registrationDate.getTime())) {
      throw new Error(`Neplatný nebo nepodporovaný formát datumového řetězce: "${dateStr}"`);
    }
  
    // 5. Získání dnešního data a normalizace obou dat na půlnoc LOKÁLNÍHO času
    //    I když je vstup v UTC ('Z'), chceme porovnávat celé dny v kontextu
    //    lokálního času uživatele (nebo serveru), kde běží kód.
    const today = new Date(); // Aktuální datum a čas v lokální zóně
    // console.log("Raw today (local):", today.toString()); // Pro ladění
    // console.log("Raw registration date (parsed from ISO):", registrationDate.toString()); // Pro ladění
  
    today.setHours(0, 0, 0, 0); // Nastaví dnešní datum na půlnoc lokálního času
    registrationDate.setHours(0, 0, 0, 0); // Nastaví zadané datum na půlnoc lokálního času
  
    // console.log("Normalized today (local midnight):", today.toString()); // Pro ladění
    // console.log("Normalized registration date (local midnight):", registrationDate.toString()); // Pro ladění
  
    // 6. Kontrola, zda zadané datum není v budoucnosti (porovnání půlnocí lokálního času)
    if (registrationDate > today) {
      // Získání dnešního data ve formátu pro ČR pro srozumitelnější chybu
      const todayFormatted = new Intl.DateTimeFormat('cs-CZ', {
          day: 'numeric', month: 'numeric', year: 'numeric'
      }).format(new Date()); // např. "3. 4. 2025"
      throw new Error(`Nelze počítat dny od data v budoucnosti (${dateStr}). Dnešek je ${todayFormatted}.`);
    }
  
    // 7. Výpočet rozdílu v milisekundách
    const diffTime = today - registrationDate; // Rozdíl bude vždy >= 0
  
    // 8. Převod rozdílu v milisekundách na celé dny
    //    (1000 ms * 60 s * 60 min * 24 h)
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    // 9. Vrácení výsledku
    return diffDays;
  };
  
  // Příklady použití (aktuální čas je čtvrtek 3. dubna 2025 14:31 CEST):
  try {
    // Zadané datum z příkladu (18. února 2025)
    const specificDate = "2025-02-18T14:02:50.273275Z";
    console.log(`Dny od ${specificDate}: ${calculateDaysSinceISO(specificDate)}`);
    // Očekávaný výsledek:
    // Normalizovaná data: 18. 2. 2025 00:00:00 (lokální čas) a 3. 4. 2025 00:00:00 (lokální čas)
    // Rozdíl je 44 celých dní.
  
    // Včerejší datum
    const yesterday = "2025-04-02T10:00:00Z";
    console.log(`Dny od ${yesterday}: ${calculateDaysSinceISO(yesterday)}`); // Očekáváme 1
  
    // Dnešní datum (ale časově předcházející aktuálnímu času)
    const todayEarlyUTC = "2025-04-03T01:00:00Z"; // 3. 4. 2025 03:00 CEST
    console.log(`Dny od ${todayEarlyUTC}: ${calculateDaysSinceISO(todayEarlyUTC)}`); // Očekáváme 0
  
    // Aktuální čas jako ISO string
    const nowISO = new Date().toISOString();
    console.log(`Dny od ${nowISO}: ${calculateDaysSinceISO(nowISO)}`); // Očekáváme 0
  
    // Budoucí datum
    const futureDate = "2026-01-01T00:00:00Z";
    // console.log(`Dny od ${futureDate}: ${calculateDaysSinceISO(futureDate)}`); // Vyhodí chybu "Nelze počítat dny od data v budoucnosti..."
  
    // Neplatné datum (např. 30. února)
    const invalidDate = "2025-02-30T10:00:00Z";
    // console.log(`Dny od ${invalidDate}: ${calculateDaysSinceISO(invalidDate)}`); // Vyhodí chybu "Neplatný nebo nepodporovaný formát..."
  
    // Další chybové stavy
    // console.log(calculateDaysSinceISO('')); // Vyhodí chybu "...nesmí být prázdný."
    // console.log(calculateDaysSinceISO(null)); // Vyhodí chybu "...nesmí být null ani undefined."
    // console.log(calculateDaysSinceISO('Not an ISO date')); // Vyhodí chybu "Neplatný nebo nepodporovaný formát..."
  
  } catch (error) {
    console.error('Došlo k chybě:', error.message);
  }

  export default calculateDaysSinceISO