import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import he from "he";

import { AppContext } from "../context/AppContext";

import contactPerson from "../img/contact-person-t.png";

import SimpleAlert from "./SimpleAlert";

import sendEvent from "../utils/functions/sendEvent";

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const ModalConsultation = () => {
  const { t } = useTranslation();
  const {
    isLogged,
    isModalOpen,
    closeModal,
    showAlert,
    closeAlert,
    isAlertVisible,
    alertMessage
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { lng } = useParams();

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [company, setCompany] = useState("");
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const nameRef = useRef(null);
  const surnameRef = useRef(null);


  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden"; // Zakáže skrolování na pozadí
      const handleEsc = (event) => {
        if (event.key === "Escape") {
          closeModal();
        }
      };
      window.addEventListener("keydown", handleEsc);

      return () => {
        document.body.style.overflow = ""; // Obnoví skrolování na pozadí
        window.removeEventListener("keydown", handleEsc);
      };
    }
  }, [isModalOpen, closeModal]);

  const handleNameSplit = () => {
    if (nameRef.current && surnameRef.current) {
      const nameInput = nameRef.current.value.split(" ");
      if (nameInput.length >= 2) {
        setName(nameInput.slice(0, -1).join(" "));
        setSurname(nameInput.slice(-1).join(" "));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkbox) {
      try {
        const dataToSend = {
          name: company,
          email: email,
          language: lng,
          message: `
              e-mail: ${email}<br>
              název společnosti: ${company}<br>
              ${phone ? "telefon: " + phone + "<br>" : ""}
              ${name ? "jméno: " + name + "<br>" : ""}
              ${surname ? "příjmení: " + surname + "<br>" : ""}
              ${date ? "datum: " + date + "<br>" : ""}
              ${message ? "zpráva: " + message : ""}
          `,
          subject: "PermoniQ",
          send_email: true,
          country: window.location.pathname.split("/")[1]
        };

        const response = await fetch("https://permoniq.com/api/send-message/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(dataToSend)
        });

        if (!response.ok) {
          showAlert(
            t("consultation.errorMessages.consultationErrorMsg"),
            "danger"
          );
          const errorDetails = await response.json();
          throw new Error(
            `Network response was not ok: ${response.status} ${
              response.statusText
            } - ${JSON.stringify(errorDetails)}`
          );
        }

        // Úspěšné odeslání
        showAlert(t("consultation.infoMessages.requestSentInfoMsg"), "info");

        // Reset formuláře
        setEmail("");
        setPhone("");
        setName("");
        setSurname("");
        setCompany("");
        setDate("");
        setMessage("");
        setCheckbox(false);
        sendEvent("consultation_request")
        // Zavření modalu
        closeModal();
      } catch (error) {
        showAlert(
          t("consultation.errorMessages.consultationErrorMsg"),
          "danger"
        );
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
          errorObject: error
        });
      }
    }
  };

  return (
    <>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          type={alertMessage.type}
          onClose={closeAlert}
        />
      )}
      <div
        className={`modal ${isModalOpen ? "is-open" : ""}`}
        id={t("consultation.id")}
      >
        <div className="modal__body">
          <div className="modal__close" onClick={closeModal}></div>
          <div className="modal__content">
            <div className="box">
              <h2
                dangerouslySetInnerHTML={renderHTML(t("consultation.title"))}
              ></h2>
              <p
                dangerouslySetInnerHTML={renderHTML(
                  t("consultation.description")
                )}
              ></p>
              <form
                action="#"
                autoComplete="off"
                method="post"
                className="form"
                onSubmit={handleSubmit}
              >
                <p>
                  <label htmlFor="contact-email">
                    {t("consultation.emailLabel")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                  </label>
                  <input
                    type="email"
                    id="contact-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </p>
                <p>
                  <label htmlFor="contact-phone">
                    {t("consultation.phoneLabel")}
                  </label>
                  <input
                    type="tel"
                    id="contact-phone"
                    value={phone}
                    onChange={(e) => {
                      const phoneInput = e.target.value;
                      if (/^(\+)?[\d\s]*$/.test(phoneInput)) {
                        setPhone(phoneInput);
                      }
                    }}
                  />
                </p>
                <div className="form__column">
                  <p>
                    <label htmlFor="contact-name">
                      {t("consultation.nameLabel")}
                    </label>
                    <input
                      type="text"
                      id="contact-name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      ref={nameRef}
                    />
                  </p>
                  <p>
                    <label htmlFor="contact-surname">
                      {t("consultation.surnameLabel")}
                    </label>
                    <input
                      type="text"
                      id="contact-surname"
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      ref={surnameRef}
                    />
                  </p>
                </div>
                <div className="form__column">
                  <p>
                    <label htmlFor="contact-company">
                      {t("consultation.companyLabel")}
                      <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                    </label>
                    <input
                      type="text"
                      id="contact-company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                  </p>
                  <p>
                    <label htmlFor="contact-date">
                      {t("consultation.dateLabel")}
                      <sub style={{ color: "red", fontSize: "1.5rem" }}></sub>
                    </label>
                    <input
                      type="date"
                      id="contact-date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </p>
                </div>
                <p>
                  <label htmlFor="contact-message">
                    {t("consultation.messageLabel")}
                  </label>
                  <textarea
                    id="contact-message"
                    rows="4"
                    cols="50"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </p>
                <p>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="contact-checkbox"
                      id="contact-checkbox"
                      checked={checkbox}
                      onChange={(e) => setCheckbox(e.target.checked)}
                      required
                    />
                    <span className="checkbox__label">
                      <Trans
                        i18nKey={t("consultation.checkboxLabel")}
                        components={[
                          <a
                            className="link"
                            href={`${window.location.origin}/${lng}/terms-of-service/`}
                            target="_blank"
                          />
                        ]}
                      />
                      <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                    </span>
                    <span className="checkbox__checker"></span>
                  </label>
                </p>
                <p className="form__submit">
                  <input
                    type="submit"
                    value={he.decode(t("consultation.submitButton"))}
                    className="btn"
                    disabled={!checkbox}
                  />
                </p>
                <sub style={{ color: "red", fontSize: "1.5rem" }}>
                  *
                  <em style={{ color: "black", fontSize: "1rem" }}>
                    {t("requireItem")}
                  </em>
                </sub>
              </form>
            </div>
            <div className="box is-connected center">
              <p>
                <img src={contactPerson} alt="" width="120" height="137" />
              </p>
              <p className="modal__contact">
                <span className="icon icon--phone">
                  <svg
                    className="icon__svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <use
                      xlinkHref="/img/icons.svg#phone"
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                    ></use>
                  </svg>
                </span>
                <a
                  href={`${
                    isLogged
                      ? t("permoniqPhoneHref")
                      : t("permoniqPhoneBusinessHref")
                  }`}
                >{`${
                  isLogged ? t("permoniqPhone") : t("permoniqPhoneBusiness")
                }`}</a>
                <small>{t("consultation.phoneHours")}</small>
              </p>
              <p className="modal__contact">
                <span className="icon icon--email">
                  <svg
                    className="icon__svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <use
                      xlinkHref="/img/icons.svg#email"
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                    ></use>
                  </svg>
                </span>
                <a
                  href="mailto:info@permoniq.com"
                  dangerouslySetInnerHTML={renderHTML(t("consultation.email"))}
                ></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalConsultation;
