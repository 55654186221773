import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";

import AppMenu from "../components/AppMenu";
import AppDashboard from "../components/AppDashboard";
import AppStatistics from "../components/AppStatistics";
import AppCampaigns from "../components/AppCampaigns";
import AppCredit from "../components/AppCredit";
import AppSettings from "../components/AppSettings";
import AppHelp from "../components/AppHelp";
import AppContactUs from "../components/AppContactUs";
import AppInvoice from "../components/AppInvoice";
import AppProducts from "../components/AppProducts";

import Modal from "../components/Modal";

import styles from "../css/AppContent.module.css";

import InfoBoxItem from "../components/InfoBoxItem";
import { useParams } from "react-router-dom";

const AppContent = () => {
  const { lng } = useParams();

  const {
    appContentType,
    setIsUserDataLoading,
    userData,
    selectedShop,
    showAdminModal,
    setShowAdminModal,
    infoItems,
  } = useContext(AppContext);

  useEffect(() => {
    if (sessionStorage.getItem("loading")) {
      setIsUserDataLoading(true);
      sessionStorage.removeItem("loading");
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        {appContentType === "dashboard" && <title>Dashboard - PermoniQ</title>}
        {appContentType === "statistics" && (
          <title>Statistiky - PermoniQ</title>
        )}
        {appContentType === "campaigns" && <title>Kampaně - PermoniQ</title>}
        {appContentType === "products" && <title>Produkty - PermoniQ</title>}
        {appContentType === "credit" && <title>Kredit - PermoniQ</title>}
        {appContentType === "settings" && <title>Nastavení - PermoniQ</title>}
        {appContentType === "help" && <title>Nápověda - PermoniQ</title>}
        {appContentType === "invoice" && <title>Fakturace - PermoniQ</title>}
        {appContentType === "contact-us" && <title>Kontakt - PermoniQ</title>}
      </Helmet>
      <main className="content content--app">
        <section className="container">
          <AppMenu />
          {appContentType === "dashboard" && <AppDashboard />}
          {appContentType === "statistics" && <AppStatistics />}
          {appContentType === "campaigns" && <AppCampaigns />}
          {appContentType === "products" && <AppProducts />}
          {appContentType === "credit" && <AppCredit />}
          {appContentType === "settings" && <AppSettings />}
          {appContentType === "help" && <AppHelp />}
          {appContentType === "invoice" && <AppInvoice />}
          {appContentType === "contact-us" && <AppContactUs />}
        </section>
      </main>

      {userData.email === "info@permoniq.com" && showAdminModal && (
        <Modal
          title="Informace o obchodu"
          cancelText="Zavřít"
          confirmText=""
          onClose={() => setShowAdminModal(false)}
        >
          {infoItems.map((item, index) => (
            <InfoBoxItem key={index} label={item.label} value={item.value} />
          ))}
        </Modal>
      )}
    </>
  );
};

export default AppContent;
