import React from "react";
import styles from "../../css/ProgressBar.module.css";
import stylesNav from "../../css/ProgressBarNav.module.css";

const Progressbar = ({
  children,
  value,
  threshold = 100,
  colorAbove = "#22e06c",
  colorBelow = "#ffda44",
  title,
  place = "menu",
  ...restProps
}) => {
  const progressBarColor = value >= threshold ? colorAbove : colorBelow;
  const activeStyles = place === "nav" ? stylesNav : styles;

  return (
    <div className={activeStyles.progressContainer} {...restProps}>
      {title && <p className={activeStyles.title}>{title}</p>}
      <span className={activeStyles.status}>
        <div
          className={activeStyles.progress__fill}
          style={{
            "--value": value,
            backgroundColor: progressBarColor
          }}
        />
        <em>{children}</em>
      </span>
    </div>
  );
};

export default Progressbar;
