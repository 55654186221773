import React from "react";

import styles from "../css/AppBox.module.css"

const AppBox = ({ children }) => {
  return (
    <>
      <div className="box">{children}</div>
    </>
  );
};

export default AppBox;
