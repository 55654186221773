import axiosInstance from "../../api/AxiosConfig";

/**
 * Updates the details of a specific campaign by sending a PATCH request.
 *
 * @param {string|number} campaignId - The unique identifier of the campaign to be updated. Must be a number.
 * @param {Object} objectForUpdateCampaign - The data object containing the fields to update. All properties are optional.
 * @param {number} [objectForUpdateCampaign.budget] - The budget for the campaign (double).
 * @param {number} [objectForUpdateCampaign.roas] - The Return on Ad Spend (ROAS) for the campaign (double).
 * @param {string} [objectForUpdateCampaign.campaign_status] - The status of the campaign.
 *   Allowed values:
 *   - `"active"`: The campaign is active.
 *   - `"paused"`: The campaign is paused.
 *   - `"deactived"`: The campaign is deactivated.
 * @throws {Error} Throws an error if the `campaignId` parameter is missing or not a number.
 * @throws {Error} Throws an error if the request fails, with the status and error message.
 * @returns {Promise<Object>} A promise that resolves to the updated campaign data.
 *
 * @example
 * // Example usage:
 * const updatedCampaignData = {
 *   budget: 5000.00,
 *   campaign_status: "paused"
 * };
 *
 * updateCampaign(12345, updatedCampaignData)
 *   .then(data => console.log("Campaign updated successfully:", data))
 *   .catch(error => console.error("Error updating campaign:", error.message));
 *
 * @example
 * // Updating only the ROAS:
 * updateCampaign(12345, { roas: 3.2 })
 *   .then(data => console.log("Campaign updated successfully:", data))
 *   .catch(error => console.error("Error updating campaign:", error.message));
 */
export async function updateCampaign(campaignId, objectForUpdateCampaign) {
    if (!campaignId) {
        throw new Error('The "campaignId" parameter is required.');
    }

    if (isNaN(Number(campaignId))) {
        throw new Error('The "campaignId" parameter must be a number.');
    }

    try {
        const response = await axiosInstance.patch(`campaign/${campaignId}/update/`, objectForUpdateCampaign);
        return response.data;
    } catch (error) {
        console.error(error)
    }
}
