import React, { useEffect } from "react";
import styles from "../css/ImageDialog.module.css";
import CloseBtn from "./CloseBtn";

const ImageDialog = ({ imageSrc, onClose }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose(); // Zavře modal při stisku klávesy Escape
      }
    };

    // Přidá event listener na stisk klávesy
    window.addEventListener("keydown", handleEsc);

    // Cleanup při odmountování komponenty
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  return (
    <div className={styles.dialog_overlay} onClick={onClose}>
      <img
        src={imageSrc}
        alt="Enlarged view"
        className={styles.enlarged_image}
        onClick={(e) =>
          e.stopPropagation()
        } /* Zabrání zavření modalu při kliknutí na obrázek */
      />
    
      <CloseBtn onClick={onClose} />
    </div>
  );
};

export default ImageDialog;
