import React, { useContext, useEffect, useState } from "react"; // Přidán import useState
import { AppContext } from "../../context/AppContext";
import { Helmet } from "react-helmet";
import SimpleAlert from "../../components/SimpleAlert";
import ButtonBtn from "../../components/buttons/ButtonBtn";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../css/2fa/TwoFactor.module.css";
import { generateEmailOtp } from "../../utils/axios_functions/2ea/generateEmailOpt";
import ModalLoading from "../../components/ModalLoading"; // <-- 1. Import ModalLoading

const TwoFactor = () => {
  const navigate = useNavigate();
  const { lng } = useParams();

  useEffect(() => {
    navigate("/cs/");
  }, []);

  const {
    // isUserDataLoading už je v AppContext, nemusíme ho znovu definovat lokálně
    isUserDataLoading, // <-- Použijeme isUserDataLoading z kontextu
    setIsUserDataLoading,
    isAlertVisible,
    alertMessage,
    closeAlert,
    showAlert,
  } = useContext(AppContext);

  // Lokální stav pro zprávu v ModalLoading
  const [message, setMessage] = useState("Pracuju na tom"); // Výchozí zpráva

  useEffect(() => {
    // Pokud chcete, aby se loading nezobrazoval při prvním načtení stránky
    setIsUserDataLoading(false);
  }, [setIsUserDataLoading]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const tokenCookie = Cookies.get("rTkn_4zF7P");
      if (tokenCookie) {
        clearInterval(intervalId);
        navigate(`/${lng}/`);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate, lng]);

  const handleResendOtp = async (e) => {
    e.target.blur();
    setMessage("Odesílám e-mail..."); 
    setIsUserDataLoading(true); 

    try {
      await generateEmailOtp();
     showAlert("E-mail odeslán!", "info")
    } catch (error) {
      console.error("Failed to resend email OTP:", error);
      let errorMessage =
        "Nepodařilo se odeslat ověřovací e-mail. Zkuste to prosím znovu.";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }
     
    } finally {
        setIsUserDataLoading(false); 
        if (!('error' in handleResendOtp && handleResendOtp.error)) { // Toto je komplikované a nespolehlivé
             // Raději ponechme alerty v try/catch
        }
    }
  };


  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Ověření - PermoniQ</title>
      </Helmet>
      {/* Zobrazení alertů */}
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {/* // <-- 2. Přidání ModalLoading do JSX */}
      {isUserDataLoading && <ModalLoading message={message} />}

      <section className="section two_factor">
        <article className="container">
          <div className={`box ${styles.twoFactorBox}`}>
            <div className={styles.verificationMessage}>
              <h2>Dvoufázové ověření</h2>
              <p>
                Na zadanou e-mailovou adresu jsme odeslali zprávu s ověřovacím
                odkazem.
                <br />
                Prosím, zkontrolujte svou e-mailovou schránku a klikněte na
                odkaz pro dokončení ověření.
              </p>
              <p>
                Pokud e-mail nevidíte, zkontrolujte také složku{" "}
                <strong>Spam</strong> nebo <strong>Hromadné</strong>.
              </p>
              <p>
                Odkaz má omezenou platnost, proto doporučujeme ověření provést
                co nejdříve.
              </p>
            </div>
            <ButtonBtn
              style={{ margin: "1rem 0 0 0" }}
              onClick={handleResendOtp}
              // Můžete přidat disabled stav, když se načítá
              disabled={isUserDataLoading}
            >
              {/* Změna textu tlačítka během načítání (volitelné) */}
              {isUserDataLoading ? "Odesílám..." : "Poslat znovu"}
            </ButtonBtn>
          </div>
        </article>
      </section>
    </>
  );
};

export default TwoFactor;