import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"

const Switcher = ({ campaignStatus, onToggle }) => {
    const { t } = useTranslation()

    const [isChecked, setIsChecked] = useState(campaignStatus === "active");

    useEffect(() => {
        setIsChecked(campaignStatus === "active");
    }, [campaignStatus]);

    const handleToggle = () => {
        const newStatus = !isChecked ? "active" : "paused";
        onToggle(newStatus); // Volání funkce z rodiče
    };

    return (
        <label className="switcher switcher--service">
            <input
                type="checkbox"
                name="checkbox"
                checked={isChecked}
                onChange={handleToggle} // Kliknutí vyvolá modal
            />
            {isChecked ? (
                <span className="switcher__label is-checked" style={{ top: "-20px" }}>{t("switcher.active")}</span>
            ) : (
                <span className="switcher__label is-unchecked" style={{ top: "-20px" }}>{t("switcher.paused")}</span>
            )}
            <span className="switcher__back"></span>
            <span className="switcher__note">{t("switcher.state")}</span>
        </label>
    );
};

export default Switcher;
