import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "../context/AppContext";

import axiosInstance from "../api/AxiosConfig";

import contact from "../img/contact-person.png";
import icons from "../img/icons.svg";

import SimpleAlert from "./SimpleAlert";
import ModalLoading from "./ModalLoading";
import { useParams } from "react-router-dom";

import sendEvent from "../utils/functions/sendEvent";

const AppContactUs = () => {
  const { t } = useTranslation();
  const { lng } = useParams();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [msgForSend, setMsgForSend] = useState("");

  const [shopForMessage, setShopForMessage] = useState({});

  const {
    uuid,
    userData,
    isAlertVisible,
    showAlert,
    closeAlert,
    selectedShop,
    alertMessage,
    isUserDataLoading,
    setIsUserDataLoading
  } = useContext(AppContext);

  useEffect(() => {
    sendEvent("app_contact-us");
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setShopForMessage(
        userData?.shops?.find((oneShop) => oneShop.uuid === uuid)
      );
    }
  }, [uuid, shopForMessage]);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsgForSend("Odesílám vaši zprávu");
    setIsUserDataLoading(true);

    try {
      await axiosInstance.post("send-message/", {
        name: shopForMessage.name,
        email: userData?.email,
        message: `Uživatel 
        email: ${userData.email}<br>
        UUID: ${selectedShop.uuid}<br>
        name: ${selectedShop.name}<br>
        website: ${selectedShop.website}<br>
        phone: ${selectedShop.phone}<br>
        first_name: ${selectedShop.first_name}<br>
        last_name: ${selectedShop.last_name}<br>
        poslal tuto zprávu: <br>${message}`,
        subject: subject,
        send_email: true,
        language: lng
        // country: window.location.pathname.split("/")[1]
      });
      showAlert(t("appContactUs.infoMessages.messageSentInfoMsg"), "info");

      setMessage("");
      setSubject("");
    } catch (error) {
      showAlert("appContactUs.errorMessages.messageSentErrorMsg", "danger");
    } finally {
      setIsUserDataLoading(false);
    }
  };

  return (
    <>
      {isUserDataLoading && <ModalLoading message={msgForSend} />}

      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <article className="app-content">
        <h1>{t("appContactUs.title")}</h1>

        <div className="box contact contact--app">
          <p className="contact__person">
            <img
              src={contact}
              alt={t("appContactUs.contactPersonAlt")}
              width="120"
              height="137"
              loading="lazy"
            />
          </p>
          <p className="contact__item">
            <span className="icon icon--phone">
              <svg
                className="icon__svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <use
                  xlinkHref={`${icons}#phone`}
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                ></use>
              </svg>
            </span>
            <a
              onClick={() => {
                sendEvent("click_phone");
              }}
              href={t("permoniqPhoneHref")}
            >
              {t("permoniqPhone")}
            </a>
            <small>{t("appContactUs.phoneHours")}</small>
          </p>
          <p className="contact__item">
            <span className="icon icon--email">
              <svg
                className="icon__svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <use
                  xlinkHref={`${icons}#email`}
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                ></use>
              </svg>
            </span>
            <a
              onClick={() => {
                sendEvent("click_mail");
              }}
              href="mailto:info@permoniq.com"
            >
              info@permoniq.com
            </a>
          </p>
        </div>

        <div className="box">
          <h2>{t("appContactUs.sendMessageTitle")}</h2>

          <form onSubmit={handleSubmit} autoComplete="off" className="form">
            <p>{t("appContactUs.subject")}:</p>
            <p> {t("appContactUs.message")}:</p>
            <p>
              <input
                type="text"
                id="contact-subject"
                value={subject}
                onChange={handleSubjectChange}
              />
            </p>

            <p>
              <textarea
                id="contact-message"
                rows="4"
                cols="50"
                value={message}
                onChange={handleMessageChange}
              ></textarea>
            </p>

            <p className="form__submit">
              <input
                type="submit"
                value={t("appContactUs.sendMessageButton")}
                className="btn"
              />
            </p>
          </form>
        </div>
      </article>
    </>
  );
};

export default AppContactUs;
