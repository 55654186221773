import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { AppContext } from "../context/AppContext";

import Cookies from "js-cookie";

import logo from "../img/logo-inverse.svg";
import phoneLogo from "../img/icons/phone.svg";
import loginLogo from "../img/icons/login.svg";

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

import { clearUserDetailStorage } from "../api/AxiosConfig";
import sendEvent from "../utils/functions/sendEvent";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag
};

const NavbarMain = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(lng || "cs");
  const [showLogout, setShowLogout] = useState(false); // Stav pro zobrazení odhlášení

  const languageRef = useRef(null);
  const logoutRef = useRef(null); // Ref pro odhlášení
  const accountButtonRef = useRef(null); // Ref pro tlačítko

  const {
    userData,
    isLogged,
    checkLoginStatus,
    appContentType,
    setIsUserDataLoading
  } = useContext(AppContext);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    window.location.href = `${window.location.origin}/${lng}/`;
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    const userToken = Cookies.get("rTkn_4zF7P");

    if (!userToken) {
      navigate(`/${lng}/login/`, { replace: true });
      return;
    }

    if (userData?.shops?.length === 1) {
      const shop = userData.shops[0];
      if (shop.status_create !== "end") {
        navigate(`/${lng}/app/register-shop/`, { replace: true });
        return;
      }
    } else if (Object.keys(userData?.shops).length === 0) {
      navigate(`/${lng}/app/register-shop/`, { replace: true });
      return;
    } else if (Object.keys(userData?.shops).length > 1) {
      navigate(`/${lng}/app/shop-option/`, { replace: true });
      return;
    }

    navigate(isLogged ? `/${lng}/app/${appContentType}/` : `/${lng}/login/`, {
      replace: true
    });
  };

  const handleToggleLogout = () => {
    setShowLogout(!showLogout);
  };

  const onLogout = () => {
    // deleteAllDatabases()
    setIsUserDataLoading(true);
    Cookies.remove("rTkn_4zF7P");
    Cookies.remove("aTkn_0yD9K");
    Cookies.remove("utk_98x76");
    clearUserDetailStorage();
    // deleteAllDatabases()
    navigate(`/${lng}/login/`);
    window.location.reload(true);
  };

  const handleClickOutside = (event) => {
    if (
      logoutRef.current &&
      !logoutRef.current.contains(event.target) &&
      accountButtonRef.current &&
      !accountButtonRef.current.contains(event.target)
    ) {
      setShowLogout(false);
    }

    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language.toLowerCase());
    setSelectedLanguage(language.toLowerCase());
    setIsLanguageSwitchVisible(false);
    navigate(`/${language.toLowerCase()}/`);
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      // Přidej hash do URL
      window.location.hash = sectionId;
    }
  };

  return (
    <div className="container" data-navbar="main">
      <div className="header__logo">
        <img
          src={logo}
          alt="PermoniQ"
          width="151"
          height="32"
          title={t("nav.home")}
          onClick={handleLogoClick}
          style={{ cursor: "pointer" }}
        />
      </div>
      <nav className="nav">
        <ul>
          <li>
            <a
              href={`#${t("paths.benefits")}`}
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(t("paths.benefits"));
              }}
            >
              {t("nav.benefits")}
            </a>
          </li>
          <li>
            <a
              href={`#${t("paths.whatItCanDo")}`}
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(t("paths.whatItCanDo"));
              }}
            >
              {t("nav.whatItCanDo")}
            </a>
          </li>
          <li>
            <a
              href={`#${t("paths.comparison")}`}
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(t("paths.comparison"));
              }}
            >
              {t("nav.comparison")}
            </a>
          </li>
          <li>
            <a
              href={`#${t("paths.forWhom")}`}
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(t("paths.forWhom"));
              }}
            >
              {t("nav.forWhom")}
            </a>
          </li>
          <li>
            <a
              href={`#${t("paths.references")}`}
              onClick={(e) => {
                e.preventDefault();
                scrollToSection(t("paths.references"));
              }}
            >
              {t("nav.references")}
            </a>
          </li>
          {!isLogged && (
            <li className="nav__cta">
              <a
                href={`#${t("paths.tryFree")}`}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(t("paths.tryFree"));
                  sendEvent("click_try-free");
                }}
                className="btn"
              >
                {t("nav.tryFree")}
              </a>
            </li>
          )}
          <li className="nav__phone">
            <a
              onClick={() => {
                sendEvent("click-phone");
              }}
              href={`${
                isLogged
                  ? t("permoniqPhoneHref")
                  : t("permoniqPhoneBusinessHref")
              }`}
            >
              <span className="icon icon--phone">
                <ReactSVG src={phoneLogo} className="icon__svg" />
              </span>
              {`${isLogged ? t("permoniqPhone") : t("permoniqPhoneBusiness")}`}
            </a>
            {/* <a href={t("permoniqPhoneHref")}>
                            <span className='icon icon--phone'>
                                <ReactSVG src={phoneLogo} className="icon__svg" />
                            </span>
                            &nbsp;{t("permoniqPhone")}
                        </a> */}
          </li>
          <li className="nav__login">
            {isLogged ? (
              <a onClick={handleToggleLogout} ref={accountButtonRef}>
                <span className="icon icon--login">
                  <ReactSVG src={loginLogo} className="icon__svg" />
                </span>
                {t("nav.myAccount")}
              </a>
            ) : (
              <a onClick={handleLoginClick}>
                <span className="icon icon--login">
                  <ReactSVG src={loginLogo} className="icon__svg" />
                </span>
                {t("nav.login")}
              </a>
            )}

            {/* Zobrazení odhlašovacího okénka */}
            {showLogout && isLogged && (
              <div className="logout-popup" ref={logoutRef}>
                <p className="logout-name">{userData?.email}</p>
                <button className="logout-btn" onClick={handleLoginClick}>
                  {t("nav.app")}
                </button>
                <button className="logout-btn" onClick={onLogout}>
                  {t("nav.logout")}
                </button>
              </div>
            )}
          </li>
          {/* <li className="nav__language" ref={languageRef}>
                        <a href="" onClick={toggleLanguageSwitch}>
                            <img src={flags[selectedLanguage]} alt={t(`loginLanguagesAppNavBar.${selectedLanguage}`)} />
                        </a>
                        <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
                            {Object.keys(flags).map((lang) => (
                                <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                                    <img src={flags[lang.toLowerCase()]} alt={t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)} /> {t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)}
                                </a>
                            ))}
                        </div>
                    </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default NavbarMain;
