import React, { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Cookies from "js-cookie";

import axiosInstance from "../api/AxiosConfig";

import { AppContext } from "../context/AppContext";

import LoadingModal from "../components/ModalLoading";

import sendEvent from "../utils/functions/sendEvent";

const LoadingGoogleLogin = () => {
  const { lng } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, setUserData, setIsUserDataLoading, appContentType } =
    useContext(AppContext);

  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get("user/detail/");
      setUserData(response.data);
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const state = queryParams.get("state");

    Cookies.set("state", state);
    if (code) {
      if (state.includes("connect-google")) {
        connectGoogle("auth/google/", code, "auth/google/callback/");
        // window.location.href = state
      } else {
        fetch("https://permoniq.com/api/auth/google/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code,
            state,
            language: lng,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              setIsUserDataLoading(false);
              navigate(`/${lng}/`, { replace: true });
              throw new Error("Invalid login credentials");
            }
            return response.json();
          })
          .then((data) => {
            const { access, refresh, pk, register } = data;

            if (register === "True") {
              sendEvent("register_user");
              window?.index_conversion?.automatic_event?.push({
                name: "register_user",
              });
            } else {
              sendEvent("login");
            }

            // Nastavení secure cookies s životností 7 dní
            Cookies.set("aTkn_0yD9K", access, {
              secure: true,
              sameSite: "None",
              expires: 1,
            });
            Cookies.set("utk_98x76", pk, {
              secure: true,
              sameSite: "None",
              expires: 7,
            });
            Cookies.set("rTkn_4zF7P", refresh, {
              secure: true,
              sameSite: "None",
              expires: 7,
            });

            // Pokud se jedná o registraci, proveď update
            if (register === "True") {
              let gclid = Cookies.get("_gcl_aw")
                ? Cookies.get("_gcl_aw").split(".")[2]
                : "";
              let client_id = Cookies.get("_ga")
                ? Cookies.get("_ga").split(".")[2] +
                  "." +
                  Cookies.get("_ga").split(".")[3]
                : "";
              let session_id = Cookies.get("_ga_7HEEGNJE8V")
                ? Cookies.get("_ga_7HEEGNJE8V").split(".")[2]
                : "";
              return axiosInstance.patch("/user/update/", {
                gclid,
                client_id,
                session_id,
              });
            } else {
              // Pokud nejde o registraci, vrátíme vyřešený promise
              return Promise.resolve();
            }
          })
          .then((updateResponse) => {
            if (updateResponse) {
              console.log("Update response:", updateResponse.data);
            }
            fetchUserData();
          })

          .catch((error) => {
            setIsUserDataLoading(false);
            console.error("Error:", error);

            navigate(`/${lng}/`, { replace: true });

            alert("Invalid login credentials");
          });
      }
    } else {
      setIsUserDataLoading(false);
      navigate(`/${lng}/`, { replace: true });
    }
  }, [location, navigate]);

  // useEffect pro přesměrování, jakmile je userData naplněno
  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      // přidat kontrolu shop
      if (userData?.email === "info@permoniq.com") {
        window.scrollTo(0, 0);
        navigate(`/${lng}/app/shop-option/`, { replace: true });
      } else if (
        Object.keys(userData.shops).length > 0 &&
        userData.shops[0].status_create !== "step1"
      ) {
        window.scrollTo(0, 0);
        navigate(`/${lng}/app/shop-option/`, { replace: true });
      } else {
        window.scrollTo(0, 0);
        navigate(`/${lng}/app/register-shop/`, { replace: true });
      }
    }
  }, [userData, navigate, lng]);

  const connectGoogle = async (url, code, state) => {
    try {
      const connectGoogleResponse = await axiosInstance.post(url, {
        code,
        state: `${window.location.origin}/cs/auth/google/callback/`,
      });

      if (connectGoogleResponse.status === 200) {
        window.location.href = `${window.location.origin}/cs/app/connect-google/`;
      }
    } catch (error) {
      // Logování chyby
      console.log("Connect Google Error:", error.message);

      // Pokud existuje odpověď serveru při chybě, zalogujte podrobnosti
      if (error.response) {
        console.log("Error response status:", error.response.status);
        console.log("Error response headers:", error.response.headers);
        console.log("Error response data:", error.response.data);
      } else if (error.request) {
        // Logování samotného requestu, pokud nedošlo k odpovědi
        console.log("No response received:", error.request);
      } else {
        // Jiné chyby (např. při nastavení requestu)
        console.log("Error setting up the request:", error.message);
      }

      console.log("Full error object:", error);
    }
  };

  return (
    <div>
      <LoadingModal />
    </div>
  );
};

export default LoadingGoogleLogin;
