import React, { useState, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "../css/DpTutor.module.css";
import CloseBtn from "./CloseBtn";

// Import log ikon
import gtmLogo from "../img/logos/GTM.svg";
import shoptetLogo from "../img/logos/Shoptet.svg";
import upgatesLogo from "../img/logos/Upgates.svg";
import shopifyLogo from "../img/logos/Shopify.svg";
import woocommerceLogo from "../img/logos/WooCommerce.svg";
import prestashopLogo from "../img/logos/PrestaShop.svg";
import eshopRychleLogo from "../img/logos/Eshop-rychle.svg";
import shopsysLogo from "../img/logos/Shopsys.svg";
import webarealLogo from "../img/logos/Webareal.svg";
import nodyLogo from "../img/logos/Nody.svg";
import fastCentrikLogo from "../img/logos/logo-fascentrik_certify.png";

const DpTutor = ({ onClose }) => {
  const { t } = useTranslation();

  // Načtení kroků pomocí překladů
  let gtmSteps = t("installTrackingCodeForm.gtmSteps", { returnObjects: true });
  let shoptetSteps = t("installTrackingCodeForm.shoptetSteps", {
    returnObjects: true,
  });
  let upgatesSteps = t("installTrackingCodeForm.upgatesSteps", {
    returnObjects: true,
  });
  let shopifySteps = t("installTrackingCodeForm.shopifySteps", {
    returnObjects: true,
  });
  let woocommerceSteps = t("installTrackingCodeForm.woocommerceSteps", {
    returnObjects: true,
  });
  let prestashopSteps = t("installTrackingCodeForm.prestashopSteps", {
    returnObjects: true,
  });
  let eshopRychleSteps = t("installTrackingCodeForm.eshopRychleSteps", {
    returnObjects: true,
  });
  let shopsysSteps = t("installTrackingCodeForm.shopsysSteps", {
    returnObjects: true,
  });
  let webarealSteps = t("installTrackingCodeForm.webarealSteps", {
    returnObjects: true,
  });
  let nodySteps = t("installTrackingCodeForm.nodySteps", {
    returnObjects: true,
  });
  let fastCentrikSteps = t("installTrackingCodeForm.fastCentrikSteps", {
    returnObjects: true,
  });

  // Ošetření, aby šlo o pole
  gtmSteps = Array.isArray(gtmSteps) ? gtmSteps : [];
  shoptetSteps = Array.isArray(shoptetSteps) ? shoptetSteps : [];
  upgatesSteps = Array.isArray(upgatesSteps) ? upgatesSteps : [];
  shopifySteps = Array.isArray(shopifySteps) ? shopifySteps : [];
  woocommerceSteps = Array.isArray(woocommerceSteps) ? woocommerceSteps : [];
  prestashopSteps = Array.isArray(prestashopSteps) ? prestashopSteps : [];
  eshopRychleSteps = Array.isArray(eshopRychleSteps) ? eshopRychleSteps : [];
  shopsysSteps = Array.isArray(shopsysSteps) ? shopsysSteps : [];
  webarealSteps = Array.isArray(webarealSteps) ? webarealSteps : [];
  nodySteps = Array.isArray(nodySteps) ? nodySteps : [];
  fastCentrikSteps = Array.isArray(fastCentrikSteps) ? fastCentrikSteps : [];

  // Sestavení pole FAQ položek s logy a (volitelnými) odkazy
  const faqItems = [
    {
      logo: gtmLogo,
      altText: t("installTrackingCodeForm.gtmTitle"),
      steps: gtmSteps,
      anchor: (
        <a
          href="https://www.shoptet.cz"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: shoptetLogo,
      altText: t("installTrackingCodeForm.shoptetTitle"),
      steps: shoptetSteps,
      anchor: (
        <a
          href="https://www.shoptet.cz"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: upgatesLogo,
      altText: t("installTrackingCodeForm.upgatesTitle"),
      steps: upgatesSteps,
      anchor: (
        <a
          href="https://www.upgates.cz"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: shopifyLogo,
      altText: t("installTrackingCodeForm.shopifyTitle"),
      steps: shopifySteps,
      anchor: (
        <a
          href="https://www.shopify.com"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: woocommerceLogo,
      altText: t("installTrackingCodeForm.woocommerceTitle"),
      steps: woocommerceSteps,
    },
    {
      logo: prestashopLogo,
      altText: t("installTrackingCodeForm.prestashopTitle"),
      steps: prestashopSteps,
      anchor: (
        <a
          href="https://www.prestashop.com"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: eshopRychleLogo,
      altText: t("installTrackingCodeForm.eshopRychleTitle"),
      steps: eshopRychleSteps,
      anchor: (
        <a
          href="https://www.eshop-rychle.cz"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: shopsysLogo,
      altText: t("installTrackingCodeForm.shopsysTitle"),
      steps: shopsysSteps,
      anchor: (
        <a
          href="https://www.shopsys.cz/"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: webarealLogo,
      altText: t("installTrackingCodeForm.webarealTitle"),
      steps: webarealSteps,
      anchor: (
        <a
          href="https://www.webareal.cz/"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    // Nová položka pro Nody
    {
      logo: nodyLogo,
      altText: t("installTrackingCodeForm.nodyTitle"),
      steps: nodySteps,
      anchor: (
        <a
          href="https://www.nody.cz/"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    },
    {
      logo: fastCentrikLogo,
      altText: t("installTrackingCodeForm.fastCentrikTitle"),
      steps: fastCentrikSteps,
      anchor: (
        <a
          href="https://www.fastcentrik.cz/"
          target="_blank"
          className={styles.link}
          rel="noopener noreferrer"
        />
      ),
    }
  ];

  const [openIndices, setOpenIndices] = useState([]);
  const [heights, setHeights] = useState({});
  const answerRefs = useRef([]);

  const handleToggle = (index) => {
    setOpenIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Při prvním renderu zjistíme výšky odpovědí
  useEffect(() => {
    const newHeights = {};
    answerRefs.current.forEach((ref, index) => {
      if (ref) {
        newHeights[index] = ref.scrollHeight;
      }
    });
    setHeights(newHeights);
  }, []);

  useEffect(() => {
    setHeights((prevHeights) => {
      const newHeights = { ...prevHeights };
      openIndices.forEach((index) => {
        if (answerRefs.current[index]) {
          newHeights[index] = answerRefs.current[index].scrollHeight;
        }
      });
      return newHeights;
    });
  }, [openIndices]);

  return (
    <div className={styles.container} onClick={onClose}>
      <CloseBtn onClick={onClose} />
      <div className={styles.modal_box} onClick={(e) => e.stopPropagation()}>
        <h2>{t("dpTutor.heading")}</h2>
        <div className={styles.textBox}>
          {faqItems.map((item, index) => (
            <div
              key={index}
              className={`faq__item ${styles.dpTutorItem} ${
                openIndices.includes(index) ? "is-open" : ""
              }`}
              onClick={() => handleToggle(index)}
            >
              <h3 className={styles.dpTutorQuestion}>
                <img
                  className={styles.logoIcon}
                  src={item.logo}
                  alt={item.altText}
                />
              </h3>
              <div
                className={styles.dpTutorAnswer}
                ref={(el) => (answerRefs.current[index] = el)}
                style={{
                  maxHeight: openIndices.includes(index)
                    ? `${heights[index]}px`
                    : "0",
                }}
              >
                <ol>
                  {item.steps.map((step, stepIndex) => (
                    <li key={stepIndex}>
                      <Trans
                        i18nKey={step}
                        components={[
                          <a
                            href="https://tagmanager.google.com/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://www.shoptet.cz/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://www.upgates.cz/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://www.shopify.com/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://addons.prestashop.com/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://www.eshop-rychle.cz/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://www.shopsys.cz/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            className="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              // Můžete přidat funkci handleScroll, pokud ji máte definovanou
                            }}
                          />,
                          <a
                            href="https://www.webareal.cz/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://www.nody.cz/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />,
                          <a
                            href="https://www.fastcentrik.cz/"
                            target="_blank"
                            className={"link"}
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                          />
                        ]}
                      />
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DpTutor;
