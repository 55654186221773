/**
 * Formats a numeric value as a string using the Czech locale (`cs-CZ`), with a customizable
 * number of decimal places.
 *
 * If `max` is less than `min`, the function adjusts `min` to match `max` to ensure valid formatting.
 * It uses `Intl.NumberFormat` for locale-aware number formatting.
 *
 * @param {number} value - The number to format.
 * @param {number} [min=2] - The minimum number of decimal places to display.
 * @param {number} [max=2] - The maximum number of decimal places to display.
 * @returns {string} The formatted number string.
 *
 * @example
 * formatNumber(1234.5);
 * // Returns: "1 234,50"
 *
 * @example
 * formatNumber(1234.5678, 1, 3);
 * // Returns: "1 234,568"
 *
 * @example
 * formatNumber(1234.5678, 3, 1);
 * // Returns: "1 234,568" (min is adjusted to match max)
 */
const formatNumber = (value, min = 2, max = 2) => {
  if (typeof(value) !== "number") {
    value = parseFloat(value)
  }
  if (max < min) {
    min = max;
  }
  return new Intl.NumberFormat("cs-CZ", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  }).format(value);
};

export default formatNumber;