import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import styles from "../css/AppProducts.module.css";
import AppComponent from "./AppComponent";
import AppBox from "./AppBox";
import ProductsStateBox from "./ProductsStateBox";
import axiosInstance from "../api/AxiosConfig";
import { AppContext } from "../context/AppContext";
import ProductsTable from "./ProductsTable";
import ProductsGraph from "./ProductsGraph";
import { formatDate } from "../utils/functions/formatDate";
import formatNumber from "../utils/functions/formatNumber";

// --- Fetcher funkce ---
// Funkce pro API volání grafových statistik
const fetchGraphStats = async (shopId) => {
  if (!shopId) throw new Error("Shop ID is required");
  const { data } = await axiosInstance.get(`shop/stats/graph-stats/${shopId}/`);
  return data;
};

// Funkce pro API volání statistik produktů (tabulka)
const fetchProductStats = async (shopId) => {
  if (!shopId) throw new Error("Shop ID is required");
  const { data } = await axiosInstance.get(`shop/stats/product-stats/${shopId}/`);
  return data;
};

const AppProducts = () => {
  const { selectedShop } = useContext(AppContext);
  const { lng } = useParams();
  const shopId = selectedShop?.uuid;

  // React Query pro načtení dat
  const graphStatsQuery = useQuery({
    queryKey: ["graphStats", shopId],
    queryFn: () => fetchGraphStats(shopId),
    enabled: !!shopId,
  });
  const productStatsQuery = useQuery({
    queryKey: ["productStats", shopId],
    queryFn: () => fetchProductStats(shopId),
    enabled: !!shopId,
  });

  // Lokální stav pro vybraný den (ze grafu)
  const [selectedGraphItem, setSelectedGraphItem] = useState(null);
  const [productsTotal, setProductsTotal] = useState(0);
  const [isInitialSelectionDone, setIsInitialSelectionDone] = useState(false);

  // Odvození dat pro graf
  const combinedGraphData = useMemo(() => {
    if (!graphStatsQuery.data) return [];
    return graphStatsQuery.data.map((item) => ({
      date: item.date,
      approved_count: item.approved_count || 0,
      suspended_count: item.suspended_count || 0,
      pending_count: item.pending_count || 0,
      rejected_count: item.rejected_count || 0,
    }));
  }, [graphStatsQuery.data]);

  // Při načtení grafových dat nastavíme výchozí výběr jen jednou
  useEffect(() => {
    if (graphStatsQuery.isSuccess && combinedGraphData.length > 0 && !isInitialSelectionDone) {
      const lastItem = combinedGraphData[combinedGraphData.length - 1];
      setSelectedGraphItem(lastItem);
      setIsInitialSelectionDone(true);
    } else if (!graphStatsQuery.isLoading && combinedGraphData.length === 0) {
      setSelectedGraphItem(null);
    }
  }, [combinedGraphData, graphStatsQuery.isSuccess, graphStatsQuery.isLoading, isInitialSelectionDone]);

  // Výpočet celkového počtu produktů
  useEffect(() => {
    if (selectedGraphItem) {
      const total =
        (selectedGraphItem.approved_count || 0) +
        (selectedGraphItem.suspended_count || 0) +
        (selectedGraphItem.pending_count || 0) +
        (selectedGraphItem.rejected_count || 0);
      setProductsTotal(total);
    } else {
      setProductsTotal(0);
    }
  }, [selectedGraphItem]);

  // Handler, který nastaví vybraný den dle kliknutí v grafu
  const handleDayClick = (dayData) => {
    if (dayData) {
      setSelectedGraphItem(dayData);
    }
  };

  // Zobrazíme chybovou hlášku, pokud došlo k chybě
  if (graphStatsQuery.isError || productStatsQuery.isError) {
    return (
      <AppComponent pageEvent={"app_products"}>
        <AppBox>
          <div>
            Chyba při načítání dat:{" "}
            {graphStatsQuery.error?.message || productStatsQuery.error?.message}
          </div>
        </AppBox>
      </AppComponent>
    );
  }

  return (
    <AppComponent pageEvent={"app_products"}>
      <AppBox>
        <div className={styles.headingBox}>
          <h4 className={styles.heading4}>
            Souhrný stav k{" "}
            <span className={styles.date}>
              {selectedGraphItem ? formatDate(lng, selectedGraphItem.date) : "..."}
            </span>
          </h4>
          <p className={styles.parCount}>
            Produktů celkem: {formatNumber(productsTotal, undefined, 0)}
          </p>
        </div>
        <div className={styles.productsStateBox}>
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${((selectedGraphItem.approved_count / productsTotal) * 100).toFixed(
                    2
                  )} %`
                : "0 %"
            }
            productsCount={
              selectedGraphItem ? formatNumber(selectedGraphItem.approved_count, undefined, 0) : 0
            }
            bgColor={"#22E06C"}
            state={"Aktivní"}
          />
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${(
                    (selectedGraphItem.suspended_count / productsTotal) *
                    100
                  ).toFixed(2)} %`
                : "0 %"
            }
            productsCount={
              selectedGraphItem ? formatNumber(selectedGraphItem.suspended_count, undefined, 0) : 0
            }
            bgColor={"#ED7200"}
            state={"Končící platnost"}
          />
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${(
                    (selectedGraphItem.pending_count / productsTotal) *
                    100
                  ).toFixed(2)} %`
                : "0 %"
            }
            productsCount={
              selectedGraphItem ? formatNumber(selectedGraphItem.pending_count, undefined, 0) : 0
            }
            bgColor={"#0000FF"}
            state={"Čekající na vyřízení"}
          />
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${(
                    (selectedGraphItem.rejected_count / productsTotal) *
                    100
                  ).toFixed(2)} %`
                : "0 %"
            }
            productsCount={
              selectedGraphItem ? formatNumber(selectedGraphItem.rejected_count, undefined, 0) : 0
            }
            bgColor={"#FF0000"}
            state={"Zamítnuto"}
          />
        </div>
        {/* Předáme data a handler do grafové komponenty */}
        <ProductsGraph data={combinedGraphData} onDayClick={handleDayClick} />
      </AppBox>
      <AppBox>
        <div className={styles.headingBox}>
          <h4 className={styles.heading4}>Detailní stav jednotlivých produktů</h4>
        </div>
        <ProductsTable data={productStatsQuery.data || []} />
      </AppBox>
    </AppComponent>
  );
};

export default AppProducts;
