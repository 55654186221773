import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import axiosInstance from "../api/AxiosConfig";

import { AppContext } from "../context/AppContext";

import { sendErrorMessage } from "../utils/axios_functions/sendErrorMessage";
import { validateIco } from "../utils/axios_functions/validateIco";

import SimpleAlert from "../components/SimpleAlert";
import ProgressTracker from "../components/ProgressTracker";
import ModalLoading from "../components/ModalLoading";

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";

import useUserRegisterDetails from "../hooks/useUserRegisterDetails";

import ModalContactUs from "../components/ModalContactUs";
import Modal from "../components/Modal";
import InfoBoxItem from "../components/InfoBoxItem";

import CopmanyDetailsStyles from "../css/CompanyDetailsStyles.module.css";

import sendEvent from "../utils/functions/sendEvent";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const countryIcoFormats = {
  cs: /^\d{8}$/,
  sk: /^\d{8}$/,
  hu: /^\d{8}$/,
  pl: /^\d{10}$/,
  ro: /^\d{2,10}$/,
};

const CompanyDetails = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    name: "",
    street: "",
    city: "",
    postal_code: "",
    ico: "",
    dic: "",
    email_billing: "",
    country_billing: "cs",
  });

  const [errors, setErrors] = useState({});
  const [isCountrySelectOpen, setIsCountrySelectOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isPulsing, setIsPulsing] = useState(false);
  const [companyInfoFromIco, setCompanyInfoFromIco] = useState(null);
  const [showIcoTooltip, setShowIcoTooltip] = useState(false);

  const {
    userData,
    uuid,
    steps,
    isAdmin,
    isAlertVisible,
    showAlert,
    closeAlert,
    alertMessage,
    selectedShop,
    setSelectedShop,
    isUserDataLoading,
    setIsUserDataLoading,
    showAdminModal,
    setShowAdminModal,
    infoItems,
  } = useContext(AppContext);

  useUserRegisterDetails();

  useEffect(() => {
    sendEvent(`rg_${location.pathname.split("/")[3]}`);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (lng === "en") {
      setFormData((prevState) => ({ ...prevState, country_billing: "cs" }));
    } else {
      setFormData((prevState) => ({ ...prevState, country_billing: lng }));
    }
  }, [lng]);

  useEffect(() => {
    if (isCountrySelectOpen) {
      const handleKeyEvent = (e) => {
        if (e.key === "Escape") {
          toggleCountry();
        }
      };

      const handleClickOutside = (e) => {
        if (!e.target.closest(".country")) {
          setIsCountrySelectOpen(false);
        }
      };

      window.addEventListener("keydown", handleKeyEvent);
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("keydown", handleKeyEvent);
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isCountrySelectOpen]);

  useEffect(() => {
    const getCompanyInfo = () => {
      if (Object.keys(userData).length > 0) {
        if (uuid) {
          setSelectedShop(
            userData?.shops.find((oneShop) => oneShop.uuid === uuid)
          );

          if (isAdmin) {
            if (selectedShop) {
              setFormData({
                name: selectedShop.name,
                street: selectedShop.street,
                city: selectedShop.city,
                postal_code: selectedShop.postal_code,
                ico: selectedShop.ico,
                dic: selectedShop.dic,
                email_billing: selectedShop.email_billing,
                country_billing: selectedShop.country_billing,
              });
            }
          } else {
            if (selectedShop && selectedShop.status_create !== "end") {
              setFormData({
                name: selectedShop.name,
                street: selectedShop.street,
                city: selectedShop.city,
                postal_code: selectedShop.postal_code,
                ico: selectedShop.ico,
                dic: selectedShop.dic,
                email_billing: selectedShop.email_billing,
                country_billing: selectedShop.country_billing,
              });
            }
          }
        }
      }
    };

    getCompanyInfo();
  }, [uuid, lng, navigate, t, selectedShop]);

  // const getCompanyInfoFromIco = async () => {
  //     if (formData.ico.length === 8) {
  //         setMessage(t("companyDetailsForm.loadingMessages.searchingForCompanyLoadMsg"))
  //         try {
  //             setIsUserDataLoading(true)
  //             const getCompanyInfoFromIcoResponse = await axiosInstance.post(`validate-ico/`, {
  //                 ico: formData.ico,
  //                 country: formData.country_billing
  //             });

  //             if (getCompanyInfoFromIcoResponse.status === 200) {
  //                 setFormData(prevState => ({
  //                     ...prevState,
  //                     name: getCompanyInfoFromIcoResponse.data.company_name || "",
  //                     street: getCompanyInfoFromIcoResponse.data.street || "",
  //                     city: getCompanyInfoFromIcoResponse.data.city || "",
  //                     postal_code: parseInt(getCompanyInfoFromIcoResponse.data.postal_code) || "",
  //                 }));

  //                 setTimeout(() => {
  //                     setIsUserDataLoading(false)
  //                     showAlert(t("companyDetailsForm.infoMessages.icoFoundInfoMsg"), "info");
  //                 }, 2000);

  //             } else {
  //                 setTimeout(() => {
  //                     setIsUserDataLoading(false)
  //                     showAlert(t("companyDetailsForm.errorMessages.icoNotFoundErrorMsg"), "danger");
  //                 }, 2000);
  //             }
  //         } catch (error) {
  //             console.log(error);
  //             sendErrorMessage(userData, selectedShop, error)
  //             setTimeout(() => {
  //                 setIsUserDataLoading(false)
  //                 showAlert(t("companyDetailsForm.errorMessages.icoNotFoundErrorMsg"), "danger");
  //             }, 2000);
  //         }
  //     } else {
  //         showAlert(t(`companyDetailsForm.requiredId.${formData.country_billing}`), "danger")
  //     }
  // };

  const handleInputChange = async (e) => {
    const { id, value } = e.target;

    // Speciální logika pro postal_code
    if (id === "postal_code") {
      const numericValue = value.replace(/[^0-9]/g, ""); // Odstraní vše kromě číslic
      const truncatedValue = numericValue.slice(0, 10); // Ořízne hodnotu na maximálně 10 znaků
      setFormData((prevState) => ({ ...prevState, [id]: truncatedValue }));
      setErrors((prevState) => ({ ...prevState, [id]: truncatedValue === "" }));
      return;
    }

    if (id === "ico") {
      const sanitizedValue = value.replace(/\s+/g, ""); // Odstraní mezery
      if (
        sanitizedValue.length === 8 &&
        (formData.country_billing === "cs" || formData.country_billing === "sk")
      ) {
        const response = await validateIco(
          sanitizedValue,
          formData.country_billing
        );
        if (response.status === 200) {
          setShowIcoTooltip(true);
          setCompanyInfoFromIco(response.data);
        } else {
          showAlert(
            t("companyDetailsForm.errorMessages.icoNotFoundErrorMsg"),
            "danger"
          );
        }
      } else {
        setShowIcoTooltip(false);
      }
      setFormData((prevState) => ({ ...prevState, [id]: sanitizedValue }));
      setErrors((prevState) => ({
        ...prevState,
        [id]: sanitizedValue.trim() === "",
      }));
      return;
    }

    // Ostatní pole
    setFormData((prevState) => ({ ...prevState, [id]: value }));
    setErrors((prevState) => ({ ...prevState, [id]: value.trim() === "" }));
  };

  const toggleCountry = () => {
    setIsCountrySelectOpen(!isCountrySelectOpen);
  };

  const handleCountryChange = (lang) => {
    // Resetujeme formulář při změně země
    setFormData({
      name: "",
      street: "",
      city: "",
      postal_code: "",
      ico: "",
      dic: "",
      email_billing: "",
      country_billing: lang,
    });
    setIsCountrySelectOpen(false);
  };

  const validateFields = () => {
    const newErrors = {};
    const namePattern = /^[a-zA-Zá-žÁ-Ž\s]+$/;
    const zipPattern = /^[0-9]+$/;

    if (!formData.name || formData.name.trim() === "") {
      newErrors.name = true;
    }
    if (!formData.street || formData.street.trim() === "") {
      newErrors.street = true;
    }
    if (!formData.city || formData.city.trim() === "") {
      newErrors.city = true;
    }
    if (!formData.postal_code || !zipPattern.test(formData.postal_code)) {
      newErrors.postal_code = true;
    }
    if (
      !formData.ico ||
      !countryIcoFormats[formData.country_billing].test(formData.ico)
    ) {
      newErrors.ico = true;
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      showAlert(
        t("companyDetailsForm.errorMessages.fillAllFieldsErrorMsg"),
        "danger"
      );
    } else {
      const submittedData = {
        ...formData,
        status_create: "shopdata",
      };

      try {
        const isSlowConnection = () => {
          if ("connection" in navigator) {
            const connection =
              navigator.connection ||
              navigator.mozConnection ||
              navigator.webkitConnection;
            const slowTypes = ["2g", "3g", "slow-2g"];
            if (
              connection.effectiveType &&
              slowTypes.includes(connection.effectiveType)
            ) {
              return true;
            }
          }
          // Pokud `navigator.connection` není dostupný, předpokládáme rychlé připojení
          return false;
        };

        if (isSlowConnection()) {
          setIsUserDataLoading(true);
        }
        await axiosInstance.patch(`shop/update/${uuid}/`, submittedData);
        navigate(`/${lng}/app/upload-products/`);
        window.scrollTo(0, 0);
      } catch (error) {
        setIsUserDataLoading(false);
        showAlert(
          t("companyDetailsForm.errorMessages.updateFailedErrorMsg"),
          "danger"
        );
      }
    }
  };

  const localizedSteps = steps.map((step) => ({
    ...step,
    label: t(`${step.label}`),
    alertMessage: t(step.alertMessage),
  }));

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Informace o vašem obchodě - PermoniQ</title>
      </Helmet>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}

      <ProgressTracker steps={localizedSteps} />

      {isUserDataLoading && <ModalLoading message={message} />}
      <ModalContactUs setMessage={setMessage} />

      <section className="section register">
        <article className="container">
          <h1 className="title">{t("companyDetailsTitle")}</h1>
          <form
            action="#"
            method="post"
            autoComplete="off"
            className="form"
            onSubmit={handleSubmit}
          >
            <div className="box">
              {/* <div className="box" style={{ margin: "0 0 10px 0", padding: 20, background: "#F0F0F0" }}>
                                <h4>{t("companyDetailsForm.countryForIcoInfo")}</h4>
                            </div> */}
              <div className="form__column">
                <div className="form__p">
                  <label htmlFor="country">
                    {t("companyDetailsForm.country")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                  </label>
                  <div
                    className={`country ${
                      isCountrySelectOpen ? "is-open" : ""
                    }`}
                    onClick={toggleCountry}
                  >
                    <div
                      className="country__selected"
                      data-lang={formData.country_billing}
                    >
                      {t(
                        `companyDetailsForm.countries.${formData.country_billing}`
                      )}
                    </div>
                    <ul className="country__switch">
                      {["cs", "sk", "hu", "pl", "ro"].map((lang) => (
                        <li
                          data-lang={lang}
                          key={lang}
                          onClick={() => handleCountryChange(lang)}
                        >
                          {t(`companyDetailsForm.countries.${lang}`)}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <select
                    name="country"
                    id="country"
                    className="country__select"
                    value={formData.country_billing}
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        country_billing: e.target.value,
                      }))
                    }
                  >
                    {["cs", "sk", "hu", "pl", "ro"].map((lang) => (
                      <option value={lang} key={lang}>
                        {t(`companyDetailsForm.countries.${lang}`)}
                      </option>
                    ))}
                  </select>
                </div>
                <p style={{ position: "relative" }}>
                  <label htmlFor="ico">
                    {t("companyDetailsForm.id")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                  </label>
                  <span
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <input
                      type="text"
                      id="ico"
                      value={formData.ico}
                      onChange={handleInputChange}
                      onFocus={() => setIsPulsing(true)}
                      onBlur={() => setIsPulsing(false)}
                      style={{
                        paddingRight:
                          formData.country_billing === "cs" ||
                          formData.country_billing === "sk"
                            ? "30px"
                            : "10px",
                        width: "100%",
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Escape") {
                          setShowIcoTooltip(false);
                        }
                      }}
                    />
                    {showIcoTooltip && (
                      <span>
                        <span
                          className={`${CopmanyDetailsStyles.ico_company_tooltip}`}
                          onClick={() => {
                            setFormData((prevState) => ({
                              ...prevState,
                              name: companyInfoFromIco?.company_name || "",
                              street: companyInfoFromIco?.street || "",
                              city: companyInfoFromIco?.city || "",
                              postal_code:
                                companyInfoFromIco?.postal_code || "",
                            }));

                            setShowIcoTooltip(false);
                          }}
                        >
                          {companyInfoFromIco.company_name !== undefined
                            ? companyInfoFromIco?.company_name +
                              " " +
                              companyInfoFromIco?.ico
                            : "Nenašel jsem data pro toto IČO"}
                        </span>
                        <span
                          className={`${CopmanyDetailsStyles.ico_tooltip_close_btn} "sibling"`}
                          onClick={() => setShowIcoTooltip(false)}
                        >
                          &times;
                        </span>
                      </span>
                    )}
                    {/* {(formData.country_billing === 'cs' || formData.country_billing === 'sk') && (
                                            <TbWorldSearch
                                                className={`${CopmanyDetailsStyles.find_ico_btn} ${isPulsing ? CopmanyDetailsStyles.pulsing : ''}`}
                                                onClick={() => getCompanyInfoFromIco()}
                                                title={t('companyDetailsForm.searchIco')}
                                            />
                                        )} */}
                  </span>
                  {errors.ico && (
                    <span className="error">
                      {t(
                        `companyDetailsForm.requiredId.${formData.country_billing}`
                      )}
                    </span>
                  )}
                </p>
              </div>
              <div className="form__column">
                <p>
                  <label htmlFor="name">
                    {t("companyDetailsForm.company")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && (
                    <span className="error">
                      {t("companyDetailsForm.required")}
                    </span>
                  )}
                </p>
                <p>
                  <label htmlFor="street">
                    {t("companyDetailsForm.street")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                  </label>
                  <input
                    type="text"
                    id="street"
                    value={formData.street}
                    onChange={handleInputChange}
                  />
                  {errors.street && (
                    <span className="error">
                      {t("companyDetailsForm.required")}
                    </span>
                  )}
                </p>
              </div>
              <div className="form__column">
                <p>
                  <label htmlFor="city">
                    {t("companyDetailsForm.city")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                  </label>
                  <input
                    type="text"
                    id="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                  {errors.city && (
                    <span className="error">
                      {t("companyDetailsForm.requiredLetters")}
                    </span>
                  )}
                </p>
                <p>
                  <label htmlFor="postal_code">
                    {t("companyDetailsForm.zip")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}>*</sub>
                  </label>
                  <input
                    type="text"
                    id="postal_code"
                    value={formData.postal_code}
                    onChange={handleInputChange}
                  />
                  {errors.postal_code && (
                    <span className="error">
                      {t("companyDetailsForm.requiredNumbers")}
                    </span>
                  )}
                </p>
              </div>
              <div className="form__column">
                <p>
                  <label htmlFor="dic">
                    {t("companyDetailsForm.taxid")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}></sub>
                  </label>
                  <input
                    type="text"
                    id="dic"
                    value={formData.dic}
                    onChange={handleInputChange}
                  />
                </p>
                <p>
                  <label htmlFor="email_billing">
                    {t("companyDetailsForm.email")}
                    <sub style={{ color: "red", fontSize: "1.5rem" }}></sub>
                  </label>
                  <input
                    type="text"
                    id="email_billing"
                    value={formData.email_billing}
                    onChange={handleInputChange}
                  />
                </p>
              </div>
              <sub style={{ color: "red", fontSize: "1.5rem" }}>
                *
                <em style={{ color: "black", fontSize: "1rem" }}>
                  {t("requireItem")}
                </em>
              </sub>
            </div>
            <div className="register__submit">
              <p>
                <button className="btn" type="submit">
                  {t("companyDetailsForm.submit")}
                </button>
              </p>
            </div>
          </form>
        </article>
      </section>
      {userData.email === "info@permoniq.com" && showAdminModal && (
        <Modal
          title="Informace o obchodu"
          cancelText="Zavřít"
          confirmText=""
          onClose={() => setShowAdminModal(false)}
        >
          {infoItems.map((item, index) => (
            <InfoBoxItem key={index} label={item.label} value={item.value} />
          ))}
        </Modal>
      )}
    </>
  );
};

export default CompanyDetails;
