import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef
} from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import deleteAllDatabases from "../utils/functions/deleteAllDatabases";
import { clearUserDetailStorage } from "../api/AxiosConfig";

export const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

const AppProvider = ({ children }) => {
  const { t } = useTranslation();

  const alertTimeoutRef = useRef(null);

  const [isUserDataLoading, setIsUserDataLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [uuid, setUuid] = useState("");
  const [pk, setPk] = useState("");
  const [appContentType, setAppContentType] = useState("dashboard");
  const [activeItem, setActiveItem] = useState("dashboard");
  const [createCampaignObject, setcreateCampaignObject] = useState({});
  const [campaignAssetsObject, setCampaignAssetsObject] = useState({});
  const [selectedShop, setSelectedShop] = useState("");
  const [isPmaxActive, setIsPmaxActive] = useState(true);
  const [isCampaignDeactivated, setIsCampaignDeactivated] = useState(false);
  const [isCampaignCreated, setIsCampaignCreated] = useState(false);
  const [hasItems, setHasItems] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedLogos, setLogos] = useState([]);
  const [headline, setHeadline] = useState(["", "", ""]);
  const [longHeadline, setLongHeadline] = useState(["", ""]);
  const [shortDescPart1, setShortDescPart1] = useState("");
  const [shortDescPart2, setShortDescPart2] = useState("");
  const [descs, setDescs] = useState(["", ""]);
  const [pno, setPno] = useState(20);
  const [shopName, setShopName] = useState("");
  const [email, setEmail] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [budget, setBudget] = useState(500);
  const [campaignBudget, setCampaignBudget] = useState(0);
  const [visitedInstallTrackingCode, setVisitedInstallTrackingCode] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState({ text: "", type: "base" });
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPNOWarning, setShowPNOWarning] = useState(false);
  const [statsCurrency, setStatsCurrency] = useState("Kč");
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [currencyFromAds, setCurrencyFromAds] = useState(null);
  const [isBilling, setIsBilling] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [navbarType, setNavbarType] = useState("main"); //options [main, app, login, 404]
  const [showModalAfterRegistration, setShowModalAfterRegistration] =
    useState(false);
  const [shopStatus, setShopStatus] = useState();
  const [existingCampaigns, setExistingCampaigns] = useState([]);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showShopModal, setShowShopModal] = useState(false);

  const steps = [
    {
      path: "/app/register-shop/",
      label: "registerProgress.addShop",
      alertMessage: "registerForm.contactSupport"
    },
    {
      path: "/app/company-details/",
      label: "registerProgress.companyDetails",
      alertMessage: "registerForm.contactSupport"
    },
    {
      path: "/app/upload-products/",
      label: "registerProgress.uploadProducts",
      alertMessage: "registerForm.contactSupport"
    },
    {
      path: "/app/connect-google/",
      label: "registerProgress.connectGoogle",
      alertMessage: "registerForm.contactSupport"
    },
    {
      path: "/app/install-tracking-code/",
      label: "registerProgress.installCode",
      alertMessage: "registerForm.contactSupport"
    }
  ];

  const [code, setCode] = useState(`<!-- DataPlus -->

<script type="text/javascript" src="https://app.permoniq.com"></script>

<!-- End DataPlus -->`);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);

    if (window.location.hash === "#consultation") {
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  };

  const deleteAll = () => {
    setSelectedImages([]);
    setLogos([]);
    setHeadline([]);
    setLongHeadline(["", ""]);
    setShortDescPart1("");
    setShortDescPart2("");
    setDescs(["", ""]);
  };

  // Funkce pro zobrazení alertu
  const showAlert = (text, type = "base", duration = 7000) => {
    // Zrušení předchozího časovače, pokud existuje
    if (alertTimeoutRef.current) {
      clearTimeout(alertTimeoutRef.current);
    }

    setAlertMessage({ text, type });
    setIsAlertVisible(true);

    // Nastavení nového časovače pro automatické zavření alertu
    alertTimeoutRef.current = setTimeout(() => {
      setIsAlertVisible(false);
    }, duration);
  };

  // Funkce pro zavření alertu
  const closeAlert = () => {
    setIsAlertVisible(false);
    if (alertTimeoutRef.current) {
      clearTimeout(alertTimeoutRef.current);
    }
  };

  // Funkce pro obnovení přístupového tokenu
  const refreshAccessToken = async (refreshToken) => {
    try {
      //  deleteAllDatabases()
      clearUserDetailStorage();
      const response = await fetch("https://permoniq.com/api/token/refresh/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ refresh: refreshToken })
      });

      if (!response.ok) {
        throw new Error("Failed to refresh access token");
      }

      const data = await response.json();
      Cookies.set("aTkn_0yD9K", data.access, {
        secure: true,
        sameSite: "None",
        expires: 1
      });
      return data.access;
    } catch (error) {
      console.error("Token refresh failed:", error);
      return null;
    }
  };

  // Funkce pro kontrolu přihlášení
  const checkLoginStatus = async () => {
    const accessToken = Cookies.get("aTkn_0yD9K");
    const refreshToken = Cookies.get("rTkn_4zF7P");

    if (accessToken && refreshToken) {
      setIsLogged(true);
      return true;
    }

    if (refreshToken) {
      const newAccessToken = await refreshAccessToken(refreshToken);
      if (newAccessToken) {
        setIsLogged(true);
        return true;
      }
    }

    setIsLogged(false);
    return false;
  };

  // upravuje číslovku, aby měla dvě desetinná místa vytvoří mezery mezi tisícem
  const toLocale = (number) => {
    return Number(Number(number).toFixed(2)).toLocaleString("cs-CZ");
  };

  const getCurrency = (dataCurrency) => {
    switch (dataCurrency) {
      case "CZK":
        return "Kč";
      case "EUR": // pro Slovensko a Maďarsko
        return "€";
      case "PLN":
        return "zł";
      case "RON":
        return "lei";
      case "HUF":
        return "Ft";
      case "USD": // pro anglicky mluvící země, kde je používán dolar
        return "$";
      default:
        return dataCurrency; // vrací originální kód měny, pokud není rozpoznán
    }
  };

  const extractMessage = (errorString) => {
    const messageRegex = /'message': '([^']+)'/; // Regulární výraz pro extrakci message
    const match = errorString.match(messageRegex);

    if (match) {
      return match[1]; // Vrátí extrahovanou message
    } else {
      return null; // Vrátí null, pokud message nebyla nalezena
    }
  };

  const getValue = (val) => (val ? val : "není nastaveno");

  const infoItems = selectedShop
    ? [
        { label: "UUID", value: getValue(selectedShop.uuid) },
        { label: "Název", value: getValue(selectedShop.name) },
        { label: "Web", value: getValue(selectedShop.website) },
        { label: "IČO", value: getValue(selectedShop.ico) },
        { label: "Jméno", value: getValue(selectedShop.first_name) },
        { label: "Příjmení", value: getValue(selectedShop.last_name) },
        { label: "E-mail", value: getValue(selectedShop.email) },
        {
          label: "Fakturační e-mail",
          value: getValue(selectedShop.email_billing)
        },
        { label: "Telefon", value: getValue(selectedShop.phone) },
        { label: "Město", value: getValue(selectedShop.city) },
        { label: "Ulice", value: getValue(selectedShop.street) },
        { label: "Země", value: getValue(selectedShop.country) },
        { label: "PSČ", value: getValue(selectedShop.postal_code) },
        {
          label: "Ads ID",
          value: getValue(selectedShop.google_ads?.client_id)
        },
        {
          label: "Ads campaign ID",
          value: getValue(selectedShop.campaign[0]?.campaign_id)
        },
        {
          label: "Merchant ID",
          value: getValue(selectedShop.google_merchant?.client_id)
        },
        {
          label: "GA4 property ID",
          value: selectedShop.google_analytics?.client_id
            ? getValue(selectedShop.google_analytics.client_id.split("/")[1])
            : "není nastaveno"
        },
        {
          label: "Stav obchodu",
          value: (() => {
            if (selectedShop.status_create === "step1") {
              return "1. krok registrace";
            } else if (selectedShop.status_create === "billinfo") {
              return "2. krok registrace";
            } else if (selectedShop.status_create === "shopdata") {
              return "3. krok registrace";
            } else if (selectedShop.status_create === "account") {
              return "4. krok registrace";
            } else if (selectedShop.status_create === "pixel") {
              return "5. krok registrace";
            } else if (selectedShop.status_create === "end") {
              return "dokončen";
            } else {
              return "není nastaveno";
            }
          })()
        },
        { label: "Start", value: getValue(selectedShop.start_date) },
        {
          label: "Začátek měření",
          value: getValue(selectedShop.first_stat_date)
        },
        { label: "Datum ukončení", value: getValue(selectedShop.end_date) }
      ]
    : [];

  return (
    <AppContext.Provider
      value={{
        userData,
        setUserData,
        isUserDataLoading,
        setIsUserDataLoading,
        uuid,
        setUuid,
        pk,
        setPk,
        createCampaignObject,
        setcreateCampaignObject,
        campaignAssetsObject,
        setCampaignAssetsObject,
        selectedShop,
        setSelectedShop,
        isPmaxActive,
        setIsPmaxActive,
        isCampaignCreated,
        setIsCampaignCreated,
        hasItems,
        setHasItems,
        isCampaignDeactivated,
        setIsCampaignDeactivated,
        selectedImages,
        setSelectedImages,
        selectedLogos,
        setLogos,
        headline,
        setHeadline,
        longHeadline,
        setLongHeadline,
        shortDescPart1,
        setShortDescPart1,
        shortDescPart2,
        setShortDescPart2,
        descs,
        setDescs,
        deleteAll,
        appContentType,
        setAppContentType,
        code,
        setCode,
        pno,
        setPno,
        shopName,
        setShopName,
        email,
        setEmail,
        merchantId,
        setMerchantId,
        campaignId,
        setCampaignId,
        budget,
        setBudget,
        campaignBudget,
        setCampaignBudget,
        visitedInstallTrackingCode,
        setVisitedInstallTrackingCode,
        isModalOpen,
        openModal,
        closeModal,
        alertMessage,
        isAlertVisible,
        setIsAlertVisible,
        showAlert,
        closeAlert,
        isLogged,
        setIsLogged,
        checkLoginStatus,
        activeItem,
        setActiveItem,
        isAdmin,
        setIsAdmin,
        steps,
        showPNOWarning,
        setShowPNOWarning,
        toLocale,
        statsCurrency,
        setStatsCurrency,
        getCurrency,
        openDescriptionModal,
        setOpenDescriptionModal,
        showArrow,
        setShowArrow,
        extractMessage,
        currencyFromAds,
        setCurrencyFromAds,
        isBilling,
        setIsBilling,
        showWelcomeModal,
        setShowWelcomeModal,
        navbarType,
        setNavbarType,
        showModalAfterRegistration,
        setShowModalAfterRegistration,
        shopStatus,
        setShopStatus,
        existingCampaigns,
        setExistingCampaigns,
        showAdminModal,
        setShowAdminModal,
        showShopModal,
        setShowShopModal,
        infoItems
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
