import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Dot,
} from "recharts";
import styles from "../css/ProductsGraph.module.css";
import { formatDate } from "../utils/functions/formatDate";
import { useParams } from "react-router-dom";
import CustomActiveDot from "./CustomActiveDot";
import formatNumber from "../utils/functions/formatNumber";

const ProductsGraph = ({ data, onDayClick }) => {
  const { lng } = useParams();
  const [graphData, setGraphData] = useState([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  // Flag zajistí, že výchozí výběr proběhne jen jednou na konkrétní data
  const [initialSelectionDone, setInitialSelectionDone] = useState(false);

  // Při změně dat resetujeme flag, aby se provedl nový výběr
  useEffect(() => {
    setInitialSelectionDone(false);
  }, [data]);

  useEffect(() => {
    let newData = [];
    if (data && typeof data === "object" && Object.keys(data).length > 0) {
      if (Array.isArray(data)) {
        newData = [...data];
      } else {
        try {
          newData = Array.from(data);
        } catch (error) {
          console.error("Failed to convert data to array:", error);
          newData = [];
        }
      }
    } else {
      newData = [];
    }

    setGraphData(newData);

    // Výchozí výběr: vybereme poslední bod, pokud ještě nebyl proveden
    if (newData.length > 0 && !initialSelectionDone) {
      const lastDataPoint = newData[newData.length - 1];
      setSelectedDataPoint(lastDataPoint);
      setInitialSelectionDone(true);
      if (onDayClick) {
        onDayClick(lastDataPoint);
      }
    } else if (newData.length === 0) {
      setSelectedDataPoint(null);
      setInitialSelectionDone(false);
      if (onDayClick) {
        onDayClick(null);
      }
    }
  }, [data, onDayClick, initialSelectionDone]);

  // Handler pro kliknutí v grafu
  const handleClick = (e) => {
    if (e && e.activePayload && e.activePayload.length > 0) {
      const clickedPayload = e.activePayload[0].payload;
      if (selectedDataPoint && selectedDataPoint.date === clickedPayload.date) {
        // Klik na stejný bod – zrušíme výběr
        setSelectedDataPoint(null);
        if (onDayClick) onDayClick(null);
      } else {
        // Klik na nový bod – vybereme
        setSelectedDataPoint(clickedPayload);
        if (onDayClick) onDayClick(clickedPayload);
      }
    }
  };

  // Výpočet maximální hodnoty na Y-ose
  const calculateDomainMax = (dataMax) => {
    if (typeof dataMax !== "number" || dataMax === 0) return 10;
    return Math.ceil(dataMax * 1.2);
  };

  // Vlastní vykreslení bodů
  const renderCustomDot = (props) => {
    const { cx, cy, stroke, payload, index } = props;
    const isZeroDataPoint =
      payload.approved_count === 0 &&
      payload.suspended_count === 0 &&
      payload.pending_count === 0 &&
      payload.rejected_count === 0;

    if (isZeroDataPoint && graphData.length > 0 && graphData[0].approved_count === 0) {
      return null;
    }

    if (selectedDataPoint && payload.date === selectedDataPoint.date) {
      return (
        <Dot
          key={`dot-selected-${index}`}
          cx={cx}
          cy={cy}
          r={8}
          fill="white"
          stroke={stroke}
          strokeWidth={3}
        />
      );
    }
    return (
      <Dot
        key={`dot-${index}`}
        cx={cx}
        cy={cy}
        r={4}
        fill="white"
        stroke={stroke}
        strokeWidth={3}
      />
    );
  };

  return (
    <div className={styles.container}>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={graphData}
          onClick={handleClick}
          margin={{ top: 0, right: 50, bottom: 0, left: 0 }}
          style={{ cursor: "pointer" }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => formatDate(lng, date)}
            interval={"preserveEnd"}
            tickMargin={20}
            tickSize={15}
            angle={-45}
            height={100}
          />
          <YAxis
            domain={["auto", calculateDomainMax]}
            tickFormatter={(value) => formatNumber(value, undefined, 0)}
          />
          <Tooltip
            offset={100}
            cursor={{ strokeWidth: 3, strokeDasharray: 10 }}
            labelFormatter={(label) => formatDate(lng, label)}
            formatter={(value, name) => [formatNumber(value, undefined, 0), name]}
          />
          <Line
          animationDuration={5000}
            strokeWidth={4}
            type="monotone"
            name="Aktivní"
            dataKey="approved_count"
            stroke="#22E06C"
            legendType="circle"
            dot={renderCustomDot}
            activeDot={<CustomActiveDot />}
          />
          <Line
          animationDuration={5000}
            strokeWidth={4}
            type="monotone"
            name="Končící platnost"
            dataKey="suspended_count"
            stroke="#ED7200"
            legendType="circle"
            dot={renderCustomDot}
            activeDot={<CustomActiveDot />}
          />
          <Line
          animationDuration={5000}
            strokeWidth={4}
            type="monotone"
            name="Čekající na vyřízení"
            dataKey="pending_count"
            stroke="#0000FF"
            legendType="circle"
            dot={renderCustomDot}
            activeDot={<CustomActiveDot />}
          />
          <Line
          animationDuration={5000}
            strokeWidth={4}
            type="monotone"
            name="Zamítnuto"
            dataKey="rejected_count"
            stroke="#FF0000"
            legendType="circle"
            dot={renderCustomDot}
            activeDot={<CustomActiveDot />}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductsGraph;
