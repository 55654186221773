import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppContext } from "../context/AppContext";

import { cleanDomain } from "../utils/functions/cleanDomain";
import { sendErrorMessage } from "../utils/axios_functions/sendErrorMessage";

import SimpleAlert from "../components/SimpleAlert";
import ModalLoading from "../components/ModalLoading";
import ProgressTracker from "../components/ProgressTracker";

import useUserRegisterDetails from "../hooks/useUserRegisterDetails";

import styles from "../css/ConnectGoogle.module.css";

import axiosInstance from "../api/AxiosConfig";

import googleLogo from "../img/login-google.svg";
import adsLogo from "../img/logos/GA_2.svg";
import merchantLogo from "../img/logos/GM_2.svg";
import plusDarkIcon from "../img/icons/plusDark.svg";
import minusIcon from "../img/icons/minus.svg";

import BudgetPnoDialog from "../components/BudgetPnoDialog";

import ModalContactUs from "../components/ModalContactUs";
import Modal from "../components/Modal";
import InfoBoxItem from "../components/InfoBoxItem";

import sendEvent from "../utils/functions/sendEvent";

const ConnectGoogle = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const BudgetPnoDialogRef = useRef(null);

  const [loadingState, setLoadingState] = useState(true);
  const [shopInAccount, setShopInAccount] = useState({});
  const [haveAccounts, setHaveAccounts] = useState(true);
  const [haveAccess, setHaveAccess] = useState(false);
  const [chooseAccounts, setChooseAccounts] = useState(false);
  const [showConnectAccountsBbtn, setShowConnectAccountsBtn] = useState(false);
  const [adsData, setAdsData] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isGoogleAccountIdValid, setIsGoogleAccountIdValid] = useState(true);
  const [mccAdsID, setMccAdsID] = useState("");
  const [mccMerchantID, setMccMerchantId] = useState("");
  const [googleAccountId, setGoogleAccountId] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [merchantClientName, setMerchantClientName] = useState();
  const [agreement, setAgreement] = useState(false);
  const [createMerchantAgree, setCreateMerchantAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onlyAds, setOnlyAds] = useState(false);
  const [showOnlyAds, setShowOnlyAds] = useState(false);
  const [onlyMerchantCreated, setOnlyMerchantCreated] = useState(false);
  const [message, setMessage] = useState("Pracuju na tom");

  const {
    uuid,
    userData,
    isAdmin,
    isUserDataLoading,
    setIsUserDataLoading,
    isAlertVisible,
    showAlert,
    closeAlert,
    alertMessage,
    steps,
    selectedShop,
    extractMessage,
    setCurrencyFromAds,
    showAdminModal,
    setShowAdminModal,
    infoItems,
  } = useContext(AppContext);

  const [emailsForShare, setEmailsForShare] = useState([userData.email] || "");

  useUserRegisterDetails();

  useEffect(() => {
    sendEvent(`rg_${location.pathname.split("/")[3]}`);
  }, []);

  useEffect(() => {
    if (
      Object.keys(userData).length > 0 &&
      Object.keys(userData.shops).length > 0
    ) {
      setShopInAccount(
        userData.shops.find((oneShop) => oneShop.status_create === "account")
      );
    }
  }, [userData]);

  useEffect(() => {
    getAccounts(true);
  }, []);

  useEffect(() => {
    if (onlyMerchantCreated && merchantId) {
      handleSubmit();
    }
  }, [onlyMerchantCreated]);

  const openBudgetDialog = (e) => {
    e.preventDefault();

    if (!haveAccounts && agreement) {
      // Filtrace pouze definovaných a validních řetězců
      const filteredEmails = emailsForShare.filter(
        (email) => typeof email === "string" && email.trim() !== ""
      );

      // Pokud po filtrování nezbývá žádný e-mail, doplníme userData.email
      if (filteredEmails.length === 0) {
        setEmailsForShare([userData.email]);
        filteredEmails.push(userData.email); // přidáme do lokální proměnné pro validaci
      } else if (filteredEmails.length !== emailsForShare.length) {
        // Aktualizujeme stav, pokud byly odstraněny nějaké prázdné či undefined hodnoty
        setEmailsForShare(filteredEmails);
      }

      // Validace zbývajících e-mailů
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      for (let email of filteredEmails) {
        if (!emailRegex.test(email)) {
          showAlert("Zadejte validní e-mail.", "danger");
          return;
        }
      }
    }

    if (BudgetPnoDialogRef.current) {
      BudgetPnoDialogRef.current.showModal();
      document.body.style.overflow = "hidden";
      BudgetPnoDialogRef.current.addEventListener(
        "close",
        () => {
          document.body.style.overflow = "auto";
        },
        { once: true }
      );
    }
  };

  const onPnoAndBudgetSet = async () => {
    if (createMerchantAgree) {
      await handleCreateMerchant();
    } else {
      await handleSubmit();
    }
  };

  const getAccounts = async (status = "") => {
    let adsPassed = false;
    let merchantPassed = false;

    try {
      setMessage(
        t("connectGoogleForm.loadingMessages.lookingForAccountsLoadMsg")
      );
      setIsUserDataLoading(true);

      const getAds = async () => {
        try {
          const getAdsResponse = await axiosInstance.get(
            `googleads/account/user/list/${status && "?status=check"}`
          );

          if (getAdsResponse.status === 200 || getAdsResponse.status === 201) {
            setOnlyAds(true);
            adsPassed = true;
          }

          if (!status) {
            setAdsData(getAdsResponse.data);

            setCurrencyFromAds(getAdsResponse.data[0].client_currency);
            sessionStorage.setItem(
              "cfa_1",
              getAdsResponse.data[0].client_currency
            );

            if (getAdsResponse.data[0].is_manager === false) {
              setGoogleAccountId(getAdsResponse.data[0].client_id);
              setMccAdsID("");
            } else if (getAdsResponse.data[0].is_manager === true) {
              setGoogleAccountId(getAdsResponse.data[0].clients[0].client_id);
              setMccAdsID(getAdsResponse.data[0].client_id);
            }
          }
        } catch (error) {
          if (
            error.response &&
            error.response.status === 404 &&
            document.referrer.includes("auth")
          ) {
            document.referrer = "";
          } else {
            console.error("Error in getAds:", error);
          }
          setHaveAccounts(false);
          setChooseAccounts(false);
          setShowConnectAccountsBtn(true);
        }
      };

      const getMerchant = async () => {
        const getMerchantResponse = await axiosInstance.get(
          `googlemerchant/account/user/list/${status && "?status=check"}`
        );

        if (
          getMerchantResponse.status === 200 ||
          getMerchantResponse.status === 201
        ) {
          merchantPassed = true; // Nastavíme příznak, že request prošel
        }

        if (!status) {
          setMerchantData(getMerchantResponse.data);
          if (getMerchantResponse.data[0].is_manager === false) {
            setMerchantId(getMerchantResponse.data[0].client_id);
            setMerchantClientName(getMerchantResponse.data[0].client_name);
            setMccMerchantId("");
          } else if (
            getMerchantResponse.data[0].is_manager === true &&
            getMerchantResponse.data[0].clients.length > 0
          ) {
            setMerchantId(getMerchantResponse.data[0].clients[0].client_id);
            setMerchantClientName(
              getMerchantResponse.data[0].clients[0].client_name
            );
            setMccMerchantId(getMerchantResponse.data[0].client_id);
          }
        }
      };

      // Zavolej obě asynchronní funkce a počkej na jejich dokončení
      await Promise.all([getAds(), getMerchant()]);

      if (status) {
        setHaveAccess(true);
        setShowConnectAccountsBtn(true);
      } else {
        setChooseAccounts(true);
        setShowConnectAccountsBtn(false);
      }
    } catch (error) {
      console.log("Error in getAccounts", error);
      if (document.referrer.includes("auth")) {
        setHaveAccounts(false);
        showAlert(
          t("connectGoogleForm.errorMessages.accountsNotFoundErrorMsg"),
          "danger"
        );
      }
    } finally {
      setIsUserDataLoading(false);
      // Nastavíme onlyAds na false, pokud oba requesty prošly
      if (adsPassed && merchantPassed) {
        setOnlyAds(false);
      } else if (!adsPassed && merchantPassed) {
        setHaveAccounts(false);
        showAlert(
          t("connectGoogleForm.errorMessages.accountsNotFoundErrorMsg"),
          "danger"
        );
      } else if (adsPassed && !merchantPassed) {
        getOnlyAds();
      }
    }
    setLoadingState(false);
  };

  const getOnlyAds = async () => {
    setMessage(
      t("connectGoogleForm.loadingMessages.lookingForAccountsLoadMsg")
    );
    setIsUserDataLoading(true);
    try {
      const getAdsResponse = await axiosInstance.get(
        `googleads/account/user/list/`
      );

      if (getAdsResponse.status === 200 || getAdsResponse.status === 201) {
        setOnlyAds(true);
      }

      setAdsData(getAdsResponse.data);
      setCurrencyFromAds(getAdsResponse.data[0].client_currency);
      sessionStorage.setItem("cfa_1", getAdsResponse.data[0].client_currency);

      setShowOnlyAds(true);
      setOnlyAds(false);
      setShowConnectAccountsBtn(false);
      if (getAdsResponse.data[0].is_manager === false) {
        setGoogleAccountId(getAdsResponse.data[0].client_id);
        setMccAdsID("");
      } else if (getAdsResponse.data[0].is_manager === true) {
        setGoogleAccountId(getAdsResponse.data[0].clients[0].client_id);
        setMccAdsID(getAdsResponse.data[0].client_id);
      }
    } catch (error) {
      if (error.status === 404) {
        setHaveAccounts(false);
        showAlert(
          t("connectGoogleForm.errorMessages.accountsNotFoundErrorMsg"),
          "danger"
        );
      }
      console.log(error);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  const handleGoogleAccountIdChange = (e) => {
    const value = e.target.value;
    const googleAccountIdPattern = /^\d{3}-\d{3}-\d{4}$/;
    setIsGoogleAccountIdValid(googleAccountIdPattern.test(value));
    setGoogleAccountId(value);
  };

  const handleCreateMerchant = async (e) => {
    if (e && createMerchantAgree) {
      e.preventDefault(); // Zastavíme výchozí chování pouze, pokud je event a createMerchantAgree je false
    }
    setIsUserDataLoading(true);

    let merchantResponse;
    let feedResponse;

    try {
      // Vytvoření promise pro vytvoření Merchant účtu
      const createMerchantPromise = await axiosInstance.post(
        `googlemerchant/account/create/`,
        {
          name: cleanDomain(selectedShop.website),
          websiteUrl: selectedShop.website,
          adultContent: false,
          conversionSettings: { freeListingsAutoTaggingEnabled: true },
          address: {
            country:
              selectedShop.country === "cs"
                ? "CZ"
                : selectedShop.country.toUpperCase(),
            region:
              selectedShop.country === "cs"
                ? "CZ"
                : selectedShop.country.toUpperCase(),
            locality: selectedShop.city,
            postalCode: selectedShop.postal_code,
            streetAddress: selectedShop.street,
          },
          customerService: { email: userData.email },
          phoneNumber: selectedShop.phone,
        }
      );

      // Čekání na výsledek vytvoření Merchant účtu
      merchantResponse = createMerchantPromise;
      setMerchantId(merchantResponse.data.customer_id);
    } catch (error) {
      console.error("Error creating Google Merchant account:", error);
      showAlert(
        t(
          "connectGoogleForm.errorMessages.merchantAccountCreationFailedErrorMsg"
        ),
        "danger"
      );
      return; // Ukončíme proces, pokud tento request selže
    }

    try {
      // Vytvoření promise pro odeslání feedu
      const createFeedPromise = axiosInstance.post(
        `googlemerchant/account/feed/`,
        {
          account_id: merchantResponse.data.customer_id,
          feed_url: selectedShop.feed,
          feed_name: `${selectedShop.name} ${new Date().getTime()}`,
        }
      );

      // Čekání na výsledek odeslání feedu
      feedResponse = await createFeedPromise;
    } catch (error) {
      console.error("Error sending feed to Google Merchant account:", error);
      showAlert(
        t("connectGoogleForm.errorMessages.feedUploadFailedErrorMsg"),
        "danger"
      );
      return; // Ukončíme proces, pokud tento request selže
    }

    // Pokud oba requesty proběhnou úspěšně, můžete provést nějakou akci zde
    if (merchantResponse && feedResponse) {
      setOnlyMerchantCreated(true);
      setHaveAccounts(true);
    }
  };

  const handleSubmit = async (e) => {
    if (e && !createMerchantAgree) {
      e.preventDefault(); // Zastavíme výchozí chování pouze, pokud je event a createMerchantAgree je false
    }

    // if (!contextEmail) return;

    setIsUserDataLoading(true);
    setLoading(true);

    if (haveAccounts && !googleAccountId) {
      showAlert(
        t("connectGoogleForm.errorMessages.fillAllFieldsErrorMsg"),
        "danger"
      );
      setIsUserDataLoading(false);
      setLoading(false);
      return;
    }
    if (haveAccounts && !isGoogleAccountIdValid) {
      showAlert(
        t("connectGoogleForm.errorMessages.invalidGoogleAccountIdErrorMsg"),
        "danger"
      );
      setIsUserDataLoading(false);
      setLoading(false);
      return;
    }
    if (!haveAccounts && !agreement) {
      showAlert(
        t("connectGoogleForm.errorMessages.fillAllFieldsErrorMsg"),
        "danger"
      );
      setIsUserDataLoading(false);
      setLoading(false);
      return;
    }

    try {
      if (haveAccounts) {
        try {
          if (!isAdmin && createMerchantAgree) {
            let inviteAdsPromise;

            if (mccAdsID) {
              inviteAdsPromise = axiosInstance.post("googleads/invite/mcc/", {
                customer_id: googleAccountId.replace(/-/g, ""),
                manager_id: mccAdsID.replace(/-/g, ""),
              });
            } else {
              inviteAdsPromise = axiosInstance.post("googleads/invite/mcc/", {
                customer_id: googleAccountId.replace(/-/g, ""),
              });
            }

            const [inviteAdsResponse] = await Promise.all([inviteAdsPromise]);
          } else if (!isAdmin && !createMerchantAgree) {
            let inviteAdsPromise;
            let inviteMerchantPromise;

            if (mccAdsID) {
              inviteAdsPromise = axiosInstance.post("googleads/invite/mcc/", {
                customer_id: googleAccountId.replace(/-/g, ""),
                manager_id: mccAdsID.replace(/-/g, ""),
              });
            } else {
              inviteAdsPromise = axiosInstance.post("googleads/invite/mcc/", {
                customer_id: googleAccountId.replace(/-/g, ""),
              });
            }

            if (mccMerchantID) {
              inviteMerchantPromise = axiosInstance.put(
                "googlemerchant/invite/mcc/",
                {
                  account_id: merchantId.replace(/-/g, ""),
                  websiteUrl: userData?.shops?.find(
                    (oneShop) => oneShop.uuid === uuid
                  ).website,
                  client_name: merchantClientName,
                  manager_id: mccMerchantID.replace(/-/g, ""),
                }
              );
            } else {
              inviteMerchantPromise = axiosInstance.put(
                "googlemerchant/invite/mcc/",
                {
                  account_id: merchantId.replace(/-/g, ""),
                  websiteUrl: userData?.shops?.find(
                    (oneShop) => oneShop.uuid === uuid
                  ).website,
                  client_name: merchantClientName,
                }
              );
            }

            // Spuštění obou requestů paralelně pomocí Promise.all
            const [inviteAdsResponse, inviteMerchantResponse] =
              await Promise.all([inviteAdsPromise, inviteMerchantPromise]);
          } else if (isAdmin) {
            // Pro případ, že jsou účty vytvořeny
            let accountCreateResponse;

            try {
              accountCreateResponse = await axiosInstance.post(
                "googleads/account/create/",
                {
                  customer_name: cleanDomain(selectedShop.website),
                  currency_code: selectedShop.currency,
                  create_account_id: googleAccountId,
                }
              );
            } catch (error) {
              console.error("Error creating Google Ads account:", error);
              showAlert(
                t(
                  "connectGoogleForm.errorMessages.adsAccountCreationFailedErrorMsg"
                ),
                "danger"
              );
              return; // Ukončíme proces, pokud tento request selže
            }

            let merchantResponse;
            try {
              merchantResponse = await axiosInstance.post(
                `googlemerchant/account/create/`,
                {
                  name: cleanDomain(selectedShop.website),
                  websiteUrl: selectedShop.website,
                  adultContent: false,
                  conversionSettings: { freeListingsAutoTaggingEnabled: true },
                  address: {
                    country:
                      selectedShop.country === "cs"
                        ? "CZ"
                        : selectedShop.country.toUpperCase(),
                    region:
                      selectedShop.country === "cs"
                        ? "CZ"
                        : selectedShop.country.toUpperCase(),
                    locality: selectedShop.city,
                    postalCode: selectedShop.postal_code,
                    streetAddress: selectedShop.street,
                  },
                  customerService: { email: userData.email },
                  phoneNumber: selectedShop.phone,
                  create_account_id: merchantId.replace(/-/g, ""),
                }
              );
            } catch (error) {
              console.error("Error creating Google Merchant account:", error);
              showAlert(
                t(
                  "connectGoogleForm.errorMessages.merchantAccountCreationFailed"
                ),
                "danger"
              );
              return; // Ukončíme proces, pokud tento request selže
            }
          }

          // vždycky
          // Posílá se propojení a z google merchant do google ads
          const linkData = {
            account_id: merchantId.replace(/-/g, ""),
            adsLinks: [
              {
                adsId: googleAccountId.replace(/-/g, ""),
                status: "active",
              },
            ],
            linked_own: Object.keys(merchantData).length > 0 ? true : false,
          };

          if (mccMerchantID) {
            linkData.manager_id = mccMerchantID.replace(/-/g, "");
          }

          const maxRetries = 10; // Maximální počet pokusů
          let attempt = 0; // Počítadlo pokusů
          let linkGoogleMerchantResponse;

          while (attempt < maxRetries) {
            try {
              // Přidání zpoždění 2000 ms před každým pokusem
              await new Promise((resolve) => setTimeout(resolve, 2000));

              linkGoogleMerchantResponse = await axiosInstance.patch(
                `googlemerchant/account/update/`,
                linkData
              );

              if (
                linkGoogleMerchantResponse.data.message ===
                "Customer updated successfully"
              ) {
                console.log("Merchant account successfully updated.");
                break; // Ukončení retry cyklu při úspěchu
              }
            } catch (error) {
              console.error(
                `Error updating googlemerchant/account/update/ (attempt ${
                  attempt + 1
                }):`,
                error
              );

              attempt++; // Zvýšení počítadla pokusů

              if (attempt >= maxRetries) {
                console.error(
                  "Max retry attempts reached. Merchant update failed."
                );
                showAlert(
                  t(
                    "connectGoogleForm.errorMessages.merchantAccountUpdateFailed"
                  ),
                  "danger"
                );
                return; // Ukončí funkci při selhání všech pokusů
              }
            }
          }

          // Pokud update googlemerchant/account/update/ proběhl úspěšně, pokračujeme s ostatními requesty

          // Přijetí pozvánky v google ads
          axiosInstance
            .post(`googleads/invite/googlemerchant/`, {
              ads_id: googleAccountId.replace(/-/g, ""),
              gmc_id: merchantId.replace(/-/g, ""),
            })
            .then((acceptInviteResponse) => {
              console.log("Invite accepted: ", acceptInviteResponse.data);
            })
            .catch((error) => {
              console.log(error.response);
              console.log("Merchant update message", error.message);
            });

          // Aktualizace shopu
          await axiosInstance.patch(`shop/update/${uuid}/`, {
            google_ads: googleAccountId.replace(/-/g, ""),
            google_merchant: merchantId.replace(/-/g, ""),
            google_ads_status: "connected",
            google_merchant_status: onlyMerchantCreated
              ? "created"
              : "connected",
          });

          try {
            const finalUpdateResponse = await axiosInstance.patch(
              `shop/update/${uuid}/`,
              {
                status_create: "pixel",
              }
            );
          } catch (error) {
            console.error("Error updating shop with status_create:", error);
            showAlert(t("connectGoogleForm.shopUpdateFailed"));
            return;
          }

          // Navigace na další stránku
          navigate(`/${lng}/app/install-tracking-code/`);
        } catch (error) {
          console.error(
            "An error occurred:",
            error.response ? error.response.data : error.message
          );
          console.error("Error message:", error.message);
          console.error("Error response:", error.response);
          console.error(
            "Error response.data.error:",
            error.response.data.error
          );
          showAlert(
            t("connectGoogleForm.errorMessages.connectFailedErrorMsg"),
            "danger"
          );

          let message = extractMessage(String(error.response.data.error));

          if (message.includes("User is not an administrator")) {
            console.log("error message", message);
            setIsUserDataLoading(false);
            showAlert(
              t("connectGoogleForm.errorMessages.notAdminErrorMsg"),
              "danger"
            );
          } else {
            console.log("error message", message);
          }
        }
      } else if (agreement) {
        let inviteData = {};

        try {
          // 1. Vytvoření Google Ads účtu
          let accountCreateResponse;
          try {
            accountCreateResponse = await axiosInstance.post(
              "googleads/account/create/",
              {
                customer_name: cleanDomain(selectedShop.website),
                currency_code: selectedShop.currency,
              }
            );
          } catch (error) {
            console.error("Error creating Google Ads account:", error);
            showAlert(
              t(
                "connectGoogleForm.errorMessages.adsAccountCreationFailedErrorMsg"
              ),
              "danger"
            );
            return; // Ukončíme proces, pokud tento request selže
          }

          // 2. Pozvánka do Google Ads účtu
          for (const email of emailsForShare) {
            try {
              inviteData = {
                customer_id: accountCreateResponse.data.customer_id,
                email: email,
                access_role: "STANDARD",
              };
              await axiosInstance.post("googleads/invite/user/", inviteData);
            } catch (error) {
              console.error(
                "Error inviting user to Google Ads account:",
                error
              );
              showAlert(
                t("connectGoogleForm.errorMessages.adsInviteFailedErrorMsg"),
                "danger"
              );
              return;
            }
          }

          // 3. Vytvoření Google Merchant účtu
          let merchantResponse;
          try {
            merchantResponse = await axiosInstance.post(
              `googlemerchant/account/create/`,
              {
                name: cleanDomain(selectedShop.website),
                websiteUrl: selectedShop.website,
                adultContent: false,
                conversionSettings: { freeListingsAutoTaggingEnabled: true },
                address: {
                  country:
                    selectedShop.country === "cs"
                      ? "CZ"
                      : selectedShop.country.toUpperCase(),
                  region:
                    selectedShop.country === "cs"
                      ? "CZ"
                      : selectedShop.country.toUpperCase(),
                  locality: selectedShop.city,
                  postalCode: selectedShop.postal_code,
                  streetAddress: selectedShop.street,
                },
                customerService: { email: userData.email },
                phoneNumber: selectedShop.phone,
              }
            );
          } catch (error) {
            console.error("Error creating Google Merchant account:", error);
            showAlert(
              t(
                "connectGoogleForm.errorMessages.merchantAccountCreationFailedErrorMsg"
              ),
              "danger"
            );
            return; // Ukončíme proces, pokud tento request selže
          }

          // 4. Odeslání feedu do Google Merchant účtu
          try {
            const feedResponse = await axiosInstance.post(
              `googlemerchant/account/feed/`,
              {
                account_id: merchantResponse.data.customer_id,
                feed_url: selectedShop.feed,
                feed_name: `${selectedShop.name} ${new Date().getTime()}`,
              }
            );
          } catch (error) {
            console.error(
              "Error sending feed to Google Merchant account:",
              error
            );
            showAlert(
              t("connectGoogleForm.errorMessages.feedUploadFailedErrorMsg"),
              "danger"
            );
            return; // Ukončíme proces, pokud tento request selže
          }

          // 5. Invite merchant do google ads

          const maxRetries = 10; // Maximální počet pokusů
          let attempt = 0; // Počítadlo pokusů
          let linkGoogleMerchantResponse;

          const linkData = {
            account_id: merchantResponse.data.customer_id,
            adsLinks: [
              {
                adsId: accountCreateResponse.data.customer_id,
                status: "active",
              },
            ],
            linked_own: false,
          };

          while (attempt < maxRetries) {
            try {
              console.log(
                `Starting googlemerchant/account/update/ request (attempt ${
                  attempt + 1
                })`
              );
              await new Promise((resolve) => setTimeout(resolve, 2000));

              linkGoogleMerchantResponse = await axiosInstance.patch(
                `googlemerchant/account/update/`,
                linkData
              );

              if (
                linkGoogleMerchantResponse.data.message ===
                "Customer updated successfully"
              ) {
                console.log("Merchant account successfully updated.");
                break; // Ukončení retry cyklu při úspěchu
              }
            } catch (error) {
              console.error(
                `Error updating googlemerchant/account/update/ (attempt ${
                  attempt + 1
                }):`,
                error
              );

              attempt++; // Zvýšení počítadla pokusů

              if (attempt >= maxRetries) {
                console.error(
                  "Max retry attempts reached. Merchant update failed."
                );
                showAlert(
                  t(
                    "connectGoogleForm.errorMessages.merchantAccountUpdateFailed"
                  ),
                  "danger"
                );
                return; // Ukončí funkci při selhání všech pokusů
              }
            }
          }

          // Pokud update googlemerchant/account/update/ proběhl úspěšně, pokračujeme s ostatními requesty

          axiosInstance
            .post(`googleads/invite/googlemerchant/`, {
              ads_id: accountCreateResponse.data.customer_id,
              gmc_id: merchantResponse.data.customer_id,
            })
            .then((acceptInviteResponse) => {
              console.log("Invite accepted: ", acceptInviteResponse.data);
            })
            .catch((error) => {
              console.log(error.response);
              console.log("Merchant update message", error.message);
            });

          // 6. Finální aktualizace obchodu
          try {
            const finalUpdateResponse = await axiosInstance.patch(
              `shop/update/${uuid}/`,
              {
                google_ads: accountCreateResponse.data.customer_id,
                google_merchant: merchantResponse.data.customer_id,
                google_ads_status: "created",
                google_merchant_status: "created",
                status_create: "pixel",
              }
            );
          } catch (error) {
            console.error("Error updating shop:", error);
            showAlert(
              t("connectGoogleForm.errorMessages.shopUpdateFailedErrorMsg"),
              "danger"
            );
            return; // Ukončíme proces, pokud tento request selže
          }

          // Přesměrování po úspěšném dokončení všech operací
          // setIsUserDataLoading(false);
          navigate(`/${lng}/app/install-tracking-code/`);
        } catch (error) {
          console.error("Error during submission process:", error);
          setIsUserDataLoading(false);
          setLoading(false);
          showAlert(
            t("connectGoogleForm.errorMessages.updateFailedErrorMsg"),
            "danger"
          );
        }
      }
    } catch (error) {
      console.error("Error occurred during submission:", error);
      setIsUserDataLoading(false);
      setLoading(false);
      showAlert(
        t("connectGoogleForm.errorMessages.updateFailedErrorMsg"),
        "danger"
      );
    }
  };

  // const handleBack = (e) => {
  //     e.preventDefault();
  //     window.scrollTo(0, 0);
  //     navigate(`/${lng}/app/upload-products/${uuid}/`);
  // };

  const toggleTooltip = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleGoogleAccountAccess = () => {
    const clientId =
      "388189105011-tvvt7fs4qr5eak5d382ti8mpjpjp85tg.apps.googleusercontent.com";
    const redirectUri = `${window.location.origin}/${lng}/auth/google/callback/`; // Adjust this based on your environment
    const stateUrl = `${window.location.origin}/${lng}/app/connect-google/`;
    const scope =
      "openid email profile https://www.googleapis.com/auth/content https://www.googleapis.com/auth/adwords";
    const responseType = "code";

    const googleLoginUrl = `https://accounts.google.com/o/oauth2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&state=${stateUrl}&prompt=consent`;

    window.location.href = googleLoginUrl;
  };

  const revokeToken = async () => {
    setIsUserDataLoading(true);
    try {
      const response = await fetch(
        `https://oauth2.googleapis.com/revoke?token=${userData?.refresh_token}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.ok) {
        console.log("Token byl úspěšně zneplatněn.");
      } else {
        console.error("Chyba při zneplatňování tokenu.");
      }
    } catch (error) {
      setIsUserDataLoading(false);
      console.error("Chyba:", error);
    } finally {
      setTimeout(() => {
        handleGoogleAccountAccess();
      }, 2000);
    }
  };

  const onChooseAccounts = () => {
    setIsUserDataLoading(true);
    setShowConnectAccountsBtn(false);
    getAccounts();
  };

  // Přizpůsobení labels v steps
  const localizedSteps = steps.map((step) => ({
    ...step,
    label: t(`${step.label}`),
    alertMessage: t(step.alertMessage),
  }));

  const addEmailForShareInput = () => {
    if (emailsForShare.length < 3) {
      setEmailsForShare((prevState) => [...prevState, ""]);
    } else {
      showAlert("Je možné přidat maximálně 5 e-mailů.", "danger");
    }
  };

  const removeEmailForShareInput = (index) => {
    setEmailsForShare((prevState) =>
      prevState.filter((item, i) => i !== index)
    );
  };

  if (loadingState) return <ModalLoading />;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Připojení účtů - PermoniQ</title>
      </Helmet>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {isUserDataLoading && <ModalLoading message={message} />}
      {/* <div className="progress">
                <div className="container">
                    <ul>
                        <li onClick={() => showAlert(t("connectGoogleForm.contactSupport"))} className="is-checked"><strong>1.</strong> {t('connectGoogleProgress.addShop')}</li>
                        <li onClick={() => showAlert(t("connectGoogleForm.contactSupport"))} className="is-checked"><strong>2.</strong> {t('connectGoogleProgress.companyDetails')}</li>
                        <li onClick={() => showAlert(t("connectGoogleForm.contactSupport"))} className="is-checked"><strong>3.</strong> {t('connectGoogleProgress.uploadProducts')}</li>
                        <li onClick={() => showAlert(t("connectGoogleForm.contactSupport"))} className="is-active"><strong>4.</strong> {t('connectGoogleProgress.connectGoogle')}</li>
                        <li onClick={() => showAlert(t("connectGoogleForm.contactSupport"))}><strong>5.</strong> {t('connectGoogleProgress.installCode')}</li>
                    </ul>
                </div>
            </div> */}

      <ProgressTracker steps={localizedSteps} />
      <BudgetPnoDialog
        ref={BudgetPnoDialogRef}
        onPnoAndBudgetSet={onPnoAndBudgetSet}
      />
      <ModalContactUs setMessage={setMessage} />

      <section className="section register">
        <article className="container">
          <h1 className="title">{t("connectGoogleTitle")}</h1>
          <form
            onSubmit={(e) => openBudgetDialog(e)}
            autoComplete="off"
            className="form"
          >
            <div className="box how" style={{ position: "relative" }}>
              <div className="center">
                <h3>{t("connectGoogleForm.haveAccounts")}</h3>
                <p>{t("connectGoogleForm.haveAccountsDetails")}</p>
                {!haveAccess && !onlyAds && !showOnlyAds && haveAccounts && (
                  <p
                    style={{
                      textAlign: "justify",
                      marginBlock: "2rem",
                      backgroundColor: "#ededed",
                      padding: "1rem",
                      borderRadius: "12px",
                    }}
                  >
                    {t("connectGoogleForm.haveAccountsInfo")}
                    <br />
                    <br />
                    {t("connectGoogleForm.haveAccountsInfo2")}
                  </p>
                )}
                <p>
                  <label className="switcher js-how">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={haveAccounts}
                      onChange={() => setHaveAccounts(!haveAccounts)}
                    />
                    <span
                      className={`switcher__label ${
                        haveAccounts ? "is-checked" : "is-unchecked"
                      }`}
                    >
                      {haveAccounts
                        ? t("connectGoogleForm.yesHave")
                        : t("connectGoogleForm.noHave")}
                    </span>
                    <span className="switcher__back"></span>
                  </label>
                </p>
              </div>

              <br />

              {haveAccounts ? (
                <div className="how__yes">
                  <div className="give_access_container">
                    {haveAccess && showConnectAccountsBbtn && !onlyAds && (
                      <div className="connect_accounts_container">
                        <p>
                          <a
                            data-var="upload"
                            className="login__external"
                            onClick={() => onChooseAccounts()}
                          >
                            <img src={googleLogo} alt="Google" />
                            {t("connectGoogleForm.uploadAccounts")}
                          </a>
                          {/* <button className="btn btn--inverse" type="submit" disabled={loading} onClick={() => onChooseAccounts()} style={{marginTop: "40px"}}>Propojit účty</button> */}
                        </p>
                        <p>{t("connectGoogleForm.uploadAccountsInfo")}</p>
                      </div>
                    )}
                    {onlyAds && (
                      <div className="connect_accounts_container">
                        <p>
                          <a
                            data-var="upload"
                            className="login__external"
                            onClick={() => getOnlyAds()}
                          >
                            <img src={googleLogo} alt="Google" />
                            {t("connectGoogleForm.uploadAccounts")}
                          </a>
                          {/* <button className="btn btn--inverse" type="submit" disabled={loading} onClick={() => onChooseAccounts()} style={{marginTop: "40px"}}>Propojit účty</button> */}
                        </p>
                        <p>{t("connectGoogleForm.uploadAccountsInfo")}</p>
                      </div>
                    )}
                    {onlyAds && showConnectAccountsBbtn && (
                      <div className="connect_accounts_container">
                        <p>
                          <a
                            className="login__external"
                            onClick={() => onChooseAccounts()}
                          >
                            <img src={googleLogo} alt="Google" />
                            {t("connectGoogleForm.uploadAccounts")}
                          </a>
                          {/* <button className="btn btn--inverse" type="submit" disabled={loading} onClick={() => onChooseAccounts()} style={{marginTop: "40px"}}>Propojit účty</button> */}
                        </p>
                        <p>{t("connectGoogleForm.uploadAccountsInfo")}</p>
                      </div>
                    )}
                    {showOnlyAds && Object.keys(adsData).length > 0 && (
                      <>
                        {
                          <div>
                            <p
                              style={{
                                textAlign: "left",
                                marginBlock: "1rem",
                                // backgroundColor: "#ededed",
                                // padding: "1rem",
                                borderRadius: "12px",
                              }}
                            >
                              {t("connectGoogleForm.haveOnlyAdsInfo")}
                            </p>
                          </div>
                        }
                        <div className="choose_accounts_select_wrapper">
                          {/* <p style={{ fontWeight: "500" }}>Google Ads</p> */}
                          <div className="google_ads_logo_box">
                            <img
                              src={adsLogo}
                              className="google_ads_logo"
                              alt="Google Ads Logo"
                            />
                          </div>
                          <select
                            name="ads_select"
                            id="ads_select"
                            className="ads_select"
                            onChange={(e) => {
                              setGoogleAccountId(e.target.value);
                            }}
                          >
                            {adsData?.map((oneItem) => (
                              <React.Fragment key={oneItem.client_id}>
                                {!oneItem.is_manager && (
                                  <option
                                    value={oneItem.client_id}
                                    disabled={oneItem.assigned_in_shop === true}
                                    onClick={() => setMccAdsID("")}
                                  >
                                    {`${oneItem.client_name}: ${
                                      oneItem.client_id
                                    }${
                                      oneItem.assigned_in_shop
                                        ? ` ${t("connectGoogleForm.usedAccount")}`
                                        : ""
                                    }`}
                                  </option>
                                )}
                                {oneItem.is_manager &&
                                  oneItem.clients.length > 0 && (
                                    <optgroup label={`${oneItem.client_name}`}>
                                      {oneItem.clients.map((subClient) => (
                                        <option
                                          value={subClient.client_id}
                                          key={subClient.client_id}
                                          disabled={
                                            subClient.assigned_in_shop === true
                                          }
                                          onClick={() =>
                                            setMccAdsID(oneItem.client_id)
                                          }
                                        >
                                          {`${
                                            subClient.client_name ||
                                            "Unnamed client"
                                          }: ${subClient.client_id}${
                                            subClient.assigned_in_shop
                                              ? ` ${t("connectGoogleForm.usedAccount")}`
                                              : ""
                                          }`}
                                        </option>
                                      ))}
                                    </optgroup>
                                  )}
                              </React.Fragment>
                            ))}
                          </select>
                        </div>
                        <div className="create_only_merchatn_wrapper">
                          <div className="how__no">
                            <p>{t("connectGoogleForm.createMerchantinfo")}</p>
                            <br />
                            <p>
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  name="agreement"
                                  id="agreement"
                                  checked={createMerchantAgree}
                                  onChange={(e) =>
                                    setCreateMerchantAgree(e.target.checked)
                                  }
                                  required
                                />
                                <span className="checkbox__label">
                                  {t("connectGoogleForm.agreeCreate")}
                                </span>
                                <span className="checkbox__checker"></span>
                              </label>
                            </p>
                          </div>
                          <p>
                            {/* <button className="btn btn--inverse" type="submit" disabled={loading} onClick={() => onChooseAccounts()} style={{marginTop: "40px"}}>Propojit účty</button> */}
                          </p>
                        </div>
                      </>
                    )}
                    {chooseAccounts &&
                    Object.keys(adsData).length > 0 &&
                    Object.keys(merchantData).length > 0 ? (
                      <div className="choose_accounts_container">
                        <p
                          style={{ marginBlock: "30px" }}
                          className="choose_account_text"
                        >
                          {t("connectGoogleForm.haveAccountsInfoUploaded")}
                        </p>
                        <p className="choose_account_text">
                          {t("connectGoogleForm.chooseAccount")}
                        </p>

                        <div className="choose_accounts_select_wrapper">
                          <div className="google_ads_select_wrapper">
                            <div className="google_ads_logo_box">
                              <img
                                src={adsLogo}
                                alt="Google Ads Logo"
                                className="google_ads_logo"
                              />
                            </div>

                            <select
                              name="ads_select"
                              id="ads_select"
                              className="ads_select"
                              onChange={(e) => {
                                setGoogleAccountId(e.target.value);

                                const selectedClientId = e.target.value;
                                const selectedClient =
                                  adsData?.find(
                                    (oneItem) =>
                                      oneItem.client_id === selectedClientId
                                  ) ||
                                  adsData
                                    ?.find((oneItem) =>
                                      oneItem.clients?.some(
                                        (subClient) =>
                                          subClient.client_id ===
                                          selectedClientId
                                      )
                                    )
                                    ?.clients.find(
                                      (subClient) =>
                                        subClient.client_id === selectedClientId
                                    );

                                if (selectedClient) {
                                  setCurrencyFromAds(
                                    selectedClient.client_currency
                                  );

                                  sessionStorage.setItem(
                                    "cfa_1",
                                    selectedClient.client_currency
                                  );
                                }
                              }}
                            >
                              {adsData?.map((oneItem) => (
                                <React.Fragment key={oneItem.client_id}>
                                  {!oneItem.is_manager && (
                                    <option
                                      value={oneItem.client_id}
                                      disabled={
                                        oneItem.assigned_in_shop === true
                                      }
                                      onClick={() => setMccAdsID("")}
                                    >
                                      {`${oneItem.client_name}: ${
                                        oneItem.client_id
                                      }${
                                        oneItem.assigned_in_shop
                                          ? ` ${t("connectGoogleForm.usedAccount")}`
                                          : ""
                                      }`}
                                    </option>
                                  )}
                                  {oneItem.is_manager &&
                                    oneItem.clients.length > 0 && (
                                      <optgroup
                                        label={`${oneItem.client_name}`}
                                      >
                                        {oneItem.clients.map((subClient) => (
                                          <option
                                            value={subClient.client_id}
                                            key={subClient.client_id}
                                            disabled={
                                              subClient.assigned_in_shop ===
                                              true
                                            }
                                            onClick={() =>
                                              setMccAdsID(oneItem.client_id)
                                            }
                                          >
                                            {`${
                                              subClient.client_name ||
                                              "Unnamed client"
                                            }: ${subClient.client_id}${
                                              subClient.assigned_in_shop
                                                ? ` ${t("connectGoogleForm.usedAccount")}`
                                                : ""
                                            }`}
                                          </option>
                                        ))}
                                      </optgroup>
                                    )}
                                </React.Fragment>
                              ))}
                            </select>
                          </div>

                          <div className="google_merchant_select_wrapper">
                            <div className="google_merchant_box_logo">
                              <img
                                src={merchantLogo}
                                alt="Google Ads Logo"
                                className="google_merchant_logo"
                              />
                            </div>
                            <select
                              name="merchant_select"
                              id="merchant_select"
                              className="merchant_select"
                              onChange={(e) => {
                                const selectedOption = JSON.parse(
                                  e.target.value
                                );

                                const selectedClientId = e.target.value;
                                const selectedClient =
                                  adsData?.find(
                                    (oneItem) =>
                                      oneItem.client_id === selectedClientId
                                  ) ||
                                  adsData
                                    ?.find((oneItem) =>
                                      oneItem.clients?.some(
                                        (subClient) =>
                                          subClient.client_id ===
                                          selectedClientId
                                      )
                                    )
                                    ?.clients.find(
                                      (subClient) =>
                                        subClient.client_id === selectedClientId
                                    );

                                if (selectedClient) {
                                  setCurrencyFromAds(
                                    selectedClient.client_currency
                                  );
                                  sessionStorage.setItem(
                                    "cfa_1",
                                    selectedClient.client_currency
                                  );
                                }

                                // Pokud je vybraný účet manager
                                if (
                                  selectedOption.is_manager &&
                                  selectedOption.sub_client_id
                                ) {
                                  setMccMerchantId(selectedOption.client_id); // client_id managera
                                  setMerchantId(selectedOption.sub_client_id); // client_id zvoleného subklienta
                                  setMerchantClientName(
                                    selectedOption.sub_client_name
                                  );
                                } else {
                                  // Pokud není manager, nastaví se pouze vybraný účet
                                  setMerchantId(selectedOption.client_id);
                                  setMerchantClientName(
                                    selectedOption.client_name
                                  );
                                  setMccMerchantId("");
                                }
                              }}
                            >
                              {merchantData?.map((oneItem) => (
                                <React.Fragment key={oneItem.client_id}>
                                  {!oneItem.is_manager && (
                                    <option
                                      value={JSON.stringify({
                                        client_id: oneItem.client_id,
                                        client_name: oneItem.client_name,
                                        is_manager: oneItem.is_manager,
                                      })}
                                      disabled={
                                        oneItem.assigned_in_shop === true
                                      }
                                    >
                                      {`${oneItem.client_name}: ${
                                        oneItem.client_id
                                      }${
                                        oneItem.assigned_in_shop
                                          ? ` ${t("connectGoogleForm.usedAccount")}`
                                          : ""
                                      }`}
                                    </option>
                                  )}
                                  {oneItem.is_manager &&
                                    oneItem.clients.length > 0 && (
                                      <optgroup
                                        label={`${oneItem.client_name}`}
                                      >
                                        {oneItem.clients.map((subClient) => (
                                          <option
                                            value={JSON.stringify({
                                              client_id: oneItem.client_id,
                                              client_name: oneItem.client_name,
                                              is_manager: oneItem.is_manager,
                                              sub_client_id:
                                                subClient.client_id,
                                              sub_client_name:
                                                subClient.client_name,
                                            })}
                                            key={subClient.client_id}
                                            disabled={
                                              subClient.assigned_in_shop ===
                                              true
                                            }
                                          >
                                            {`${
                                              subClient.client_name ||
                                              "Unnamed client"
                                            }: ${subClient.client_id}${
                                              subClient.assigned_in_shop
                                                ? ` ${t("connectGoogleForm.usedAccount")}`
                                                : ""
                                            }`}
                                          </option>
                                        ))}
                                      </optgroup>
                                    )}
                                </React.Fragment>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : (
                      !haveAccess &&
                      !onlyAds &&
                      !showOnlyAds && (
                        <>
                          <p>
                            <a
                              className="login__external"
                              onClick={() => handleGoogleAccountAccess()}
                            >
                              <img src={googleLogo} alt="Google" />
                              {t("connectGoogleForm.giveAccess")}
                            </a>
                          </p>
                        </>
                      )
                    )}
                  </div>
                  {/* <p>{t('connectGoogleForm.shareAccess')}</p>
                                    <br />
                                    <p>
                                        <label htmlFor="gid">
                                            {t('connectGoogleForm.googleAccountId')}
                                            <span
                                                className={`tooltip ${isTooltipOpen ? 'is-open' : ''}`}
                                                onClick={toggleTooltip}
                                            >
                                                <span className="tooltip__icon"></span>
                                                <span className="tooltip__body">
                                                    {t('connectGoogleForm.moreInfo')}
                                                    <span className="tooltip__close" onClick={toggleTooltip}>&times;</span>
                                                </span>
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            id="gid"
                                            value={googleAccountId}
                                            onChange={handleGoogleAccountIdChange}
                                            className={isGoogleAccountIdValid ? '' : 'is-invalid'}
                                        />
                                        {!isGoogleAccountIdValid && (
                                            <span className="error">{t('connectGoogleForm.invalidGoogleAccountId')}</span>
                                        )}
                                    </p> */}
                  {userData.email !== "info@permoniq.com" &&
                    (haveAccess ||
                      showOnlyAds ||
                      onlyAds ||
                      (chooseAccounts &&
                        Object.keys(adsData).length > 0 &&
                        Object.keys(merchantData).length > 0)) && (
                      <button
                        type="button"
                        className={`btn btn--secondary ${styles.removeAccountBtn}`}
                        onClick={(e) => {
                          e.target.blur();
                          revokeToken();
                        }}
                      >
                        Změnit účet Google
                      </button>
                    )}
                </div>
              ) : (
                <div className="how__no">
                  <div className={styles.email_for_share_box}>
                    <label htmlFor="email_for_share">
                      Zadejte e-mail pro nasdílení Google Ads. Pokud je to
                      potřeba, je možné přidat až 3 e-maily.
                    </label>
                    {emailsForShare.map((item, index) => {
                      return (
                        <div key={index} className={styles.input_box}>
                          <input
                            type="text"
                            id="email_for_share"
                            className={styles.email_for_share}
                            value={item}
                            onChange={(e) => {
                              setEmailsForShare((prevEmails) =>
                                prevEmails.map((email, i) =>
                                  i === index ? e.target.value : email
                                )
                              );
                            }}
                          />
                          {index === 0 ? (
                            <img
                              src={plusDarkIcon}
                              className={`${styles.plusIcon}`}
                              alt="Add headline"
                              style={{ cursor: "pointer" }}
                              onClick={() => addEmailForShareInput()}
                            />
                          ) : (
                            <img
                              src={minusIcon}
                              className={`${styles.minusIcon}`}
                              alt="Remove headline"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeEmailForShareInput(index)}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <p>{t("connectGoogleForm.createAccounts")}</p>
                  <p>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="agreement"
                        id="agreement"
                        checked={agreement}
                        onChange={(e) => setAgreement(e.target.checked)}
                        required
                      />
                      <span className="checkbox__label">
                        {t("connectGoogleForm.agreeCreate")}
                      </span>
                      <span className="checkbox__checker"></span>
                    </label>
                  </p>
                </div>
              )}
            </div>

            <div className="register__submit">
              {chooseAccounts && haveAccounts && (
                <p>
                  <button
                    className="btn"
                    type="submit"
                    disabled={loading}
                    onClick={(e) => e.target.blur()}
                  >
                    {t("connectGoogleForm.setBudgetAndPno")}
                  </button>
                </p>
              )}

              {!haveAccounts && agreement && (
                <p>
                  <button
                    className="btn"
                    type="submit"
                    disabled={loading}
                    onClick={(e) => e.target.blur()}
                  >
                    {t("connectGoogleForm.setBudgetAndPno")}
                  </button>
                </p>
              )}

              {showOnlyAds && createMerchantAgree && (
                <p>
                  <button
                    className="btn"
                    type="submit"
                    disabled={loading}
                    onClick={(e) => e.target.blur()}
                  >
                    {t("connectGoogleForm.setBudgetAndPno")}
                  </button>
                </p>
              )}
            </div>
          </form>
        </article>
      </section>
      {userData.email === "info@permoniq.com" && showAdminModal && (
        <Modal
          title="Informace o obchodu"
          cancelText="Zavřít"
          confirmText=""
          onClose={() => setShowAdminModal(false)}
        >
          {infoItems.map((item, index) => (
            <InfoBoxItem key={index} label={item.label} value={item.value} />
          ))}
        </Modal>
      )}
    </>
  );
};

export default ConnectGoogle;
