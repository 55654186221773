import React, { useContext, useEffect } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

import Cookies from "js-cookie";

import { AppContext } from "../context/AppContext";

import Home from "../pages/Home";
import Login from "../pages/Login";
import TwoFactor from "../pages/2fa/TwoFactor";
import VerifyOtp from "../pages/2fa/VerifyOtp";
import LoadingGoogleLogin from "../pages/LoadingGoogleLogin";
import NotFound from "../pages/NotFound";
import ShopOption from "../pages/ShopOption";
import Users from "../pages/Users";
import RegistrationAccount from "../pages/RegistrationAccount";
import RegisterShop from "../pages/RegisterShop";
import CompanyDetails from "../pages/CompanyDetails";
import UploadProducts from "../pages/UploadProducts";
import ConnectGoogle from "../pages/ConnectGoogle";
import InstallTrackingCode from "../pages/InstallTrackingCode";
import AppContent from "../pages/AppContent";
import TermsOfServices from "../pages/TermsOfServices";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import GoogleTermsPage from "../pages/GoogleTermsPage";
import Blog from "../pages/Blog";
import NewPassword from "../pages/NewPassword";
import { clearUserDetailStorage } from "../api/AxiosConfig";
import deleteAllDatabases from "../utils/functions/deleteAllDatabases";

const supportedLanguages = ["cs", "sk", "en", "hu", "pl", "ro"];

export const allowedPaths = [
  { path: "", element: <Home /> },
  { path: "login/", element: <Login /> },
  { path: "2fa/two-factor/", element: <TwoFactor /> },
  { path: "2fa/verify-otp/", element: <VerifyOtp /> },
  { path: "auth/google/callback/", element: <LoadingGoogleLogin /> },
  { path: "404/", element: <NotFound /> },
  { path: "terms-of-service/", element: <TermsOfServices /> },
  { path: "privacy-policy/", element: <PrivacyPolicy /> },
  { path: "google-terms/", element: <GoogleTermsPage /> },
  { path: "blog/", element: <Blog /> },
  { path: "user-password-reset-confirm/", element: <NewPassword /> },
  { path: "registration-account/", element: <RegistrationAccount /> },
  { path: "app/shop-option/", element: <ShopOption /> },
  { path: "app/users/", element: <Users /> },
  { path: "app/register-shop/:id?/", element: <RegisterShop /> },
  { path: "app/company-details/:id?/", element: <CompanyDetails /> },
  { path: "app/upload-products/:id?/", element: <UploadProducts /> },
  { path: "app/connect-google/:id?/", element: <ConnectGoogle /> },
  { path: "app/install-tracking-code/:id?/", element: <InstallTrackingCode /> },
  { path: "app/dashboard/:id?/", element: <AppContent /> },
  { path: "app/statistics/:id?/", element: <AppContent /> },
  { path: "app/campaigns/:id?/", element: <AppContent /> },
  { path: "app/credit/:id?/", element: <AppContent /> },
  { path: "app/settings/:id?/", element: <AppContent /> },
  { path: "app/help/:id?/", element: <AppContent /> },
  { path: "app/invoice/:id?/", element: <AppContent /> },
  { path: "app/products/:id?/", element: <AppContent /> },
  { path: "app/contact-us/:id?/", element: <AppContent /> },
  {
    path: "*", // Záchyt jakékoliv jiné cesty pod /:lng/
    element: <NotFound />,
  },
];

const LanguageWrapper = ({ children }) => {
  const location = useLocation();
  const { pathname, search } = useLocation();
  const lng = pathname.split("/")[1];
  const navigate = useNavigate();

  const { userData, isLogged, uuid } = useContext(AppContext);

  window.dataLayer = window.dataLayer || [];

  useEffect(() => {
    if (window.dataLayer && !window.location.href.includes("auth/google")) {
      const obj = {
        event: "page_view",
        page: {
          href: window.location.href,
          path: pathname,
          title: window.document.title,
        },
        user: {
          is_logged: !!Cookies.get("aTkn_0yD9K"),
          uuid: Cookies.get("utk_98x76") ? Cookies.get("utk_98x76") : undefined,
        },
        global: {
          env: process.env.NODE_ENV,
        },
      };

      window.dataLayer.push(obj);
    }
  }, [pathname]);

  useEffect(() => {
    const refreshToken = Cookies.get("rTkn_4zF7P");
    const accessToken = Cookies.get("aTkn_0yD9K");
    if (
      !refreshToken &&
      window.location.pathname.includes("app") &&
      !window.location.pathname.includes("login")
    ) {
      // deleteAllDatabases()
      clearUserDetailStorage();
      navigate(`/${lng}/login/`, { replace: true });
    } else if (
      !refreshToken &&
      window.location.pathname.includes("user-password-reset-confirm") &&
      !window.location.pathname.includes("login")
    ) {
      navigate(`/${lng}/user-password-reset-confirm${search}`, {
        replace: true,
      });
    } else if (accessToken && window.location.pathname.includes("login")) {
      navigate(`/${lng}/app/dashboard/`, { replace: true });
    }
  }, []);

  if (!supportedLanguages.includes(lng)) {
    const newPath = allowedPaths.find(
      (allowed) => allowed.path.replace("/", "") === lng.replace("/", "")
    );

    if (newPath) {
      return <Navigate to={`/cs/${newPath.path}${search}`} replace />;
    } else {
      return <NotFound />;
    }
  }

  return children;
};

export default LanguageWrapper;
