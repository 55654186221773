import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import LanguageWrapper, { allowedPaths } from "./components/LanguageWrapper";
import RedirectWithSlash from "./components/RedirectWithSlash";
import Layout from "./components/Layout";
import ErrorPage from "./pages/ErrorPage"; // Import vlastní chybové stránky

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/cs/" replace />,
    errorElement: <ErrorPage /> // Fallback UI pro chyby na root cestě
  },
  {
    path: "/:lng/",
    element: (
      <LanguageWrapper>
        <RedirectWithSlash>
          <Layout />
        </RedirectWithSlash>
      </LanguageWrapper>
    ),
    errorElement: <ErrorPage />, // Fallback UI pro chyby na této úrovni
    children: allowedPaths
  }
]);

export default router;
