import { cleanDomain } from "../functions/cleanDomain"

/**
 * Asynchronously checks the status of a domain by sending a request to a specific endpoint.
 *
 * @async
 * @function checkDomainStatus
 * @param {string} domain - The domain name to check. Must be a string.
 * @throws {TypeError} Throws an error if the `domain` parameter is not a string.
 * @returns {Promise<Response>} A promise that resolves to the response of the fetch request.
 *
 * @example
 * try {
 *   const response = await checkDomainStatus('example.com');
 *   console.log(await response.text());
 * } catch (error) {
 *   console.error('Error:', error.message);
 * }
 */
export async function checkDomainStatus(domain) {
    if (typeof domain !== 'string') {
        throw new TypeError('The "domain" parameter must be a string');
    }

    const cleanedDomain = cleanDomain(domain);
   
    const response = await fetch(`https://das.domena.cz/${cleanedDomain}/`);
    return response;
}
