import React, { useMemo, useState, useContext, useEffect } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useResizeColumns,
  useBlockLayout,
  useExpanded,
} from "react-table";
import { AppContext } from "../context/AppContext";
import styles from "../css/ProductsTable.module.css";
import plusIcon from "../img/icons/plus.svg";
import minusIcon from "../img/icons/minus.svg";
import formatNumber from "../utils/functions/formatNumber";

// Pomocná funkce pro měření šířky textu
const measureTextWidth = (text, font = "14px sans-serif") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
};

// Rekurzivní funkce pro výpočet maximální šířky dat (včetně odsazení pro zanoření)
const getMaxWidthForColumnData = (data, renderFunc, depth = 0) => {
  let maxWidth = 0;
  data.forEach((row) => {
    const text = renderFunc(row, depth);
    // Přičteme odsazení podle hloubky (např. depth * 50)
    const width = measureTextWidth(text) + depth * 40;
    if (width > maxWidth) maxWidth = width;
    if (row.subRows) {
      const childWidth = getMaxWidthForColumnData(
        row.subRows,
        renderFunc,
        depth + 1
      );
      if (childWidth > maxWidth) maxWidth = childWidth;
    }
  });
  return maxWidth;
};

const ProductsTable = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("");
  // viewOption může být "title", "brand", "productType" nebo "category"
  const [viewOption, setViewOption] = useState("title");
  const { selectedShop, getCurrency } = useContext(AppContext);

  // Stav pro šířku prvního sloupce a příznak, zda uživatel ručně změnil velikost
  const [firstColWidth, setFirstColWidth] = useState(150);
  const [firstColUserSet, setFirstColUserSet] = useState(false);

  const getBackgroundColorForDepth = (depth) => {
    const depthColors = ["#ffffff", "#f0f8ff", "#e6f7ff", "#d9f2ff"];
    return depthColors[depth] || depthColors[depthColors.length - 1];
  };

  // Agregace a filtrování dat podle viewOption
  const tableData = useMemo(() => {
    let filtered = data.filter(
      (item) => item.title && item.title.trim() !== ""
    );
    if (searchTerm) {
      const lowerTerm = searchTerm.toLowerCase();
      if (viewOption === "title") {
        filtered = filtered.filter((item) =>
          item.title.toLowerCase().includes(lowerTerm)
        );
      } else if (viewOption === "brand") {
        filtered = filtered.filter(
          (item) => item.brand && item.brand.toLowerCase().includes(lowerTerm)
        );
      } else if (viewOption === "productType") {
        filtered = filtered.filter(
          (item) =>
            item.product_type &&
            item.product_type.toLowerCase().includes(lowerTerm)
        );
      } else if (viewOption === "category") {
        filtered = filtered.filter(
          (item) =>
            item.category && item.category.toLowerCase().includes(lowerTerm)
        );
      }
    }
    if (viewOption === "title") {
      return filtered;
    } else if (viewOption === "brand") {
      const aggregated = {};
      filtered.forEach((item) => {
        const key = item.brand || "nepřiřazeno";
        if (!aggregated[key]) {
          aggregated[key] = {
            title: key,
            brand: key,
            item_id: "Celkem",
            total_views: 0,
            total_clicks: 0,
            total_purchases: 0,
            total_revenue: 0,
            total_cost: 0,
          };
        }
        aggregated[key].total_views += Number(item.total_views || 0);
        aggregated[key].total_clicks += Number(item.total_clicks || 0);
        aggregated[key].total_purchases += Number(item.total_purchases || 0);
        aggregated[key].total_revenue += Number(item.total_revenue || 0);
        aggregated[key].total_cost += Number(item.total_cost || 0);
      });
      return Object.values(aggregated);
    } else if (viewOption === "productType") {
      const buildTree = (items, level = 0) => {
        const keys = [
          "product_type",
          "product_type2",
          "product_type3",
          "product_type4",
        ];
        if (level >= keys.length) return [];
        const groups = {};
        items.forEach((item) => {
          const keyValue = item[keys[level]];
          if (keyValue) {
            if (!groups[keyValue]) groups[keyValue] = [];
            groups[keyValue].push(item);
          }
        });
        return Object.keys(groups).map((groupKey) => {
          const groupItems = groups[groupKey];
          const hasNextLevel = groupItems.some((item) => item[keys[level + 1]]);
          const total_views = groupItems.reduce(
            (sum, item) => sum + Number(item.total_views || 0),
            0
          );
          const total_clicks = groupItems.reduce(
            (sum, item) => sum + Number(item.total_clicks || 0),
            0
          );
          const total_purchases = groupItems.reduce(
            (sum, item) => sum + Number(item.total_purchases || 0),
            0
          );
          const total_revenue = groupItems.reduce(
            (sum, item) => sum + Number(item.total_revenue || 0),
            0
          );
          const total_cost = groupItems.reduce(
            (sum, item) => sum + Number(item.total_cost || 0),
            0
          );
          if (!hasNextLevel) {
            return {
              title: groupKey,
              product_type: groupKey,
              item_id: "Celkem",
              total_views,
              total_clicks,
              total_purchases,
              total_revenue,
              total_cost,
            };
          } else {
            const childItems = groupItems.filter(
              (item) => item[keys[level + 1]]
            );
            const ungroupedItems = groupItems.filter(
              (item) => !item[keys[level + 1]]
            );
            const childTree = buildTree(childItems, level + 1);
            let summaryRow = null;
            if (ungroupedItems.length > 0) {
              const sum_views = ungroupedItems.reduce(
                (sum, item) => sum + Number(item.total_views || 0),
                0
              );
              const sum_clicks = ungroupedItems.reduce(
                (sum, item) => sum + Number(item.total_clicks || 0),
                0
              );
              const sum_purchases = ungroupedItems.reduce(
                (sum, item) => sum + Number(item.total_purchases || 0),
                0
              );
              const sum_revenue = ungroupedItems.reduce(
                (sum, item) => sum + Number(item.total_revenue || 0),
                0
              );
              const sum_cost = ungroupedItems.reduce(
                (sum, item) => sum + Number(item.total_cost || 0),
                0
              );
              summaryRow = {
                title: "ostatní",
                product_type: `ostatní pro ${groupKey}`,
                item_id: "Celkem",
                total_views: sum_views,
                total_clicks: sum_clicks,
                total_purchases: sum_purchases,
                total_revenue: sum_revenue,
                total_cost: sum_cost,
                isSummary: true,
              };
            }
            const subRows = summaryRow ? [summaryRow, ...childTree] : childTree;
            return {
              title: groupKey,
              product_type: groupKey,
              item_id: "Skupina",
              total_views,
              total_clicks,
              total_purchases,
              total_revenue,
              total_cost,
              subRows,
            };
          }
        });
      };
      return buildTree(filtered, 0);
    } else if (viewOption === "category") {
      const buildCategoryTree = (items, level = 0) => {
        const keys = ["category", "category2", "category3", "category4"];
        if (level >= keys.length) return [];
        const groups = {};
        items.forEach((item) => {
          const keyValue = item[keys[level]];
          if (keyValue) {
            if (!groups[keyValue]) groups[keyValue] = [];
            groups[keyValue].push(item);
          }
        });
        return Object.keys(groups).map((groupKey) => {
          const groupItems = groups[groupKey];
          const total_views = groupItems.reduce(
            (sum, item) => sum + Number(item.total_views || 0),
            0
          );
          const total_clicks = groupItems.reduce(
            (sum, item) => sum + Number(item.total_clicks || 0),
            0
          );
          const total_purchases = groupItems.reduce(
            (sum, item) => sum + Number(item.total_purchases || 0),
            0
          );
          const total_revenue = groupItems.reduce(
            (sum, item) => sum + Number(item.total_revenue || 0),
            0
          );
          const total_cost = groupItems.reduce(
            (sum, item) => sum + Number(item.total_cost || 0),
            0
          );
          const children = buildCategoryTree(groupItems, level + 1);
          return {
            title: groupKey,
            category: groupKey,
            item_id: "Celkem",
            total_views,
            total_clicks,
            total_purchases,
            total_revenue,
            total_cost,
            subRows: children.length > 0 ? children : undefined,
          };
        });
      };
      return buildCategoryTree(filtered, 0);
    }
  }, [data, viewOption, searchTerm]);

  const autoFirstColumnWidth = useMemo(() => {
    if (!tableData || tableData.length === 0) return 150;
    const accessor =
      viewOption === "title"
        ? "title"
        : viewOption === "brand"
        ? "brand"
        : viewOption === "productType"
        ? "product_type"
        : "category";
    const maxWidth = getMaxWidthForColumnData(
      tableData,
      (row, depth) => row[accessor] || "",
      0
    );
    return Math.ceil(maxWidth + 24);
  }, [tableData, viewOption]);

  const autoLastColumnWidth = useMemo(() => {
    if (!tableData || tableData.length === 0) return 50;
    const maxWidth = getMaxWidthForColumnData(
      tableData,
      (row, depth) => `${row.total_revenue || 0} ${selectedShop.currency}`,
      0
    );
    return Math.ceil(maxWidth + 24);
  }, [tableData, selectedShop.currency]);

  useEffect(() => {
    const currentRows =
      viewOption === "productType" || viewOption === "category"
        ? tableData
        : tableData.slice(0, 10);
    if (!firstColUserSet) {
      const accessor =
        viewOption === "title"
          ? "title"
          : viewOption === "brand"
          ? "brand"
          : viewOption === "productType"
          ? "product_type"
          : "category";
      const newWidth = Math.ceil(
        getMaxWidthForColumnData(
          currentRows,
          (row, depth) => row[accessor] || "",
          0
        ) + 24
      );
      setFirstColWidth(newWidth);
    }
  }, [tableData, viewOption, firstColUserSet]);

  const firstColumn = useMemo(() => {
    const common = {
      className: "firstColumn",
      minWidth: 50, // dovoluje zmenšení až na 50px
      width: firstColWidth,
      maxWidth: 10000, // umožňuje libovolné roztáhnutí
    };
    if (viewOption === "title") {
      return { Header: "Název produktu", accessor: "title", ...common };
    } else if (viewOption === "brand") {
      return { Header: "Značka", accessor: "brand", ...common };
    } else if (viewOption === "productType") {
      return {
        Header: "Název kategorie",
        accessor: "product_type",
        ...common,
        Cell: ({ row, value }) => {
          const depth = row.depth;
          return (
            <div
              className={styles.cellToFlex}
              style={{
                marginLeft: `${depth * 20}px`,
                padding: "4px",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <span
                {...row.getToggleRowExpandedProps()} // Removed onMouseDown
                style={{
                  cursor: row.canExpand ? "pointer" : "default",
                  marginRight: "4px",
                }}
              >
                {row.canExpand ? (
                  row.isExpanded ? (
                    <span className={styles.expandIcon}>-</span>
                  ) : (
                    <span className={styles.expandIcon}>+</span>
                  )
                ) : (
                  ""
                )}
              </span>
              {value}
            </div>
          );
        },
      };
    } else if (viewOption === "category") {
      return {
        Header: "Kategorie",
        accessor: "category",
        ...common,
        Cell: ({ row, value }) => {
          const depth = row.depth;
          return (
            <div
              style={{
                marginLeft: `${depth * 20}px`,
                padding: "4px",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <span
                {...row.getToggleRowExpandedProps()} // Removed onMouseDown
                style={{
                  cursor: row.canExpand ? "pointer" : "default",
                  marginRight: "4px",
                }}
              >
                {row.canExpand ? (
                  row.isExpanded ? (
                    <span className={styles.expandIcon}>-</span>
                  ) : (
                    <span className={styles.expandIcon}>+</span>
                  )
                ) : (
                  ""
                )}
              </span>
              {value}
            </div>
          );
        },
      };
    }
  }, [viewOption, firstColWidth]);

  const otherColumns = useMemo(() => {
    const cols = [];
    if (viewOption === "title") {
      cols.push({ Header: "Item ID", accessor: "item_id" });
    }
    cols.push(
      {
        Header: "Zobrazení",
        accessor: "total_views",
        Cell: ({ value }) => new Intl.NumberFormat("cs-CZ").format(value),
      },
      {
        Header: "Kliknutí",
        accessor: "total_clicks",
        Cell: ({ value }) => new Intl.NumberFormat("cs-CZ").format(value),
      },
      {
        Header: "Nákupy",
        accessor: "total_purchases",
        Cell: ({ value }) => new Intl.NumberFormat("cs-CZ").format(value),
      },
      {
        Header: "Náklady",
        accessor: "total_cost",
        className: "lastColumn",
        minWidth: autoLastColumnWidth,
        width: autoLastColumnWidth,
        maxWidth: autoLastColumnWidth,
        Cell: ({ value }) =>
          `${formatNumber(Number(value).toFixed(2))} ${getCurrency(
            selectedShop.currency
          )}`,
      },
      {
        Header: "Příjem",
        accessor: "total_revenue",
        className: "lastColumn",
        minWidth: autoLastColumnWidth,
        width: autoLastColumnWidth,
        maxWidth: autoLastColumnWidth,
        Cell: ({ value }) =>
          `${formatNumber(Number(value).toFixed(2))} ${getCurrency(
            selectedShop.currency
          )}`,
      },
      {
        Header: "PNO",
        accessor: (row) => {
          const revenue = Number(row.total_revenue || 0);
          const cost = Number(row.total_cost || 0);
          return revenue === 0 ? 0 : (cost / revenue) * 100;
        },
        sortType: (rowA, rowB, columnId) =>
          rowA.values[columnId] - rowB.values[columnId],
        Cell: ({ value, row }) => {
          const revenue = Number(row.values.total_revenue || 0);
          if (revenue === 0) return "-";
          return `${formatNumber(Number(value).toFixed(2))} %`;
        },
      }
    );
    return cols;
  }, [viewOption, selectedShop, getCurrency, autoLastColumnWidth]);

  const columns = useMemo(
    () => [firstColumn, ...otherColumns],
    [firstColumn, otherColumns]
  );

  // Default column – ponecháme prázdné, aby nedocházelo k přepisování nastavení u sloupců
  const defaultColumn = useMemo(() => ({}), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination
  );

  // Helper function to prevent sorting when resizing
  const handleResizerMouseDown = (e, column) => {
    e.stopPropagation(); // Stop event from bubbling up
    column.getResizerProps().onMouseDown(e); // Trigger the actual resize
    setFirstColUserSet(true);
  };

  const rowsToRender =
    viewOption === "productType" || viewOption === "category" ? rows : page;
  const { key: tableKey, ...restTableProps } = getTableProps();
  const { key: tbodyKey, ...restTbodyProps } = getTableBodyProps();

  return (
    <>
      <div className={styles.toggleContainer}>
        <button
          className={`${styles.toggleButton} ${
            viewOption === "title" ? styles.active : ""
          }`}
          onClick={() => setViewOption("title")}
        >
          Název produktu
        </button>
        <button
          className={`${styles.toggleButton} ${
            viewOption === "brand" ? styles.active : ""
          }`}
          onClick={() => setViewOption("brand")}
        >
          Značka
        </button>
        <button
          className={`${styles.toggleButton} ${
            viewOption === "productType" ? styles.active : ""
          }`}
          onClick={() => setViewOption("productType")}
        >
          Kategorie
        </button>
      </div>
      <input
        type="text"
        placeholder="Vyhledávání..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />
      <div className={styles.tableContainer}>
        <table
          key={tableKey}
          {...restTableProps}
          className={styles.productsTable}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key: headerGroupKey, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={headerGroupKey} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column) => {
                    // Získáme resizer properties pro aktuální sloupec
                    const resizerProps = column.getResizerProps();

                    // Destrukturujeme props pro hlavičku
                    const { key: headerKey, ...restHeaderProps } =
                      column.getHeaderProps();

                    return (
                      // Použijeme explicitní key={column.id} a zbytek props bez headerKey
                      <th key={column.id} {...restHeaderProps}>
                        {/* Obalujeme jen obsah hlavičky řadícími props */}
                        <div
                          {...column.getSortByToggleProps()}
                          className={styles.headerContent}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " \u2191"
                                : " \u2193"
                              : ""}
                          </span>
                        </div>
                        {/* Resizer */}
                        <div
                          {...resizerProps}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            // Zavoláme původní onMouseDown z resizerProps
                            resizerProps.onMouseDown &&
                              resizerProps.onMouseDown(e);
                            setFirstColUserSet(true); // Zachováme tvou logiku pro šířku sloupce
                          }}
                          className={styles.resizer}
                        />
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody key={tbodyKey} {...restTbodyProps}>
            {rowsToRender.map((row) => {
              prepareRow(row);
              const {
                key: rowKey,
                style: rowStyleFromProps,
                ...restRowProps
              } = row.getRowProps();
              const customRowStyle =
                viewOption === "productType" || viewOption === "category"
                  ? {
                      backgroundColor: row.isExpanded
                        ? "#e6ffe6"
                        : getBackgroundColorForDepth(row.depth),
                      height: row.isExpanded
                        ? "auto"
                        : rowStyleFromProps?.height,
                    }
                  : {};
              return (
                <tr
                  key={rowKey}
                  {...restRowProps}
                  style={{ ...rowStyleFromProps, ...customRowStyle }}
                >
                  {row.cells.map((cell) => {
                    const { key: cellKey, ...restCellProps } =
                      cell.getCellProps();
                    return (
                      <td key={cellKey} {...restCellProps}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {(viewOption === "title" || viewOption === "brand") && (
        <div className={styles.paginationContainer}>
          <button
            className={`${styles.pageButton} ${styles.toEndBtn}`}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className={styles.pageButton}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>
          <span>
            {pageIndex + 1}&nbsp;/&nbsp;{pageOptions.length}
          </span>
          <button
            className={styles.pageButton}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>
          <button
            className={`${styles.pageButton} ${styles.toEndBtn}`}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className={styles.selectBox}
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default ProductsTable;
