import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { AppContext } from "../context/AppContext";

import he from "he";

import permoniqLogo from "../img/logo.svg";
import colorClock from "../img/icons/color-clock.svg";
import colorOnline from "../img/icons/color-online.svg";

import sendEvent from "../utils/functions/sendEvent";

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const HeroSection = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const { openModal, isLogged } = useContext(AppContext);

  return (
    <section className="section first-section">
      <article className="container">
        <div className="hero">
          <div
            className={`box box--back hero__1 ${
              isLogged ? "add_margin_top_40" : ""
            }`}
          >
            <h1>
              <img src={permoniqLogo} alt="PermoniQ" width="188" height="40" />
              <span
                dangerouslySetInnerHTML={renderHTML(t("hero.title"))}
              ></span>
            </h1>
            <p dangerouslySetInnerHTML={renderHTML(t("hero.description"))}></p>

            {!isLogged && (
              <div className="hero__cta">
                <p>
                  <a
                    onClick={() => {
                      sendEvent("click_try-free");
                    }}
                    href={`#${t("paths.tryFree")}`}
                    className="btn"
                    dangerouslySetInnerHTML={renderHTML(t("hero.ctaTryFree"))}
                  ></a>
                  <br />
                  <span
                    dangerouslySetInnerHTML={renderHTML(
                      t("hero.withoutObligations")
                    )}
                  ></span>
                </p>
                <p>
                  <a
                    href={`#${t("paths.consultation")}`}
                    className="btn btn--empty js-modal"
                    onClick={() => {
                      sendEvent("click_consultation");
                      openModal();
                    }}
                    dangerouslySetInnerHTML={renderHTML(t("hero.ctaKnowMore"))}
                  ></a>
                </p>
              </div>
            )}
          </div>

          <div className="box box--back is-connected hero__2">
            <span className="icon icon--color-clock">
              <ReactSVG
                src={colorClock}
                beforeInjection={(svg) => {
                  svg.setAttribute("width", "100%");
                }}
              />
            </span>
            <h2>
              <small
                dangerouslySetInnerHTML={renderHTML(t("hero.saving"))}
              ></small>
              <span
                dangerouslySetInnerHTML={renderHTML(t("hero.moneyAndTime"))}
              ></span>
            </h2>
            <p
              dangerouslySetInnerHTML={renderHTML(t("hero.box2Description"))}
            ></p>
          </div>

          <div className="box box--back is-connected hero__3">
            <span className="icon icon--color-online">
              <ReactSVG
                src={colorOnline}
                beforeInjection={(svg) => {
                  svg.setAttribute("width", "100%");
                }}
              />
            </span>
            <h2>
              <small
                dangerouslySetInnerHTML={renderHTML(
                  t("hero.effectiveAdvertising")
                )}
              ></small>
              <span
                dangerouslySetInnerHTML={renderHTML(t("hero.twentyFourSeven"))}
              ></span>
            </h2>
            <p
              dangerouslySetInnerHTML={renderHTML(t("hero.box3Description"))}
            ></p>
          </div>
        </div>
      </article>
    </section>
  );
};

export default HeroSection;
