import React, { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Helmet } from "react-helmet";
import SimpleAlert from "../../components/SimpleAlert";
import axiosInstance from "../../api/AxiosConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import sendEvent from "../../utils/functions/sendEvent";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const { lng } = useParams();

  useEffect(() => {
      navigate("/cs/");
    }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const otp = queryParams.get("otp");

  const { setIsUserDataLoading, isAlertVisible, alertMessage, closeAlert } =
    useContext(AppContext);

  const onVerifyOtp = async (otp) => {
    try {
      const response = await axiosInstance.post(`2fa/verify-email-otp/`, {
        otp: otp,
      });

      if (response.status === 200) {
        const { access, refresh } = response?.data;

        // Nastavení secure cookies s životností 7 dní
        Cookies.set("aTkn_0yD9K", access, {
          secure: true,
          sameSite: "None",
          expires: 1,
        });
        Cookies.set("rTkn_4zF7P", refresh, {
          secure: true,
          sameSite: "None",
          expires: 7,
        });
        sendEvent("login");
        window.scrollTo(0, 0);
        navigate(`/${lng}/app/dashboard/`);
      } else {
        window.scrollTo(0, 0);
        navigate(`/${lng}/login/`);
      }
    } catch (error) {
      console.log("Error sending OTP: ", error);
      Cookies.remove("aTkn_0yD9K");
      Cookies.remove("rTkn_4zF7P");
      window.scrollTo(0, 0);
      navigate(`/${lng}/login/`);
    }
  };

  useEffect(() => {
    onVerifyOtp(otp);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Ověření - PermoniQ</title>
      </Helmet>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <section className="section two_factor">
        <article className="container">
          <h1>Dvoufaktorové ověření</h1>
        </article>
      </section>
    </>
  );
};

export default VerifyOtp;
