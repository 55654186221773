import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import axiosInstance from "../api/AxiosConfig";
import styles from "../css/Users.module.css";
import ModalUsers from "../components/ModalUsers";
import { useNavigate } from "react-router-dom";
import { TbArrowsSort } from "react-icons/tb";

const Users = () => {
  const navigate = useNavigate();
  const { setIsUserDataLoading } = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("");

  // Nový stav pro řazení: "email" nebo "registration"
  const [sortOption, setSortOption] = useState("email");
  // Nový stav pro směr řazení: "asc" nebo "desc"
  const [sortDirection, setSortDirection] = useState("asc");

  // Klíče pro filtrování – nyní zahrnují i „nenastaven“
  const [selectedStages, setSelectedStages] = useState({
    nenastaven: true,
    0.1: true,
    10: true,
  });

  // Nový stav pro filtrování registrovaných uživatelů v posledním týdnu
  const [registeredLastWeek, setRegisteredLastWeek] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [shops, setShops] = useState([]);
  const [statusText, setStatusText] = useState("Načítám uživatele...");

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    users.forEach((user) => {
      console.log(user.registered_at);
    });
  }, [users]);

  // Filtrování dle emailu, hodnoty user_value, data registrace a následné řazení podle sortOption a sortDirection
  useEffect(() => {
    // Výpočet data před 7 dny (včetně normalizace na půlnoc pro konzistentní porovnání)
    const oneWeekAgo = new Date();
    oneWeekAgo.setHours(0, 0, 0, 0);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const filtered = users
      .filter((user) => {
        // --- Stávající filtry (email, stage) ---
        if (!user.email.toLowerCase().includes(filter.toLowerCase())) {
          return false;
        }
        const stage = getUserStage(user.user_value);
        if (!selectedStages[stage]) return false;
        // --- Konec stávajících filtrů ---

        // --- Nová logika pro filtr "Do 7 dnů" ---
        let registrationDate;
        try {
          registrationDate = new Date(user.registered_at);
          if (isNaN(registrationDate.getTime())) {
            console.warn(
              `Neplatné datum registrace pro uživatele ${user.email}: ${user.registered_at}`
            );
            return false;
          }
          registrationDate.setHours(0, 0, 0, 0);
        } catch (e) {
          console.warn(
            `Chyba při parsování data registrace pro uživatele ${user.email}: ${user.registered_at}`,
            e
          );
          return false;
        }

        const isWithinLastWeek = registrationDate >= oneWeekAgo;
        if (isWithinLastWeek !== registeredLastWeek) {
          return false;
        }
        // --- Konec nové logiky filtru ---

        return true;
      })
      .sort((a, b) => {
        let compareResult = 0;
        if (sortOption === "email") {
          compareResult = a.email.localeCompare(b.email);
        } else if (sortOption === "registration") {
          const dateA = new Date(a.registered_at);
          const dateB = new Date(b.registered_at);
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) return 0;
          compareResult = dateA - dateB;
        }
        return sortDirection === "asc" ? compareResult : -compareResult;
      });
    setFilteredUsers(filtered);
    if (
      users.length > 0 &&
      filtered.length === 0 &&
      statusText === "Načítám uživatele..."
    ) {
      setStatusText("Žádní uživatelé nevyhovují filtrům.");
    } else if (users.length === 0 && statusText === "Načítám uživatele...") {
      // Pokud se ještě nenahráli uživatelé, necháme původní text
    } else if (filtered.length > 0 && statusText !== "Načítám uživatele...") {
      // setStatusText("");
    }
  }, [
    filter,
    users,
    selectedStages,
    sortOption,
    sortDirection,
    registeredLastWeek,
    statusText,
  ]);

  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("users/");
      const sortedUsers = response.data.sort((a, b) =>
        a.email.localeCompare(b.email)
      );
      setUsers(sortedUsers);
    } catch (error) {
      console.log("Error getting users!", error);
      setStatusText("Nic jsem nenašel");
    } finally {
      setIsUserDataLoading(false);
    }
  };

  const getUserDetails = async (userId) => {
    setIsUserDataLoading(true);
    try {
      const response = await axiosInstance.get(`user/detail/`);
      setShops(response.data.shops || []);
    } catch (error) {
      console.log("Error getting user details!", error);
      setShops([]);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  const getUserStage = (value) => {
    if (value === 0) return "nenastaven";
    if (value === 0.1) return "0.1";
    if (value === 10) return "10";
    return "";
  };

  const stageToValue = (stage) => {
    switch (stage) {
      case "0.1":
        return 0.1;
      case "10":
        return 10;
      default:
        return 0;
    }
  };

  const onUpdateValue = async (id, newStage) => {
    setIsUserDataLoading(true);
    try {
      const numericValue = stageToValue(newStage);
      await axiosInstance.patch(`users/${id}/`, {
        user_value: numericValue,
        send_conversion_action: selectedUser?.gclid ? true : false,
      });
      setIsModalOpen(false);
      getUsers();
    } catch (error) {
      console.log("Error updating user value", error);
    } finally {
      setIsUserDataLoading(false);
      setUsers([]);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    getUserDetails(user.id);
    setIsModalOpen(true);
  };

  const formatDate = (dateStr) =>
    new Intl.DateTimeFormat("cs-CZ", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }).format(new Date(dateStr));

  const userShops = selectedUser
    ? shops.filter((shop) => shop.users.some((u) => u.id === selectedUser.id))
    : [];

  const handleStageChange = (e) => {
    const { value, checked } = e.target;
    setSelectedStages((prev) => ({
      ...prev,
      [value]: checked,
    }));
  };

  return (
    <>
      <section className={`${"need-mt"} ${styles.usersSection}`}>
        <h2 className={styles.title}>Vyberte uživatele</h2>
        <div className={styles.sumContainer}>
          <p>
            <strong>Celkem: </strong>
            {Object.keys(users).length}
          </p>
          <p>
            <strong>Zobrazeno: </strong>
            {Object.keys(filteredUsers).length}
          </p>
        </div>
        <div className={styles.sortAndSearchContainer}>
          <input
            type="text"
            placeholder="Vyhledávání"
            className={styles.searchInput}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <select
            className={styles.sortSelect}
            id="sortSelect"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="email">Řazení podle e-mailu</option>
            <option value="registration">Řazení podle data registrace</option>
          </select>
          <TbArrowsSort
            className={styles.sortIcon}
            onClick={() =>
              setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"))
            }
          />
        </div>
        <div className={styles.userListBox}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <div
                key={user.id}
                className={styles.userRow}
                onClick={() => handleUserClick(user)}
              >
                <div className={styles.nlBox}>
                  <span className={styles.userEmail}>{user.email}</span>
                  {user?.gclid && (
                    <span className={styles.gclidLabel}>Google Ads</span>
                  )}
                </div>
                {(user.user_value || user.user_value === 0) && (
                  <span className={styles.userValue}>
                    {getUserStage(user.user_value)}
                  </span>
                )}
              </div>
            ))
          ) : (
            <p className={styles.noUsers}>{statusText}</p>
          )}
        </div>
        <div className={styles.checkboxContainer}>
          {["nenastaven", "0.1", "10"].map((stageOption) => (
            <label
              key={stageOption}
              className={`checkbox ${styles.checkboxLabel}`}
            >
              <input
                type="checkbox"
                name="stage"
                value={stageOption}
                checked={selectedStages[stageOption]}
                onChange={handleStageChange}
              />
              {stageOption}
              <span className="checkbox__checker"></span>
            </label>
          ))}
          <label className={`checkbox ${styles.checkboxLabel}`}>
            <input
              type="checkbox"
              name="registeredLastWeek"
              checked={registeredLastWeek}
              onChange={(e) => setRegisteredLastWeek(e.target.checked)}
            />
            Do 7 dnů
            <span className="checkbox__checker"></span>
          </label>
        </div>
      </section>

      {isModalOpen && selectedUser && (
        <ModalUsers
          userId={selectedUser.id}
          title={selectedUser.email}
          userValue={selectedUser.user_value}
          userRegistered={selectedUser.registered_at}
          shops={userShops}
          onClose={() => setIsModalOpen(false)}
          onConfirm={onUpdateValue}
          confirmText=""
          cancelText="Zavřít"
          getUserStage={getUserStage}
          formatDate={formatDate}
          gclid={selectedUser.gclid}
        />
      )}
    </>
  );
};

export default Users;
