import React, { useEffect, useContext, useLayoutEffect } from "react";
import { RouterProvider } from "react-router-dom";

// 1. Importujte potřebné věci z React Query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AppContext } from "./context/AppContext";
import router from "./routes";

// 2. Vytvořte instanci QueryClient mimo komponentu App
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Příklad: Nastavení globálního staleTime na 5 minut
      // To znamená, že data budou považována za "čerstvá" 5 minut
      // a nebude se pro ně znovu odesílat request, pokud nejsou explicitně invalidována.
      staleTime: 5 * (60 * 1000),
      // Můžete přidat další globální nastavení zde
      // např. refetchOnWindowFocus: false,
    },
  },
});


const App = () => {
  const contextValue = useContext(AppContext);

  // --- Váš stávající kód (useEffect, useLayoutEffect, atd.) zůstává beze změny ---
  useEffect(() => {
    if (localStorage.getItem("userData") !== null) {
      localStorage.removeItem("userData");
    }
  }, []);

  const { setIsLogged, userData, isUserDataLoading, setIsUserDataLoading } =
    contextValue;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    if (!window.location.pathname.includes("login")) {
      setIsUserDataLoading(true);
    }
  }, []);

  if (sessionStorage.getItem("isLogged")) {
    setIsLogged(true);
  }

  useEffect(() => {
    // Váš kód pro content editing
     if (userData.email === "info@permoniq.com") {
       let isEditing = false;
       let originalContent = "";
       let currentElement = null;

       const handleAltClick = (event) => {
         // ... (vaše logika)
       };

       const handleEscKey = (event) => {
         // ... (vaše logika)
       };

       const handleBlur = () => {
          // ... (vaše logika)
       };

       document.addEventListener("click", handleAltClick);
       document.addEventListener("keydown", handleEscKey);
       document.addEventListener("blur", handleBlur, true);

       window.appContext = Object.freeze(contextValue);

       // Zvažte přidání cleanup funkce pro odstranění listenerů
       return () => {
          document.removeEventListener('click', handleAltClick);
          document.removeEventListener('keydown', handleEscKey);
          document.removeEventListener('blur', handleBlur, true);
       };
     }

  }, [userData.email, contextValue]);

  useEffect(() => {
    console.log(`version: ${process.env.REACT_APP_VERSION}\n`);
  }, []);
  // --- Konec vašeho stávajícího kódu ---


  // 3. Obalte RouterProvider pomocí QueryClientProvider
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {/* 4. Volitelně přidejte React Query DevTools (zobrazí se jen v development módu) */}
    </QueryClientProvider>
  );
};

export default App;