import React, { useContext, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { AppContext } from "../context/AppContext";
import sendEvent from "../utils/functions/sendEvent";

const AppHelp = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  const { setActiveItem, setAppContentType } = useContext(AppContext);

  useEffect(() => {
    sendEvent("app_help");
  }, []);

  const onRedirect = (where, isApp = true, anchor = "") => {
    // Přesměrování na kotvu, pokud je anchor zadán
    if (anchor) {
      navigate(`/${lng}/app/${where}/#${anchor}`);

      // Po navigaci přidej dodatečný posun o 20 pixelů po malé prodlevě
      setTimeout(() => {
        const anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          const elementPosition =
            anchorElement.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: elementPosition - 150,
            behavior: "smooth" // Plynulý scroll
          });
        }
      }, 100); // Delay 100ms, aby se kotva načetla
    } else {
      navigate(`/${lng}/app/${where}/`);
    }

    if (isApp) {
      setActiveItem(where);
      setAppContentType(where);
    }

    // Posun na vrchol stránky, pokud není zadána kotva
    if (!anchor) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <article className="app-content">
      <h1>{t("appHelp.title")}</h1>

      <section className="help-section">
        <h2>{t("appHelp.uploadProducts.title")}</h2>
        <p>
          <Trans
            i18nKey={t("appHelp.uploadProducts.description")}
            components={[
              <a
                className="link"
                onClick={() => onRedirect("settings", true, "xml-feed")}
              ></a>
            ]}
          />
        </p>
      </section>

      <section className="help-section">
        <h2 style={{ justifyContent: "left" }}>
          <Trans
            i18nKey={t("appHelp.dataplus.title")}
            components={[
              <a
                className="link"
                rel="noopener"
                target="_blank"
                href="https://dataplus.advisio.cz/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=app_help"
              ></a>
            ]}
          />
        </h2>
        <p>{t("appHelp.dataplus.description1")}</p>
        <p>
          <Trans
            i18nKey={t("appHelp.dataplus.description2")}
            components={[
              <a
                className="link"
                onClick={() => onRedirect("settings", true, "install-code")}
              ></a>
            ]}
          />
        </p>
      </section>

      <section className="help-section">
        <h2>{t("appHelp.adCredit.title")}</h2>
        <p>
          <Trans
            i18nKey={t("appHelp.adCredit.description")}
            components={[
              <a className="link" onClick={() => onRedirect("credit")}></a>
            ]}
          />
        </p>
      </section>

      {/* <section className="help-section">
                <h2>{t('appHelp.adFormats.title')}</h2>
                <p>
                    <Trans
                        i18nKey={t('appHelp.adFormats.description')}
                        components={[
                            <a
                                className='link'
                                onClick={() => onRedirect("campaigns")}>
                            </a>
                        ]}
                    />
                </p>
            </section> */}

      <section className="help-section">
        <h2>{t("appHelp.usefulSettings.title")}</h2>
        <h4>{t("appHelp.usefulSettings.maxPnoTitle")}</h4>
        <p>
          <Trans
            i18nKey={t("appHelp.usefulSettings.maxPnoDescription")}
            components={[
              <a
                className="link"
                onClick={() => onRedirect("settings", true, "pno")}
              ></a>
            ]}
          />
        </p>
      </section>

      <section className="help-section">
        <h2>{t("appHelp.performanceEvaluation.title")}</h2>
        <p>{t("appHelp.performanceEvaluation.description1")}</p>
        <p>
          <Trans
            i18nKey={t("appHelp.performanceEvaluation.description2")}
            components={[
              <a className="link" onClick={() => onRedirect("contact-us")}></a>
            ]}
          />
        </p>
      </section>
      <section className="help-section">
        <h2>{t("appHelp.needHelp.title")}</h2>
        <p>
          <Trans
            i18nKey={t("appHelp.needHelp.description")}
            components={[
              <a className="link" onClick={() => onRedirect("contact-us")}></a>
            ]}
          />
        </p>
      </section>
    </article>
  );
};

export default AppHelp;
