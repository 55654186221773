import axiosInstance from "../../api/AxiosConfig";

/**
 * Sends a message by making a POST request with the provided message object.
 *
 * @async
 * @function sendMessage
 * @param {Object} messageObject - The message data to be sent. Must be a non-empty object.
 * @throws {Error} Throws an error if `messageObject` is invalid or if the message sending fails.
 * @returns {Promise<Object>} Resolves with the response data from the server.
 *
 * @example
 * // Example usage:
 * const message = {
 *     recipient: "user123",
 *     content: "Hello, how are you?",
 *     timestamp: Date.now()
 * };
 *
 * sendMessage(message)
 *     .then(response => {
 *         console.log("Message sent successfully:", response);
 *     })
 *     .catch(error => {
 *         console.error("Error sending message:", error.message);
 *     });
 */
export const sendMessage = async (messageObject) => {
  if (
    !messageObject ||
    typeof messageObject !== "object" ||
    Object.keys(messageObject).length === 0
  ) {
    throw new Error('Invalid "messageObject": Must be a non-empty object.');
  }

  try {
    const response = await axiosInstance.post(`send-message/`, messageObject);
    return response.data;
  } catch (error) {
    console.error("Failed to send message: ", error.message || error);
    throw new Error("Failed to send the message. Please try again.");
  }
};

// V případě, že předmět obsahuje internal message, tak se neposílá zpráva klientovi.
