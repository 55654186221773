// import axios from "axios";
// import Cookies from "js-cookie";
// import { logout } from "../utils/functions/logout";

// const axiosInstance = axios.create({
//   // baseURL: process.env.NODE_ENV === "production" ? "https://permoniq.com/api/" : "http://192.168.88.125:8002/api/",
//   baseURL: "https://permoniq.com/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // -----------------------
// // LocalStorage Helpers
// // -----------------------

// function getUserDetailFromStorage() {
//   return new Promise((resolve, reject) => {
//     try {
//       const storedData = localStorage.getItem("userData");
//       resolve(storedData ? JSON.parse(storedData) : null);
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// function setUserDetailInStorage(data) {
//   return new Promise((resolve, reject) => {
//     try {
//       localStorage.setItem("userData", JSON.stringify(data));
//       resolve();
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// function clearUserDetailStorage() {
//   return new Promise((resolve, reject) => {
//     try {
//       localStorage.removeItem("userData");
//       resolve();
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// // -----------------------
// // Axios Interceptors
// // -----------------------

// axiosInstance.interceptors.request.use(
//   async (config) => {
//     const token = Cookies.get("aTkn_0yD9K");
//     const refToken = Cookies.get("rTkn_4zF7P");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     } else if (refToken) {
//       try {
//         // Při obnově tokenu také vymažeme cache
//         await clearUserDetailStorage();
//         Cookies.remove("userDataTimestamp");
//         const response = await fetch(
//           "https://permoniq.com/api/token/refresh/",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ refresh: refToken }),
//           }
//         );

//         if (!response.ok) {
//           throw new Error("Failed to refresh access token");
//         }

//         const data = await response.json();
//         Cookies.set("aTkn_0yD9K", data.access, {
//           secure: true,
//           sameSite: "None",
//           expires: 1,
//         });
//         config.headers.Authorization = `Bearer ${data.access}`;
//       } catch (error) {
//         console.error("Token refresh failed:", error);
//         return null;
//       }
//     } else {
//       const lng = Cookies.get("i18next") || "cs";
//       window.location.href = `/${lng}/login/`;
//       setTimeout(() => {
//         const messages = {
//           cs: "Došlo k chybě, zkuste se znovu přihlásit!",
//           en: "An error occurred, try to login again!",
//           pl: "Wystąpił błąd, spróbuj ponownie się zalogować!",
//           hu: "Hiba történt, próbáljon újra bejelentkezni!",
//           sk: "Došlo k chybe, skúste sa znova prihlásiť!",
//           ro: "A apărut o eroare, încercați să vă conectați din nou!",
//         };
//         alert(messages[lng] || messages.en);
//       }, 500);
//     }

//     // Pokud jde o GET požadavek na 'user/detail/', zkontrolujeme cache a její čas platnosti.
//     if (config.method === "get" && config.url.includes("user/detail/")) {
//       const cachedData = await getUserDetailFromStorage();
//       const cachedTimestamp = Cookies.get("userDataTimestamp");

//       if (cachedData && cachedTimestamp) {
//         const timestamp = parseInt(cachedTimestamp, 10);
//         const now = Date.now();
//         const threeHours = 3 * 60 * 60 * 1000; // 3 hodiny v ms

//         if (now - timestamp < threeHours) {
//           // Data nejsou starší než 3 hodiny – použijeme cache
//           return Promise.reject({
//             isCached: true,
//             cachedData,
//           });
//         } else {
//           // Data jsou starší než 3 hodiny – vymažeme cache
//           await clearUserDetailStorage();
//           Cookies.remove("userDataTimestamp");
//         }
//       } else if (cachedData && !cachedTimestamp) {
//         // Pokud máme data, ale není uložen timestamp, považujeme je za neplatná a vymažeme cache.
//         await clearUserDetailStorage();
//       }
//     }

//     // Při požadavcích, které mění data, vymažeme cache a odstraníme i timestamp.
//     if (
//       (config.method === "post" ||
//         config.method === "patch" ||
//         config.method === "put") &&
//       config.url.includes("update")
//     ) {
//       await clearUserDetailStorage();
//       Cookies.remove("userDataTimestamp");
//     } else if (
//       config.method === "post" &&
//       config.url.includes("shop/create/")
//     ) {
//       await clearUserDetailStorage();
//       Cookies.remove("userDataTimestamp");
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   async (response) => {
//     // Při úspěšném GET požadavku na 'user/detail/' uložíme data do cache
//     // a nastavíme timestamp do cookies.
//     if (
//       response.config.method === "get" &&
//       response.config.url.includes("user/detail/")
//     ) {
//       await setUserDetailInStorage(response.data);
//       Cookies.set("userDataTimestamp", Date.now().toString(), {
//         secure: true,
//         sameSite: "None",
//       });
//     }
//     return response;
//   },
//   (error) => {
//     if (error.isCached) {
//       return Promise.resolve({ data: error.cachedData });
//     }

//     if (error.status === 403) {
//       logout();
//     }
//     return Promise.reject(error);
//   }
// );

// export { clearUserDetailStorage };
// export default axiosInstance;












import axios from "axios";
import Cookies from "js-cookie";
import { logout } from "../utils/functions/logout";

// -----------------------
// Web Crypto API Helper Functions
// -----------------------

// Převod ArrayBuffer na hexadecimální řetězec
function buf2hex(buffer) {
  return Array.from(new Uint8Array(buffer))
    .map(b => ("00" + b.toString(16)).slice(-2))
    .join("");
}

// Převod hexadecimálního řetězce na ArrayBuffer
function hex2buf(hex) {
  const bytes = new Uint8Array(
    hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16))
  );
  return bytes.buffer;
}

// Odvození šifrovacího klíče pomocí PBKDF2 (kombinace hesla a salt)
async function deriveKey(password, salt) {
  const enc = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    enc.encode(password),
    "PBKDF2",
    false,
    ["deriveKey"]
  );

  return crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: enc.encode(salt),
      iterations: 100000,
      hash: "SHA-256"
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
}

// Zašifruje text pomocí AES-GCM s klíčem odvozeným z celého access tokenu a aktuálního data.
// Výsledek je řetězec ve formátu "IV:encryptedData:YYYY-MM-DD"
async function encryptData(plainText) {
  const currentDate = new Date().toISOString().slice(0, 10); // např. "2025-03-28"
  const accessToken = Cookies.get("aTkn_0yD9K");
  if (!accessToken) return null; // pokud není token, vrátíme null
  const password = accessToken; // použijeme celý token
  const salt = currentDate; // datum použijeme jako salt
  const key = await deriveKey(password, salt);
  const enc = new TextEncoder();
  const iv = crypto.getRandomValues(new Uint8Array(12)); // IV pro AES-GCM

  const encryptedBuffer = await crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    key,
    enc.encode(plainText)
  );

  // Formát: IV:encryptedData:datum
  return `${buf2hex(iv)}:${buf2hex(encryptedBuffer)}:${currentDate}`;
}

// Dešifruje text ve formátu "IV:encryptedData:YYYY-MM-DD"
async function decryptData(cipherText) {
  const parts = cipherText.split(":");
  if (parts.length !== 3) throw new Error("Neplatný formát šifrovaných dat.");
  const [ivHex, dataHex, dateStr] = parts;
  const accessToken = Cookies.get("aTkn_0yD9K");
  if (!accessToken) return null; // pokud není token, vrátíme null
  const password = accessToken; // použijeme celý token
  const salt = dateStr;
  const key = await deriveKey(password, salt);
  const iv = new Uint8Array(hex2buf(ivHex));
  const encryptedBuffer = hex2buf(dataHex);

  const decryptedBuffer = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    key,
    encryptedBuffer
  );
  const dec = new TextDecoder();
  return dec.decode(decryptedBuffer);
}

// -----------------------
// LocalStorage Helpers se šifrováním
// -----------------------

// Použijeme obfuskovaný název klíče
const STORAGE_KEY = "x12tyj98";

async function getUserDetailFromStorage() {
  try {
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (!storedData) return null;
    const decrypted = await decryptData(storedData);
    // Pokud dešifrování selže (např. vrátí null), vymažeme cache a vrátíme null
    if (!decrypted) {
      await clearUserDetailStorage();
      return null;
    }
    return JSON.parse(decrypted);
  } catch (error) {
    await clearUserDetailStorage();
    return null;
  }
}

async function setUserDetailInStorage(data) {
  try {
    const encrypted = await encryptData(JSON.stringify(data));
    // Pokud šifrování neproběhne kvůli chybě (např. chybějící token), cache vymažeme
    if (!encrypted) {
      await clearUserDetailStorage();
      return;
    }
    localStorage.setItem(STORAGE_KEY, encrypted);
  } catch (error) {
    return Promise.reject(error);
  }
}

function clearUserDetailStorage() {
  return new Promise((resolve, reject) => {
    try {
      localStorage.removeItem(STORAGE_KEY);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

// -----------------------
// Axios Instance & Interceptors
// -----------------------

const axiosInstance = axios.create({
  // baseURL: process.env.NODE_ENV === "production" ? "https://permoniq.com/api/" : "http://192.168.88.125:8002/api/",
  baseURL: "https://permoniq.com/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("aTkn_0yD9K");
    const refToken = Cookies.get("rTkn_4zF7P");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (refToken) {
      try {
        await clearUserDetailStorage();
        Cookies.remove("userDataTimestamp");
        const response = await fetch("https://permoniq.com/api/token/refresh/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh: refToken }),
        });

        if (!response.ok) throw new Error();

        const data = await response.json();
        Cookies.set("aTkn_0yD9K", data.access, {
          secure: true,
          sameSite: "None",
          expires: 1,
        });
        config.headers.Authorization = `Bearer ${data.access}`;
      } catch (error) {
        return null;
      }
    } else {
      const lng = Cookies.get("i18next") || "cs";
      window.location.href = `/${lng}/login/`;
      setTimeout(() => {
        const messages = {
          cs: "Došlo k chybě, zkuste se znovu přihlásit!",
          en: "An error occurred, try to login again!",
          pl: "Wystąpił błąd, spróbuj ponownie się zalogować!",
          hu: "Hiba történt, próbáljon újra bejelentkezni!",
          sk: "Došlo k chybe, skúste sa znova prihlásiť!",
          ro: "A apărut o eroare, încercați să vă conectați din nou!",
        };
        alert(messages[lng] || messages.en);
      }, 500);
    }

    // Kontrola cache pro GET požadavek na 'user/detail/'
    if (config.method === "get" && config.url.includes("user/detail/")) {
      const cachedData = await getUserDetailFromStorage();
      const cachedTimestamp = Cookies.get("userDataTimestamp");

      if (cachedData && cachedTimestamp) {
        const timestamp = parseInt(cachedTimestamp, 10);
        const now = Date.now();
        const threeHours = 3 * 60 * 60 * 1000;
        if (now - timestamp < threeHours) {
          return Promise.reject({
            isCached: true,
            cachedData,
          });
        } else {
          await clearUserDetailStorage();
          Cookies.remove("userDataTimestamp");
        }
      } else if (cachedData && !cachedTimestamp) {
        await clearUserDetailStorage();
      }
    }

    // Při požadavcích měnících data vymažeme cache a timestamp
    if (
      (config.method === "post" ||
        config.method === "patch" ||
        config.method === "put") &&
      config.url.includes("update")
    ) {
      await clearUserDetailStorage();
      Cookies.remove("userDataTimestamp");
    } else if (config.method === "post" && config.url.includes("shop/create/")) {
      await clearUserDetailStorage();
      Cookies.remove("userDataTimestamp");
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  async (response) => {
    if (
      response.config.method === "get" &&
      response.config.url.includes("user/detail/")
    ) {
      await setUserDetailInStorage(response.data);
      Cookies.set("userDataTimestamp", Date.now().toString(), {
        secure: true,
        sameSite: "None",
      });
    }
    return response;
  },
  (error) => {
    if (error.isCached) {
      return Promise.resolve({ data: error.cachedData });
    }
    if (error.status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);

export { clearUserDetailStorage };
export default axiosInstance;

