import React from "react";
import { ReactComponent as CloseIcon } from "../img/icons/close.svg";
import styles from "../css/CloseBtn.module.css";

const CloseBtn = ({ onClick }) => {
  return (
    <button
      className={styles.close_btn}
      onClick={onClick}
      aria-label="Zavřít"
      type="button"
    >
      <CloseIcon />
    </button>
  );
};

export default CloseBtn;
