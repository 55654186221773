// In: ../../utils/axios_functions/2ea/generateEmailOpt.js
import axiosInstance from "../../../api/AxiosConfig"; // Ujistěte se, že cesta je správná

export const generateEmailOtp = async () => {
  try {
    // Počkáme na odpověď od API
    const response = await axiosInstance.post(`2fa/generate-email-otp/`);
    // Vracíme úspěšnou odpověď (nebo jen true, pokud data nepotřebujeme)
    return response;
  } catch (error) {
    // Logujeme chybu
    console.error("Error generating e-mail in utility function: ", error);
    // Vyhodíme chybu dál, aby ji mohl zachytit volající (komponenta)
    throw error;
  }
};
