const short = {
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
};

const long = {
  day: "numeric",
  month: "long",
  year: "numeric",
};

export const formatDate = (lng, date, type = "short") => {
  let d;

  // 1. Pokud už je to instance Date, použijte ji přímo.
  if (date instanceof Date) {
    d = date;
  } else {
    // 2. Pokud je to řetězec (případně i číslo), vytvořte nový Date.
    d = new Date(date);
  }

  // 3. Zkontrolujte, zda je `d` platný datum.
  if (isNaN(d.getTime())) {
    console.error("Invalid date:", date);
    return "";
  }

  // 4. Až teď formátujte pomocí Intl.DateTimeFormat.
  const short = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  };

  const long = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return Intl.DateTimeFormat(lng, {
    day: type === "short" ? short.day : long.day,
    month: type === "short" ? short.month : long.month,
    year: type === "short" ? short.year : long.year,
  }).format(d);
};
