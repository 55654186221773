import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { AppContext } from "../context/AppContext";

import Progressbar from "./progressBars/ProgressBar";

import useUserAppDetails from "../hooks/useUserAppDetails";

import Cookies from "js-cookie";

import csFlag from "../img/flags/cs.png";
import enFlag from "../img/flags/en.png";
import skFlag from "../img/flags/sk.png";
import plFlag from "../img/flags/pl.png";
import huFlag from "../img/flags/hu.png";
import roFlag from "../img/flags/ro.png";
import plusIcon from "../img/icons/plus.svg";
import loginIcon from "../img/icons/login.svg";
import inverseLogo from "../img/logo-inverse.svg";

import { TbLogout2 } from "react-icons/tb";

import { clearUserDetailStorage } from "../api/AxiosConfig";
import deleteAllDatabases from "../utils/functions/deleteAllDatabases";

const flags = {
  cs: csFlag,
  en: enFlag,
  sk: skFlag,
  pl: plFlag,
  hu: huFlag,
  ro: roFlag,
};

const NavBarApp = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLanguageSwitchVisible, setIsLanguageSwitchVisible] = useState(false);
  const languageRef = useRef(null);
  const logoutRef = useRef(null);
  const accountButtonRef = useRef(null);
  const addShopRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(lng || "cs");
  const [showLogout, setShowLogout] = useState(false);

  const {
    userData,
    setUserData,
    selectedShop,
    setSelectedShop,
    deleteAll,
    setVisitedInstallTrackingCode,
    appContentType,
    showArrow,
    setIsUserDataLoading,
    shopStatus,
    showAdminModal,
    setShowAdminModal,
  } = useContext(AppContext);

  useUserAppDetails();

  // Vypočítáme počet zbývajících dní do trial_end_date.
  const daysRemaining = useMemo(() => {
    if (!selectedShop?.trial_end_date) return 0;
    const trialEndDate = new Date(selectedShop.trial_end_date);
    const today = new Date();
    const diff = trialEndDate.getTime() - today.getTime();
    return diff > 0 ? Math.ceil(diff / (1000 * 60 * 60 * 24)) : 0;
  }, [selectedShop]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fi = sessionStorage.getItem("fi_1");

    if (selectedShop && selectedShop.status_create !== "end" && !fi) {
      // setIsUserDataLoading(true)
      console.log("shop was not done");
      navigate(`/${lng}/app/register-shop/`);
      window.scrollTo(0, 0);
    } else {
      sessionStorage.removeItem("fi_1");
    }
  }, [selectedShop]);

  useEffect(() => {
    if (showArrow) {
      addShopRef.current.classList.add("add_shop_current_border");
    } else {
      addShopRef.current.classList.remove("add_shop_current_border");
    }
  }, [showArrow]);

  useEffect(() => {
    if (shopStatus) {
      setSelectedShop(selectedShop);
    }
  }, [shopStatus]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language.toLowerCase());
    setSelectedLanguage(language.toLowerCase());
    setIsLanguageSwitchVisible(false);
    navigate(`/${language.toLowerCase()}/app/${appContentType}/`);
  };

  const toggleLanguageSwitch = (e) => {
    e.preventDefault();
    setIsLanguageSwitchVisible(!isLanguageSwitchVisible);
  };

  const handleClickOutside = (event) => {
    // Přidáme kontrolu, zda bylo kliknuto mimo logout popup a tlačítko „můj účet“
    if (
      logoutRef.current &&
      !logoutRef.current.contains(event.target) &&
      accountButtonRef.current &&
      !accountButtonRef.current.contains(event.target)
    ) {
      setShowLogout(false);
    }

    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setIsLanguageSwitchVisible(false);
    }
  };

  // const showSelected = (e) => {
  //   let index = e.target.selectedIndex;
  //   setSelectedShop(userData.shops[index]);
  // };

  const changeVisitedInstallTrackingState = () => {
    setVisitedInstallTrackingCode(false);
  };

  const handleShopChange = (event) => {
    const selectedShopUuid = event.target.value; // Získání hodnoty (uuid) vybraného obchodu
    const selectedShop = userData?.shops?.find(
      (shop) => shop.uuid === selectedShopUuid
    ); // Najdi obchod podle uuid
    setSelectedShop(selectedShop); // Aktualizuj stav
    sessionStorage.setItem("x23uU09I098D", selectedShop.uuid);
    if (selectedShop.status_create !== "end") {
      navigate(`/${lng}/app/register-shop/`);
      window.scrollTo(0, 0);
    }
  };

  // Funkce pro přepnutí viditelnosti okénka
  const handleToggleLogout = () => {
    setShowLogout(!showLogout);
  };

  const onLogout = () => {
    setIsUserDataLoading(true);
    Cookies.remove("rTkn_4zF7P");
    Cookies.remove("aTkn_0yD9K");
    Cookies.remove("utk_98x76");
    clearUserDetailStorage();
    // deleteAllDatabases()
    navigate(`/${lng}/login/`);
    window.location.reload(true);
  };

  const removeProtocol = (url) => {
    return url.replace(/^https?:\/\/(www\.)?/, ""); // Odstraní "http://", "https://" a "www."
  };

  const getShopStatus = (oneShop) => {
    if (oneShop.end_date) return "Ukončeno";
    if (oneShop.status_create !== "end") return "Registrace";
    const pmaxCampaign = oneShop?.campaign.find((oneCampaign) =>
      oneCampaign.campaign_type.toLowerCase().includes("pmax")
    );
    if (pmaxCampaign) {
      if (pmaxCampaign.campaign_status === "active") return "Aktivní";
      else if (pmaxCampaign.campaign_status === "paused") return "Pozastaveno";
      else return "Neaktivní";
    }
    return "Neaktivní";
  };

  return (
    <header className="header app-navbar">
      <div className="container">
        <div className="header__logo">
          <Link to={`/${lng}/app/dashboard/`}>
            <img src={inverseLogo} alt="PermoniQ" width="151" height="32" />
          </Link>
        </div>
        <nav className="app-nav-header">
          <div className="select-main">
            <select
              id="eshop"
              value={selectedShop?.uuid || userData?.shops?.[0]?.uuid}
              onChange={handleShopChange}
            >
              {userData?.shops
                ?.filter(
                  (oneShop) =>
                    selectedShop?.uuid === oneShop.uuid || !oneShop.end_date
                )
                ?.sort((a, b) => {
                  const websiteA = removeProtocol(a.website).toLowerCase();
                  const websiteB = removeProtocol(b.website).toLowerCase();
                  return websiteA.localeCompare(websiteB);
                })
                .map((oneShop) => {
                  const baseStatus = getShopStatus(oneShop);
                  const status = baseStatus;
                  let backgroundColor;
                  switch (status) {
                    case "Aktivní":
                      backgroundColor = "#d4edda";
                      break;
                    case "Ukončeno":
                      backgroundColor = "#FFC4C4";
                      break;
                    case "Neaktivní":
                      backgroundColor = "#f8d7da";
                      break;
                    case "Registrace":
                      backgroundColor = "#fff";
                      break;
                    default:
                      backgroundColor = "#fff";
                  }
                  return (
                    <option
                      key={oneShop.uuid}
                      value={oneShop.uuid}
                      title={removeProtocol(oneShop.website)}
                      style={{ backgroundColor }}
                    >
                      {removeProtocol(oneShop.website).length > 20
                        ? removeProtocol(oneShop.website).substring(0, 20) +
                          "..."
                        : removeProtocol(oneShop.website)}
                      {" (" + status + ")"}
                    </option>
                  );
                })}
            </select>

            {/* {selectedShop ? (
              <select
                name="eshop"
                id="eshop"
                value={selectedShop.name}
                onChange={(e) => {
                  setEshop(e.target.value);
                  showSelected(e);
                  deleteAll();
                }}
              >
                {shops.length > 0 &&
                  shops[0].map((oneShop) => {
                    return (
                      <option key={oneShop.uuid} value={oneShop.name}>
                        {oneShop.name}
                      </option>
                    );
                  })}
              </select>
            ) : (
              <select
                name="eshop"
                id="eshop"
                value={eshop}
                onChange={(e) => {
                  setEshop(e.target.value);
                  showSelected(e);
                  deleteAll();
                }}
              >
                {shops.length > 0 &&
                  shops[0]?.map((oneShop) => {
                    return (
                      <option key={oneShop.uuid} value={oneShop.name}>
                        {oneShop.name}
                      </option>
                    );
                  })}
              </select>
            )} */}
          </div>

          <ul>
            <li className="add-shop-wrapper" ref={addShopRef}>
              <Link
                to={
                  userData?.email === "info@permoniq.com"
                    ? `/${lng.toLowerCase()}/app/shop-option/`
                    : `/${lng.toLowerCase()}/app/register-shop/`
                }
                onClick={() => changeVisitedInstallTrackingState()}
              >
                <span className="icon icon--plus">
                  <ReactSVG src={plusIcon} className="icon__svg" />
                </span>
                {t("addShop")}
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="my-account-wrapper"
                onClick={() => handleToggleLogout()}
                ref={accountButtonRef}
              >
                <span className="icon icon--login">
                  <ReactSVG src={loginIcon} className="icon__svg" />
                </span>
                {t("myAccount")}
              </Link>

              {/* Zobrazení odhlašovacího okénka na základě stavu */}
              {showLogout && (
                <div className="logout-popup" ref={logoutRef}>
                  <p className="logout-name">{userData.email}</p>
                  {userData.email === "info@permoniq.com" && (
                    <>
                      <button
                        className="logout-btn"
                        onClick={() => {
                          navigate(`/${lng.toLowerCase()}/app/shop-option/`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Výběr obchodu
                      </button>
                      <button
                        className="logout-btn"
                        onClick={() => {
                          setShowAdminModal(!showAdminModal);
                        }}
                      >
                        Admin
                      </button>
                    </>
                  )}
                  <button className="logout-btn" onClick={() => onLogout()}>
                    Odhlásit
                  </button>
                  {daysRemaining > 0 && (
                    <Progressbar
                      value={daysRemaining}
                      colorAbove="#22e06c"
                      colorBelow="#ffda44"
                      place="nav"
                      title={"Pracuji zdarma"}
                    >
                      ještě{" "}
                      {daysRemaining > 1
                        ? `${daysRemaining} dní`
                        : `${daysRemaining} den`}
                    </Progressbar>
                  )}
                </div>
              )}
            </li>
          </ul>
          {/* <div className="language" ref={languageRef}>
            <a href="" onClick={toggleLanguageSwitch}>
              <img
                src={flags[selectedLanguage]}
                alt={t(`loginLanguagesAppNavBar.${selectedLanguage}`)}
              />
            </a>
            <div className={`language__switch ${isLanguageSwitchVisible ? 'is-visible' : ''}`}>
              {Object.keys(flags).map((lang) => (
                <a href="" onClick={() => handleLanguageChange(lang.toLowerCase())} key={lang}>
                  <img
                    src={flags[lang.toLowerCase()]}
                    alt={t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)}
                  />{' '}
                  {t(`loginLanguagesAppNavBar.${lang.toLowerCase()}`)}
                </a>
              ))}
            </div>
          </div> */}
        </nav>
      </div>
    </header>
  );
};

export default NavBarApp;
