import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import he from "he";

import { AppContext } from "../context/AppContext";

import sendEvent from "../utils/functions/sendEvent";

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const ComparisonSection = () => {
  const { t } = useTranslation();

  const { isLogged } = useContext(AppContext);

  return (
    <section className="section" id={t("comparison.id")}>
      <article className="container">
        <h2
          className="title"
          dangerouslySetInnerHTML={renderHTML(t("comparison.title"))}
        ></h2>
        <div className="pricelist">
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>
                  <span
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.headers.permoniq")
                    )}
                  ></span>
                </th>
                <th
                  dangerouslySetInnerHTML={renderHTML(
                    t("comparison.headers.specialist")
                  )}
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  dangerouslySetInnerHTML={renderHTML(
                    t("comparison.rows.workingHours.feature")
                  )}
                ></td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.workingHours.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.workingHours.permoniq")
                      )}
                    ></strong>
                  </span>
                </td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.workingHours.feature")
                    )}
                  ></em>
                  <strong
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.workingHours.specialist")
                    )}
                  ></strong>
                </td>
              </tr>
              <tr>
                <td
                  dangerouslySetInnerHTML={renderHTML(
                    t("comparison.rows.monthlyCost.feature")
                  )}
                ></td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.monthlyCost.feature")
                    )}
                  ></em>
                  <span
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.monthlyCost.permoniq")
                    )}
                  ></span>
                </td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.monthlyCost.feature")
                    )}
                  ></em>
                  <span
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.monthlyCost.specialist")
                    )}
                  ></span>
                </td>
              </tr>
              <tr>
                <td
                  dangerouslySetInnerHTML={renderHTML(
                    t("comparison.rows.trainingCost.feature")
                  )}
                ></td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.trainingCost.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.trainingCost.permoniq")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.trainingCost.permoniqDetail")
                      )}
                    ></span>
                  </span>
                </td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.trainingCost.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.trainingCost.specialist")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.trainingCost.specialistDetail")
                      )}
                    ></span>
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  dangerouslySetInnerHTML={renderHTML(
                    t("comparison.rows.reliability.feature")
                  )}
                ></td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.reliability.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.reliability.permoniq")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.reliability.permoniqDetail")
                      )}
                    ></span>
                  </span>
                </td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.reliability.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.reliability.specialist")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.reliability.specialistDetail")
                      )}
                    ></span>
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  dangerouslySetInnerHTML={renderHTML(
                    t("comparison.rows.performance.feature")
                  )}
                ></td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.performance.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.performance.permoniq")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.performance.permoniqDetail")
                      )}
                    ></span>
                  </span>
                </td>
                <td>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.performance.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.performance.specialist")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.performance.specialistDetail")
                      )}
                    ></span>
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  dangerouslySetInnerHTML={renderHTML(
                    t("comparison.rows.commitment.feature")
                  )}
                ></td>
                <td className={`${isLogged && "pricelist__last"}`}>
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.commitment.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.commitment.permoniq")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.commitment.permoniqDetail")
                      )}
                    ></span>
                  </span>
                </td>
                <td className="pricelist__last">
                  <em
                    dangerouslySetInnerHTML={renderHTML(
                      t("comparison.rows.commitment.feature")
                    )}
                  ></em>
                  <span>
                    <strong
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.commitment.specialist")
                      )}
                    ></strong>
                    <br />
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.rows.commitment.specialistDetail")
                      )}
                    ></span>
                  </span>
                </td>
              </tr>
            </tbody>
            {!isLogged && (
              <tfoot>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <a
                      onClick={() => {
                        sendEvent("click_try-free");
                      }}
                      href={`#${t("paths.tryFree")}`}
                      className="btn"
                      dangerouslySetInnerHTML={renderHTML(
                        t("comparison.ctaJoinTeam")
                      )}
                    ></a>
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </article>
    </section>
  );
};

export default ComparisonSection;
