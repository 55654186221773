import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import formatNumber from "../utils/functions/formatNumber"; // Předpokládáme existenci a funkčnost

import {
  LineChart,
  // ComposedChart, // Nebylo v původním kódu použito aktivně
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  // BarChart, // Nebylo v původním kódu použito aktivně
  // Bar, // Nebylo v původním kódu použito aktivně
} from "recharts";

import { AppContext } from "../context/AppContext";

import { FaGear } from "react-icons/fa6"; // Bylo v původním kódu, ponecháno

// import { checkBilling } from "../utils/axios_functions/checkBiling"; // Nebylo v původním kódu aktivní

import axiosInstance from "../api/AxiosConfig";

import SimpleAlert from "./SimpleAlert";
import ModalInfo from "./ModalInfo";
import ModalWelcome from "./ModalWelcome";

import dangerIcon from "../img/icons/danger.svg"; // Bylo v původním kódu (v komentáři), ponecháno
import googleIcon from "../img/login-google.svg";
import userIcon from "../img/icons/user.svg"; // Bylo v původním kódu (v komentáři), ponecháno
import basketIcon from "../img/icons/basket.svg";
import documentIcon from "../img/icons/document.svg"; // Bylo v původním kódu (v komentáři), ponecháno
import productsIcon from "../img/icons/products.svg"; // Bylo v původním kódu (v komentáři), ponecháno
import indicatorIcon from "../img/icons/indicator.svg"; // Bylo v původním kódu (v komentáři), ponecháno
import growIcon from "../img/icons/grow.svg"; // Ponecháno, i když bylo později nahrazeno target
import clickIcon from "../img/icons/click.svg";
import turnonIcon from "../img/icons/turnon.svg";
import thumbIcon from "../img/icons/thumb.svg";
import feedIcon from "../img/icons/feed.svg";
import clockIcon from "../img/icons/clock.svg";
import { ReactSVG } from "react-svg"; // Ponecháno pro ikony
import tooltipInfoIcon from "../img/tooltip.svg"; // Bylo v původním kódu, ponecháno
import target from "../img/icons/target.svg"; // Ponecháno, i když přišlo později

import useUserAppDetails from "../hooks/useUserAppDetails";

import styles from "../css/AppDashboard.module.css";
import styleForInfoModal from "../css/ModalInfo.module.css";

import CapmaignStatusBar from "./progressBars/CampaignStatusBar"; // Název ponechán, jak byl

import sendEvent from "../utils/functions/sendEvent";
import { formatDate } from "../utils/functions/formatDate";

const AppDashboard = () => {
  const navigate = useNavigate();
  const { lng } = useParams();
  const { t } = useTranslation();

  const keyPointsOfLearningFaseTitle = t(
    "appDashboard.keyPointsOfLearningFaseTitle",
    { returnObjects: true }
  );

  // Stavy pro obchod a modály - PŮVODNÍ
  const [shopForDisplay, setShopForDisplay] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showHowToStart, setShowHowToStart] = useState(false);

  // Stavy pro statistiky (7 dní) - PŮVODNÍ struktura, hodnoty budou čísla
  const [totalExpenses, setTotalExpenses] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [cos, setCos] = useState({ value: 0, type: "Percent" });
  const [generatedRevenue, setGeneratedRevenue] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [assistedRevenue, setAssistedRevenue] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [visitorCount, setVisitorCount] = useState({
    value: 0,
    type: "Number",
  });
  const [clickCount, setClickCount] = useState({ value: 0, type: "Number" });
  const [orderCount, setOrderCount] = useState({ value: 0, type: "Number" });
  const [conversionRatio, setConversionRatio] = useState({
    value: 0,
    type: "Percent",
  });

  // Stavy pro statistiky (30 dní) - PŮVODNÍ struktura, hodnoty budou čísla
  const [totalExpenses30, setTotalExpenses30] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [cos30, setCos30] = useState({ value: 0, type: "Percent" });
  const [generatedRevenue30, setGeneratedRevenue30] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [assistedRevenue30, setAssistedRevenue30] = useState({
    value: 0,
    currency: "CZK",
    type: "Money",
  });
  const [visitorCount30, setVisitorCount30] = useState({
    value: 0,
    type: "Number",
  });
  const [clickCount30, setClickCount30] = useState({
    value: 0,
    type: "Number",
  });
  const [orderCount30, setOrderCount30] = useState({
    value: 0,
    type: "Number",
  });
  const [conversionRatio30, setConversionRatio30] = useState({
    value: 0,
    type: "Percent",
  });

  // Stav pro denní statistiky (graf) - PŮVODNÍ
  const [dailyStats, setDailyStats] = useState([]);

  // Hook pro načtení detailů - PŮVODNÍ
  useUserAppDetails();

  // Kontext aplikace - PŮVODNÍ
  const {
    userData,
    uuid,
    setAppContentType,
    setActiveItem,
    pno,
    toLocale,
    statsCurrency, // Ponecháno, i když se přímo nepoužívá
    selectedShop,
    getCurrency,
    alertMessage,
    isAlertVisible,
    showAlert, // Ponecháno
    closeAlert,
    setShowArrow, // Ponecháno
    setIsUserDataLoading, // Ponecháno
    showWelcomeModal,
  } = useContext(AppContext);

  // Efekty - PŮVODNÍ
  useEffect(() => {
    sendEvent("app_dashboard");
  }, []);

  useEffect(() => {
    if (uuid) {
      retrieveGraphData();
      retrieveGraphData30Days();
      retrieveDailyStats();
    }
  }, [uuid]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setShopForDisplay(
        userData?.shops?.find((oneShop) => oneShop.uuid === uuid)
      );
    }
    // PŮVODNÍ ZÁVISLOST: [uuid, shopForDisplay] - Vráceno na původní
    // Poznámka: Závislost na shopForDisplay zde může vést k zacyklení, pokud se setShopForDisplay volá často.
    // Doporučená závislost by byla [userData, uuid], jak bylo v předchozích verzích.
    // Ale pro dodržení "původního" kódu je vráceno. Zvažte úpravu.
  }, [uuid, shopForDisplay]);

  // Navigace - PŮVODNÍ
  const onRedirect = (where, isApp = true) => {
    window.scrollTo(0, 0);
    navigate(`/${lng}/app/${where}/`);
    if (isApp) {
      setActiveItem(where);
      setAppContentType(where);
    }
  };

  // --- Načítání dat (7 dní) ---
  const retrieveGraphData = async () => {
    try {
      const currentDate = new Date();
      const sevenDaysAgo = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      );
      const retrieveGraphDataResponse = await axiosInstance.post(
        `shop/stats/summary/${uuid}/`,
        {
          start_date: sevenDaysAgo.toISOString().split("T")[0],
          end_date: currentDate.toISOString().split("T")[0],
        }
      );
      const data = retrieveGraphDataResponse.data;

      // --- FIX: Ukládání surových ČÍSEL, bez formátování ---
      setTotalExpenses({
        ...data.totalExpenses,
        value: Number(data.totalExpenses?.value ?? 0),
      });
      setCos({ ...data.cos, value: Number(data.cos?.value ?? 0) });
      setGeneratedRevenue({
        ...data.generatedRevenue,
        value: Number(data.generatedRevenue?.value ?? 0),
      });
      setAssistedRevenue({
        ...data.assistedRevenue,
        value: Number(data.assistedRevenue?.value ?? 0),
      });
      setVisitorCount({
        ...data.visitorCount,
        value: Number(data.visitorCount?.value ?? 0),
      });
      setClickCount({
        ...data.clickCount,
        value: Number(data.clickCount?.value ?? 0),
      });
      setOrderCount({
        ...data.orderCount,
        value: Number(data.orderCount?.value ?? 0),
      });
      setConversionRatio({
        ...data.conversionRatio,
        value: Number(data.conversionRatio?.value ?? 0),
      });
    } catch (error) {
      console.log(error); // Původní logování
    }
  };

  // --- Načítání dat (30 dní) ---
  const retrieveGraphData30Days = async () => {
    try {
      const currentDate = new Date();
      const thirtyDaysAgo = new Date(
        currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
      );
      const retrieveGraphData30DaysResponse = await axiosInstance.post(
        `shop/stats/summary/${uuid}/`,
        {
          start_date: thirtyDaysAgo.toISOString().split("T")[0],
          end_date: currentDate.toISOString().split("T")[0],
        }
      );
      const data = retrieveGraphData30DaysResponse.data;

      // --- FIX: Ukládání surových ČÍSEL, bez formátování ---
      const totalExpensesValue = Number(data.totalExpenses?.value ?? 0); // Pro kontrolu níže
      setTotalExpenses30({ ...data.totalExpenses, value: totalExpensesValue });
      setCos30({ ...data.cos, value: Number(data.cos?.value ?? 0) });
      setGeneratedRevenue30({
        ...data.generatedRevenue,
        value: Number(data.generatedRevenue?.value ?? 0),
      });
      setAssistedRevenue30({
        ...data.assistedRevenue,
        value: Number(data.assistedRevenue?.value ?? 0),
      });
      setVisitorCount30({
        ...data.visitorCount,
        value: Number(data.visitorCount?.value ?? 0),
      });
      setClickCount30({
        ...data.clickCount,
        value: Number(data.clickCount?.value ?? 0),
      });
      setOrderCount30({
        ...data.orderCount,
        value: Number(data.orderCount?.value ?? 0),
      }); // Již bylo číslo
      setConversionRatio30({
        ...data.conversionRatio,
        value: Number(data.conversionRatio?.value ?? 0),
      });

      // Původní logika pro showHowToStart (používá nyní číselnou hodnotu)
      if (totalExpensesValue === 0) {
        setShowHowToStart(true);
      } else {
        setShowHowToStart(false);
      }
    } catch (error) {
      console.log(error); // Původní logování
    }
  };

  // --- Načítání denních statistik pro graf ---
  const retrieveDailyStats = async () => {
    try {
      const currentDate = new Date();
      const thirtyDaysAgo = new Date(
        currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
      );
      const retrieveDailyStatsResponse = await axiosInstance.post(
        `shop/stats/daily/${uuid}/`,
        {
          start_date: thirtyDaysAgo.toISOString().split("T")[0],
          end_date: currentDate.toISOString().split("T")[0],
        }
      );

      // Původní zpracování dat, jen zajištění čísel a zaokrouhlení
      const updatedStats = retrieveDailyStatsResponse.data
        .map((item) => {
          // FIX: Ošetření pro případ, že item je null nebo undefined
          if (!item) return null;

          const visitors = Number(item.total_visitors ?? 0);
          const conversions = Number(item.total_conversions ?? 0);
          const conversionRateValue =
            visitors > 0 ? (conversions / visitors) * 100 : 0;

          return {
            ...item, // Původní struktura zachována
            // Převedení na číslo a zaokrouhlení pro konzistenci v grafu
            total_clicks: Number(Number(item.total_clicks ?? 0).toFixed(2)),
            total_cost: Number(Number(item.total_cost ?? 0).toFixed(2)),
            total_visitors: Number(Number(item.total_visitors ?? 0).toFixed(0)), // Návštěvníci bez des. míst
            total_assisted_conversions: Number(
              Number(item.total_assisted_conversions ?? 0).toFixed(2)
            ),
            total_conversions: Number(
              Number(item.total_conversions ?? 0).toFixed(2)
            ),
            total_assisted_conversion_value: Number(
              Number(item.total_assisted_conversion_value ?? 0).toFixed(2)
            ),
            total_conversion_value: Number(
              Number(item.total_conversion_value ?? 0).toFixed(2)
            ),
            conversion_rate: Number(conversionRateValue.toFixed(2)),
          };
        })
        .filter((item) => item !== null); // FIX: Odstranění null položek, pokud API vrátí nevalidní data

      setDailyStats(updatedStats);
    } catch (error) {
      // Původní logování, upraveno pro lepší popis
      console.log("Daily stats error: ", error);
    }
  };

  // Ovládání modálního okna - PŮVODNÍ
  const toggleModal = (targetId) => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen && targetId) {
      setTimeout(() => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
          targetElement.classList.add("pulse__border__no__outline");
          setTimeout(() => {
            targetElement.classList.remove("pulse__border__no__outline");
          }, 3000);
        }
      }, 100);
    }
  };

  // Zakomentovaný handler - PŮVODNÍ
  // const handleShowArrowOnClick = () => { ... };

  // --- Renderování ---
  return (
    <>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      {showWelcomeModal && <ModalWelcome />}
      <article className="app-content app-content--dashboard">
        {/* Původní zakomentovaný box s danger item */}
        {/* <div className="item item--danger"> ... </div> */}

        {/* Sekce "Jak začít" - PŮVODNÍ struktura */}
        {showHowToStart && (
          <div className="box">
            <h2>{t("appDashboard.startWithPermoniq")}</h2>
            {/* Původní zakomentovaný progress bar */}
            {/* <div className="progress progress--app"> ... </div> */}
            <div className="box__note" style={{ color: "black" }}>
              <h3>{t("appDashboard.howToStart")}</h3>
              <p>
                <Trans
                  i18nKey="appDashboard.topUpCreditInfo"
                  components={[
                    <a
                      key="creditLink" // Přidán klíč
                      onClick={() => onRedirect("credit")}
                      className={styleForInfoModal.anchor_to_credit}
                    ></a>,
                  ]}
                />
              </p>
            </div>
          </div>
        )}

        {/* Řádek s fází učení - PŮVODNÍ struktura */}
        <div className="app-content__row">
          {/* Původní zakomentovaný box s kampaněmi */}
          {/* <div className="box"> ... </div> */}

          <div className="box learning__box">
            {orderCount30.value > 30 ? ( // FIX: Používá se číslo
              <>
                <h2>{t("appDashboard.afterLeatningFaseTitle")}</h2>
                <div className="box__note box__note--bubble">
                  <p>{t("appDashboard.afterLearningFaseInfo")}</p>
                </div>
              </>
            ) : (
              <>
                <div className={styles.learnBox}>
                  <h2 className={styles.heading}>
                    {t("appDashboard.learningFase")}
                  </h2>
                  {/* Původní zakomentovaná ikonka */}
                  {/* <div className={styles.iconBox}> ... </div> */}
                </div>
                <div className="box__note box__note--bubble">
                  <h3>{t("appDashboard.whatIsLearningFase")}</h3>
                  <p>{t("appDashboard.learningFaseInfo")}</p>
                  <ul style={{ paddingLeft: "20px", marginBottom: "20px" }}>
                    {/* FIX: Přidána kontrola pro případ, že překlad nevrátí objekt */}
                    {typeof keyPointsOfLearningFaseTitle === "object" &&
                      keyPointsOfLearningFaseTitle !== null &&
                      Object.keys(keyPointsOfLearningFaseTitle).map(
                        (key, index) => {
                          const item = keyPointsOfLearningFaseTitle[key];
                          if (
                            typeof item !== "object" ||
                            !item?.title ||
                            !item?.desc
                          )
                            return null; // Přeskočení nevalidních
                          const { title, desc } = item;
                          return (
                            <li key={index}>
                              {title}
                              <br />- {desc}
                            </li>
                          );
                        }
                      )}
                  </ul>
                  <p>{t("appDashboard.afterLearningFase")}</p>
                </div>
              </>
            )}
            <div className="campaign campaign--status">
              <span className="campaign__ico">
                <img src={googleIcon} alt="google" />
              </span>
              <span className="campaign__title">Performance max</span>
              <span className="campaign__info">
                <em>{t("appDashboard.conversionCount")}</em>
              </span>
              {/* Původní zakomentovaný span se status třídami */}
              {/* <span className={`campaign__status ...`}><em>{orderCount30.value}</em></span> */}
              {/* --- FIX: Použití CapmaignStatusBar s číslem a formátovaným textem --- */}
              <CapmaignStatusBar value={orderCount30.value}>
                {formatNumber(orderCount30.value, undefined, 0)}
              </CapmaignStatusBar>
            </div>
            {/* Původní zakomentované další kampaně */}
            {/* <div className="campaign campaign--status"> ... </div> */}
          </div>
        </div>

        {/* Původní zakomentovaná sekce "Stav měření" */}
        {/* <div className="box"> ... </ul></div> */}

        {/* Sekce Základní statistiky - PŮVODNÍ struktura a styly */}
        <div className="box">
          <h2>
            {t("appDashboard.basicStatistics")}{" "}
            <small onClick={() => toggleModal()} style={{ cursor: "pointer" }}>
              {/* FIX: Původně zde nebyl toggleModal s parametrem, ale proklik na last30Days */}
              {/* Vracíme tedy původní text, ale necháme toggleModal bez parametru */}
              {t("appDashboard.last30Days")}
            </small>
          </h2>
          <div className="row">
            <div className="col col--2">
              {/* Původní zakomentovaná legenda */}
              {/* <p className="graph"><span className="graph__legend"> ... </span></p> */}
              <div>
                {/* --- Graf - PŮVODNÍ STYLY, opravené názvy --- */}
                <ResponsiveContainer width="100%" height={300}>
                  {dailyStats.length > 0 ? (
                    <LineChart data={dailyStats} margin={{bottom: 0, top: 5, right: 0, left: 0}}>
                      <CartesianGrid
                        strokeDasharray="0"
                        strokeWidth={2}
                        vertical={false}
                      />
                      <XAxis
                        dataKey="date"
                        style={{ fontSize: 10 }}
                        interval={"equidistantPreserveStart"}
                        tickFormatter={(date) => formatDate(lng, date)}
                        minTickGap={50} // Původní hodnota
                        tickMargin={5}
                      />
                      <YAxis
                        style={{ fontSize: 10 }}
                        tickFormatter={(value) => toLocale(value)}
                      />
                      <Tooltip
                        labelFormatter={(label) => formatDate(lng, label)}
                        formatter={
                          (
                            value // Původní formatter hodnoty
                          ) =>
                            `${formatNumber(value)} ${getCurrency(
                              selectedShop?.currency ?? "CZK"
                            )}` // Přidáno ošetření pro případ, že selectedShop není
                        }
                        offset={50} // Původní offset
                      />
                      <Legend wrapperStyle={{ bottom: -20 }} />
                      <Line
                        type="monotone"
                        legendType="circle"
                        dataKey="total_conversion_value"
                        strokeWidth={2}
                        stroke="#D455CF"
                        name={t("appDashboard.chartRevenue", "Obrat")}
                      />
                      <Line
                        type="monotone"
                        legendType="circle"
                        dataKey="total_cost"
                        strokeWidth={2}
                        stroke="#2768B5"
                        name={t("appDashboard.chartExpenses", "Výdaje")}
                      />
                    </LineChart>
                  ) : (
                    // Původní placeholder
                    <div
                      style={{
                        textAlign: "center",
                        lineHeight: "300px",
                        fontSize: "18px",
                        color: "#999",
                      }}
                    >
                      No data
                    </div>
                  )}
                </ResponsiveContainer>
              </div>
            </div>
            {/* Statistiky vpravo - PŮVODNÍ struktura, opravené formátování hodnot */}
            <div className="col col--2">
              <div
                className={`${styles.toggleBtnBox} ${styleForInfoModal.stats_icon} info info--7 info__no__margin`}
                onClick={() =>
                  setTimeout(() => {
                    toggleModal("order__count__explain");
                  }, 250)
                }
              >
                <span
                  className={`pack pack--7 ${styleForInfoModal.stats_icon}`}
                  style={{
                    "--primary-hover-color": "#f2edf9",
                    "--secondary-hover-color": "#fff",
                  }}
                >
                  <span className="icon icon--basket">
                    <ReactSVG src={basketIcon} />
                  </span>
                </span>
                <span className="info__title">
                  {t("appDashboard.orderCount")}
                </span>
                <span className="info__value">
                  {/* --- FIX: Formátování čísla --- */}
                  {formatNumber(orderCount30.value, undefined, 0)}
                </span>
              </div>
              <br />
              <div
                className={`${styles.toggleBtnBox} ${styleForInfoModal.stats_icon} info info--5 info__no__margin`}
                onClick={() =>
                  setTimeout(() => {
                    toggleModal("pno__explain");
                  }, 250)
                }
              >
                <span
                  className={`pack pack--5 ${styleForInfoModal.stats_icon}`}
                  style={{
                    "--primary-hover-color": "#e5f7f8",
                    "--secondary-hover-color": "#fff",
                  }}
                >
                  <span className="icon icon--grow">
                    <ReactSVG src={target} />
                  </span>{" "}
                  {/* Použita novější ikona target */}
                </span>
                <span className="info__title">PNO</span>
                <span className="info__value">
                  <span title={`PNO`}>
                    {/* --- FIX: Formátování čísla --- */}
                    {formatNumber(cos30.value)} %
                  </span>
                  &nbsp;/&nbsp;
                  <span
                    title={t("appDashboard.targetPNO")}
                    style={{ cursor: "pointer" }}
                  >
                    {/* --- FIX: Formátování čísla --- */}
                    {formatNumber(pno)} %
                  </span>
                </span>
              </div>
              <br />
              <div
                className={`${styles.toggleBtnBox} ${styleForInfoModal.stats_icon} info info--6 info__no__margin`}
                onClick={() =>
                  setTimeout(() => {
                    toggleModal("click__count__explain");
                  }, 250)
                }
              >
                <span
                  className={`pack pack--6 ${styleForInfoModal.stats_icon}`}
                  style={{
                    "--primary-hover-color": "#fdf1e6",
                    "--secondary-hover-color": "#fff",
                  }}
                >
                  <span className="icon icon--click">
                    <ReactSVG src={clickIcon} />
                  </span>
                </span>
                <span className="info__title">
                  {t("appDashboard.clickCount")}
                </span>
                <span className="info__value">
                  {/* --- FIX: Formátování čísla --- */}
                  {formatNumber(clickCount30.value, undefined, 0)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Sekce Tipy pro růst - PŮVODNÍ struktura */}
        <div className="app-content__row">
          <div className="box how_grow_campaigns_box">
            <h2>{t("appDashboard.tipsForGrowth")}</h2>
            <div className="box__note">
              <p>{t("appDashboard.basicRules")}</p>
            </div>
            <ul className="box__list">
              <li>
                <span className="icon icon--turnon">
                  <img src={turnonIcon} alt="turnon" className="icon__svg" />
                </span>
                {t("appDashboard.firstRule")}
              </li>
              <li>
                <span className="icon icon--thumb">
                  <img src={thumbIcon} alt="thumb" className="icon__svg" />
                </span>
                {t("appDashboard.secondRule")}
              </li>
              <li>
                <span className="icon icon--feed">
                  <img src={feedIcon} alt="feed" className="icon__svg" />
                </span>
                {t("appDashboard.thirdRule")}
              </li>
              <li>
                <span className="icon icon--clock">
                  <img src={clockIcon} alt="clock" className="icon__svg" />
                </span>
                {t("appDashboard.fourthRule")}
              </li>
            </ul>
          </div>
          {/* Původní zakomentovaný box */}
          {/* <div className="box"> ... </div> */}
        </div>

        {/* Sekce Názvy produktů - PŮVODNÍ struktura */}
        <div className="box">
          <h2>{t("appDashboard.nameOfProducts")}</h2>
          <div className="box__note">
            <h3 style={{ color: "black" }}>
              {t("appDashboard.productsToPerfection")}
            </h3>
            <p>
              <Trans
                i18nKey="appDashboard.productNamingIntroduction"
                components={[<strong key="strong"></strong>]}
              />
            </p>{" "}
            {/* Přidán klíč */}
            <h3 style={{ color: "black" }}>
              {t("appDashboard.namingRecommendationTitle")}
            </h3>
            <p>{t("appDashboard.namingRecommendation")}</p>
            <ul>
              <li>{t("appDashboard.namingRecommendationPartTwo")}</li>
              <ul>
                <li>{t("appDashboard.importanceOfQuantity")}</li>
              </ul>
            </ul>
            <h3 style={{ color: "black" }}>
              {t("appDashboard.correctNamingExamplesTitle")}
            </h3>
            <ul>
              <li>{t("appDashboard.correctNamingExamples.0")}</li>
              <li>{t("appDashboard.correctNamingExamples.1")}</li>
              <li>{t("appDashboard.correctNamingExamples.2")}</li>
              <li>{t("appDashboard.correctNamingExamples.3")}</li>
              <li>{t("appDashboard.correctNamingExamples.4")}</li>
              <li>{t("appDashboard.correctNamingExamples.5")}</li>
              <li>{t("appDashboard.correctNamingExamples.6")}</li>
              <li>{t("appDashboard.correctNamingExamples.7")}</li>
            </ul>
            <p>{t("appDashboard.namingConclusion")}</p>
          </div>
        </div>

        {/* Odkaz na modal - PŮVODNÍ */}
        <p
          className="link"
          onClick={() => toggleModal()}
          style={{ cursor: "pointer" }}
        >
          {t("statisticsModal.modalLink")}
        </p>
        <ModalInfo isOpen={isModalOpen} toggleModal={toggleModal} t={t} />
      </article>
    </>
  );
};

export default AppDashboard;
