import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import he from "he";

import { AppContext } from "../context/AppContext";

import styles from "../css/CertifySection.module.css";

import googleLogo from "../img/logos/logo-google.svg";
import gmailLogo from "../img/logos/logo-gmail.svg";
import googleMapsLogo from "../img/logos/logo-maps.svg";
import googleDiscoverLogo from "../img/logos/logo-discover.svg";
import googleDisplayLogo from "../img/logos/logo-display.svg";
import youtubeLogo from "../img/logos/logo-youtube.svg";
import cssLogo from "../img/logos/logo-google-comparison-partner-v.png";
import permoniqLogo from "../img/logo-2.svg";
import googlePartnerLogo from "../img/logos/Partner-RGB.png";
import shoptetLogo from "../img/logos/logo-shoptet_certify.svg";
import upgatesLogo from "../img/logos/logo-upgates_certify.svg";
import shopifyLogo from "../img/logos/logo-shopify_certify.svg";
import woocomerceLogo from "../img/logos/logo-woocommerce_certify.svg";
import prestaShopLogo from "../img/logos/logo-prestashop_certify.svg";
import eshoprychleLogo from "../img/logos/logo-eshop-rychle_certify.svg";
import fastcentrikLogo from "../img/logos/logo-fascentrik_certify.png";
import webarealLogo from "../img/logos/logo-webareal_certify.svg";

import sendEvent from "../utils/functions/sendEvent";

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const CertifySection = () => {
  const { t } = useTranslation();

  const { isLogged } = useContext(AppContext);

  return (
    <section className="section" id={t("whatItCanDo.id")}>
      <article className="container">
        <div className="certify">
          <div
            className={`box certify__1 ${isLogged ? "add_margin_top_30" : ""}`}
          >
            <h2
              dangerouslySetInnerHTML={renderHTML(t("whatItCanDo.title"))}
            ></h2>
            <p
              dangerouslySetInnerHTML={renderHTML(t("whatItCanDo.description"))}
            ></p>
            {!isLogged && (
              <p>
                <a
                  onClick={() => {
                    sendEvent("click_try-free");
                  }}
                  href={`#${t("paths.tryFree")}`}
                  className="btn"
                  dangerouslySetInnerHTML={renderHTML(
                    t("whatItCanDo.ctaTryFree")
                  )}
                ></a>
              </p>
            )}
          </div>

          <div className="box is-connected certify__logo">
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={permoniqLogo}
                alt="PermoniQ"
                width="151"
                height="32"
                loading="lazy"
              />
            </p>
          </div>

          <div className="box is-connected certify__2">
            <h3
              dangerouslySetInnerHTML={renderHTML(
                t("whatItCanDo.services.title")
              )}
            ></h3>
            <ul className="certify__services">
              <li>
                <img
                  src={googleLogo}
                  alt="logo Google Search"
                  width="40"
                  height="40"
                  loading="lazy"
                />
                <span
                  dangerouslySetInnerHTML={renderHTML(
                    t("whatItCanDo.services.googleSearch")
                  )}
                ></span>
              </li>
              <li>
                <img
                  src={gmailLogo}
                  alt="logo Gmail"
                  width="40"
                  height="40"
                  loading="lazy"
                />
                <span
                  dangerouslySetInnerHTML={renderHTML(
                    t("whatItCanDo.services.gmail")
                  )}
                ></span>
              </li>
              <li>
                <img
                  src={googleMapsLogo}
                  alt="logo Google Maps"
                  width="40"
                  height="40"
                  loading="lazy"
                />
                <span
                  dangerouslySetInnerHTML={renderHTML(
                    t("whatItCanDo.services.googleMaps")
                  )}
                ></span>
              </li>
              <li>
                <img
                  src={googleDiscoverLogo}
                  alt="logo Google Discover"
                  width="40"
                  height="40"
                  loading="lazy"
                />
                <span
                  dangerouslySetInnerHTML={renderHTML(
                    t("whatItCanDo.services.googleDiscover")
                  )}
                ></span>
              </li>
              <li>
                <img
                  src={googleDisplayLogo}
                  alt="logo Google Display"
                  width="40"
                  height="40"
                  loading="lazy"
                />
                <span
                  dangerouslySetInnerHTML={renderHTML(
                    t("whatItCanDo.services.googleDisplay")
                  )}
                ></span>
              </li>
              <li>
                <img
                  src={youtubeLogo}
                  alt="logo Youtube"
                  width="40"
                  height="40"
                  loading="lazy"
                />
                <span
                  dangerouslySetInnerHTML={renderHTML(
                    t("whatItCanDo.services.youtube")
                  )}
                ></span>
              </li>
            </ul>
          </div>

          <div className="box is-connected certify__3">
            <h3
              dangerouslySetInnerHTML={renderHTML(
                t("whatItCanDo.certifications.title")
              )}
            ></h3>
            <ul class="certify__partners">
              <li>
                <img
                  src={shoptetLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={upgatesLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={shopifyLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={woocomerceLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={prestaShopLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={eshoprychleLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={fastcentrikLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={webarealLogo}
                  alt=""
                  width="130"
                  height="48"
                  loading="lazy"
                />
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  );
};

export default CertifySection;
