import React, { useState, useEffect, useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SHA256 from "crypto-js/sha256";
import { Helmet } from "react-helmet";
import axiosInstance from "../api/AxiosConfig";

import he from "he";

import { AppContext } from "../context/AppContext";

import Cookies from "js-cookie";

import SimpleAlert from "../components/SimpleAlert";

import icons from "../img/icons.svg";

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const RegistrationAccount = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password2Visible, setPassword2Visible] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [isPasswordLettersValid, setIsPasswordLettersValid] = useState(false);
  const [isPasswordNumberValid, setIsPasswordNumberValid] = useState(false);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  window.dataLayer = window.dataLayer || [];

  const {
    alertMessage,
    isAlertVisible,
    showAlert,
    closeAlert,
    setIsUserDataLoading,
  } = useContext(AppContext);

  useEffect(() => {
    setIsUserDataLoading(false);
  }, []);

  useEffect(() => {
    setIsPasswordLengthValid(password.length >= 8);
    setIsPasswordLettersValid(/[a-z]/.test(password) && /[A-Z]/.test(password));
    setIsPasswordNumberValid(/\d/.test(password));
    setDoPasswordsMatch(
      password !== "" && password2 !== "" && password === password2
    );
  }, [password, password2]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Regulární výraz pro validaci e-mailu
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Kontrola, zda e-mail splňuje regulární výraz
    if (!emailRegex.test(email)) {
      showAlert(
        t("tryFreeSection.errorMessages.invalidEmailFormatErrorMsg"),
        "danger"
      );
      return; // Zamezí odeslání requestu, pokud e-mail není platný
    }

    const hashedEmail = SHA256(email).toString();

    fetch("https://permoniq.com/api/user/create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        login_provider: "own",
        language: lng,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            console.error(err.email[0]);
            if (
              err.email[0] ===
              "Položka aa_user s touto hodnotou v poli email již existuje."
            ) {
              showAlert(
                t("tryFreeSection.errorMessages.userExistErrorMsg"),
                "danger"
              );
            }
            throw new Error(err.email[0]);
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const { access, refresh } = data;

        // Nastavení secure cookies s životností 7 dní
        Cookies.set("aTkn_0yD9K", access, {
          secure: true,
          sameSite: "None",
          expires: 1,
        });
        Cookies.set("rTkn_4zF7P", refresh, {
          secure: true,
          sameSite: "None",
          expires: 7,
        });

        window.dataLayer.push({
          event: "register_user",
          user: {
            email: email,
            hashed_email: hashedEmail,
          },
        });
        window?.index_conversion?.automatic_event?.push({
          name: "register_user",
        });

        // Získání hodnot z cookies pro update
        let gclid = Cookies.get("_gcl_aw")
          ? Cookies.get("_gcl_aw").split(".")[2]
          : "";
        let client_id = Cookies.get("_ga")
          ? Cookies.get("_ga").split(".")[2] +
            "." +
            Cookies.get("_ga").split(".")[3]
          : "";
        let session_id = Cookies.get("_ga_7HEEGNJE8V")
          ? Cookies.get("_ga_7HEEGNJE8V").split(".")[2]
          : "";

        // Druhý request: update pomocí axios, kde posíláme gclid, client_id a session_id
        return axiosInstance.patch("/user/update/", {
          gclid,
          client_id,
          session_id,
        });
      })
      .then((updateResponse) => {
        console.log("Update response:", updateResponse.data);
        window.scrollTo(0, 0);
        navigate(`/${lng}/app/register-shop/`);
      })
      .catch((error) => {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
        if (
          error?.message ===
          "Položka aa_user s touto hodnotou v poli email již existuje."
        ) {
          showAlert(
            t("tryFreeSection.errorMessages.userExistErrorMsg"),
            "danger"
          );
        } else {
          showAlert(
            t("tryFreeSection.errorMessages.createUserErrorMsg"),
            "danger"
          );
        }
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePassword2Visibility = () => {
    setPassword2Visible(!password2Visible);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Registrace uživatele - PermoniQ</title>
      </Helmet>
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <section className="section" id={t("paths.tryFree")}>
        <article className="container">
          <div className="contact-solo">
            <div className="box contact__1">
              <form
                action="#"
                method="post"
                autoComplete="off"
                className="form form--register"
                onSubmit={handleSubmit}
              >
                <p>
                  <label
                    htmlFor="email"
                    dangerouslySetInnerHTML={renderHTML(
                      t("tryFreeSection.form.emailLabel")
                    )}
                  ></label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    autoComplete="new-password"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </p>
                <div className="form__column">
                  <p className="form__password">
                    <label
                      htmlFor="password"
                      dangerouslySetInnerHTML={renderHTML(
                        t("tryFreeSection.form.passwordLabel")
                      )}
                    ></label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete="new-password"
                    />
                    <span
                      className="icon icon--eye"
                      onClick={togglePasswordVisibility}
                    >
                      <svg className="icon__svg">
                        <use
                          xlinkHref={`${icons}#eye`}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                        ></use>
                      </svg>
                    </span>
                  </p>
                  <p className="form__password">
                    <label
                      htmlFor="password-2"
                      dangerouslySetInnerHTML={renderHTML(
                        t("tryFreeSection.form.passwordRepeatLabel")
                      )}
                    ></label>
                    <input
                      type={password2Visible ? "text" : "password"}
                      id="password-2"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                      required
                      autoComplete="new-password"
                    />
                    <span
                      className="icon icon--eye"
                      onClick={togglePassword2Visibility}
                    >
                      <svg className="icon__svg">
                        <use
                          xlinkHref={`${icons}#eye`}
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                        ></use>
                      </svg>
                    </span>
                  </p>
                </div>
                <div className="form__column">
                  <ul className="form__check">
                    <li
                      className={isPasswordLengthValid ? "is-checked" : ""}
                      dangerouslySetInnerHTML={renderHTML(
                        t("tryFreeSection.form.passwordCriteria.length")
                      )}
                    ></li>
                    <li
                      className={isPasswordLettersValid ? "is-checked" : ""}
                      dangerouslySetInnerHTML={renderHTML(
                        t("tryFreeSection.form.passwordCriteria.letters")
                      )}
                    ></li>
                    <li
                      className={isPasswordNumberValid ? "is-checked" : ""}
                      dangerouslySetInnerHTML={renderHTML(
                        t("tryFreeSection.form.passwordCriteria.number")
                      )}
                    ></li>
                  </ul>
                  <ul className="form__check">
                    <li
                      className={doPasswordsMatch ? "is-checked" : ""}
                      dangerouslySetInnerHTML={renderHTML(
                        t("tryFreeSection.form.passwordCriteria.match")
                      )}
                    ></li>
                  </ul>
                </div>
                <p>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="gdpr"
                      id="gdpr"
                      checked={isCheckboxChecked}
                      onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                      required
                    />
                    <span className="checkbox__label">
                      {
                        <Trans
                          i18nKey={t("tryFreeSection.form.gdprLabel")}
                          components={[
                            <span
                              className="link"
                              onClick={() => {
                                navigate(`/${lng}/terms-of-service/`);
                                window.scrollTo(0, 0);
                              }}
                            ></span>,
                            <span
                              className="link"
                              onClick={() => {
                                navigate(`/${lng}/privacy-policy/`);
                                window.scrollTo(0, 0);
                              }}
                            ></span>,
                          ]}
                        />
                      }
                    </span>
                    <span className="checkbox__checker"></span>
                  </label>
                </p>
                <p className="form__submit">
                  <input
                    type="submit"
                    value={t("tryFreeSection.form.submitButton")}
                    className="btn"
                    disabled={!isCheckboxChecked}
                  />
                </p>
              </form>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default RegistrationAccount;
