export function getDateWithNextMonth() {
  // Získáme aktuální datum
  const today = new Date();

  // Vytvoříme nové datum se zvýšeným měsícem (JavaScript automaticky převede přetečení, např. prosinec + 1 → leden následujícího roku)
  const nextMonthDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  // Naformátujeme datum pomocí Intl.DateTimeFormat s použitím lokace sv-SE,
  // kde formát data je "YYYY-MM-DD"
  return new Intl.DateTimeFormat("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  }).format(nextMonthDate);
}
