import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { AppContext } from "../context/AppContext";
import axiosInstance from "../api/AxiosConfig";

import SimpleAlert from "../components/SimpleAlert";

import tooltipInfoIcon from "../img/tooltip.svg";

import ModalLoading from "../components/ModalLoading";

import BudgetPnoDialogStyles from "../css/BudgetPnoDialogStyles.module.css";

const currencySettings = {
  CZK: { symbol: "Kč", minLimit: 300, maxLimit: 20000 },
  EUR: { symbol: "€", minLimit: 12, maxLimit: 800 },
  HUF: { symbol: "Ft", minLimit: 4700, maxLimit: 320000 },
  RON: { symbol: "lei", minLimit: 60, maxLimit: 4000 },
  PLN: { symbol: "zł", minLimit: 50, maxLimit: 3300 },
};

const BudgetPnoDialog = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dialogRef = useRef(null);
  const [rangeValue, setRangeValue] = useState(20);
  const [limitValue, setLimitValue] = useState(100);
  const [currency, setCurrency] = useState("CZK");
  const { onPnoAndBudgetSet } = props;

  const tooltipIconRef = useRef(null); // Ref pro ikonu

  const {
    pno,
    setPno,
    budget,
    setBudget,
    selectedShop,
    isUserDataLoading,
    setIsUserDataLoading,
    campaignId,
    uuid,
    showAlert,
    isAlertVisible,
    closeAlert,
    alertMessage,
    currencyFromAds,
    getCurrency,
  } = useContext(AppContext);

  const currentCurrencySettings =
    currencySettings[currencyFromAds] || currencySettings["CZK"];

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (currencyFromAds) {
      setCurrency(currencyFromAds);
    }
  }, [currencyFromAds]);

  useEffect(() => {
    setLimitValue(budget);
  }, [budget]);

  useEffect(() => {
    setRangeValue(pno < 5 ? 5 : pno);
  }, [pno]);

  const handleRangeChange = (e) => {
    setRangeValue(e.target.value);
  };

  const handleLimitChange = (e) => {
    setLimitValue(e.target.value);
  };

  const closeDialog = () => {
    ref.current.close();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.target.blur();
    setIsUserDataLoading(true);

    const limit = Number(limitValue);

    // Kontrola limitu rozpočtu
    if (isNaN(limit)) {
      setIsUserDataLoading(false);
      showAlert(
        t("budgetPnoDialog.errorMessages.invalidBudgetErrorMsg"),
        "danger"
      ); // Chyba rozpočtu: neplatné číslo
      return;
    }

    if (limit < currentCurrencySettings.minLimit) {
      setIsUserDataLoading(false);
      showAlert(
        t("budgetPnoDialog.errorMessages.lowBudgetErrorMsg", {
          minLimit: currentCurrencySettings.minLimit,
          currency:
            currencySettings[currencyFromAds]?.symbol ||
            getCurrency(selectedShop?.currency),
        }),
        "danger"
      ); // Chyba rozpočtu: příliš nízký limit
      return;
    }

    if (limit > currentCurrencySettings.maxLimit) {
      setIsUserDataLoading(false);
      showAlert(
        t("budgetPnoDialog.errorMessages.highBudgetErrorMsg", {
          maxLimit: currentCurrencySettings.maxLimit,
          currency:
            currencySettings[currencyFromAds]?.symbol ||
            getCurrency(selectedShop?.currency),
        }),
        "danger"
      ); // Chyba rozpočtu: příliš vysoký limit
      return;
    }

    // Kontrola PNO
    if (rangeValue < 5) {
      setIsUserDataLoading(false);
      showAlert(
        t("budgetPnoDialog.errorMessages.lowPnoErrorMsg", { minPno: 5 }),
        "danger"
      ); // Chyba PNO: příliš nízké PNO
      return;
    }

    if (rangeValue > 50) {
      setIsUserDataLoading(false);
      showAlert(
        t("budgetPnoDialog.errorMessages.highPnoErrorMsg", { maxPno: 50 }),
        "danger"
      ); // Chyba PNO: příliš vysoké PNO
      return;
    }

    try {
      // Spustí oba update požadavky paralelně
      await Promise.all([
        axiosInstance.patch(`shop/update/${uuid}/`, {
          pno: rangeValue,
          budget: limit,
        }),
      ]);

      setPno(rangeValue);
      setBudget(limit);

      onPnoAndBudgetSet();
    } catch (error) {
      console.log(error);
      showAlert(
        t("budgetPnoDialog.errorMessages.pnoAndBudgetUpdateErrorMsg"),
        "danger"
      ); // Chyba při aktualizaci PNO a rozpočtu
    }
  };

  const toggleTooltip = (typeOfMsg) => {
    if (typeOfMsg === "pno") {
      showAlert(t("appSettings.pnoTooltip"));
    } else if (typeOfMsg === "limit") {
      showAlert(
        t("appSettings.limitTooltip", {
          budget: (budget * 30).toLocaleString("cs-CZ"),
          currency: currentCurrencySettings.symbol,
        })
      );
    }
  };

  return (
    <>
      <dialog ref={ref} className="dialog-settings">
        {isUserDataLoading && <ModalLoading />}
        {isAlertVisible && (
          <SimpleAlert
            message={alertMessage.text}
            onClose={closeAlert}
            type={alertMessage.type}
          />
        )}
        <article className="app-content">
          <div className="app-content__row">
            <div className={`box ${BudgetPnoDialogStyles.pno_budget_set_box}`}>
              <div>
                <h2>{t("appSettings.limitTitle")}</h2>
                <form
                  autoComplete="off"
                  className="form"
                  onSubmit={handleSubmit}
                >
                  <p>
                    <Trans
                      i18nKey={t("appSettings.limitExplanation")}
                      components={[<strong></strong>]}
                    />
                  </p>
                  <p className="form__currency">
                    <label htmlFor="limit">{t("appSettings.limit")}</label>
                    <input
                      type="number"
                      value={limitValue}
                      min={currentCurrencySettings.minLimit}
                      max={currentCurrencySettings.maxLimit}
                      name="limit"
                      id="limit"
                      onChange={handleLimitChange}
                    />
                    {currentCurrencySettings.symbol}
                  </p>
                </form>
              </div>
              {/* <div className='btn-box'>
                                <button onClick={closeDialog} className="btn">
                                    {t('budgetPnoDialog.close')}
                                </button>
                            </div> */}
            </div>

            <div className={`box ${BudgetPnoDialogStyles.pno_budget_set_box}`}>
              <div>
                <h2>{t("appSettings.targetPnoTitle")}</h2>
                <form autoComplete="off" className="form ">
                  <p className="snuggle">
                    <Trans
                      i18nKey="appSettings.maxPnoExplanation"
                      components={[<br />, <strong></strong>]}
                      values={{
                        rangeValue: rangeValue,
                        baseAmount: (limitValue * 30).toLocaleString("cs-CZ"),
                        currency: currentCurrencySettings.symbol,
                        turnover: (() => {
                          let baseAmount = currentCurrencySettings.baseAmount;
                          let result = ((limitValue * 30) / rangeValue) * 100;
                          let formattedResult = new Intl.NumberFormat("cs-CZ", {
                            style: "currency",
                            currency: currency,
                          }).format(result);

                          return formattedResult;
                        })(),
                      }}
                    >
                      <img
                        style={{
                          display: "inline",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => toggleTooltip("pno")}
                        src={tooltipInfoIcon}
                        ref={tooltipIconRef}
                        alt="info icon"
                      />
                    </Trans>
                  </p>
                  <p
                    className={`range js-range ${BudgetPnoDialogStyles.register_ranger}`}
                  >
                    <input
                      type="range"
                      id="range"
                      name="range"
                      min="5"
                      max="50"
                      value={rangeValue}
                      step="1"
                      onChange={handleRangeChange}
                    />
                    <span className="range__values">
                      <span>5,00&nbsp;%</span>
                      <span>50,00&nbsp;%</span>
                    </span>
                  </p>
                  {/* <p>{t('budgetPnoDialog.pnoChangeAdvice')}</p> */}
                </form>
              </div>
            </div>
            <div className="btn-box" style={{ justifyContent: "flex-end" }}>
              <button
                className="btn"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                type="submit"
              >
                {t("budgetPnoDialog.save")}
              </button>
            </div>
          </div>
        </article>
      </dialog>
    </>
  );
});

export default BudgetPnoDialog;
