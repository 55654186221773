import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import sendEvent from "../utils/functions/sendEvent";
import styles from "../css/AppInvoice.module.css";
import { useTranslation } from "react-i18next";

const AppInvoice = () => {
  const navigate = useNavigate();
  const { lng } = useParams();
  const { selectedShop } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !selectedShop?.billing_info ||
      (Array.isArray(selectedShop.billing_info) &&
        selectedShop.billing_info.length === 0)
    ) {
      navigate(`/${lng}/app/dashboard/`);
    }
  }, [selectedShop, lng, navigate]);

  useEffect(() => {
    sendEvent("app_invoice");
  }, []);

  const billingInfo = Array.isArray(selectedShop.billing_info)
    ? selectedShop.billing_info
    : [];

  // Filtrace záznamů, kde je obrat (revenue) nenulový
  const filteredBillingInfo = billingInfo.filter(
    (item) => Number(item.revenue) !== 0
  );

  const totalRevenue = filteredBillingInfo.reduce(
    (acc, cur) => acc + Number(cur.revenue),
    0
  );
  const totalFakturace = filteredBillingInfo.reduce(
    (acc, cur) => acc + Number(cur.fakturace),
    0
  );

  const currency = selectedShop?.currency || "CZK";

  const revenueFormatter = new Intl.NumberFormat("cs-CZ", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const fakturaceFormatter = new Intl.NumberFormat("cs-CZ", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  // Považujeme fakturaci za nedostupnou, pokud po filtrování neexistují žádné záznamy
  const isInvoiceNotAvailable = filteredBillingInfo.length === 0;

  // Pomocná funkce pro získání aktuálního měsíce ve formátu "MM/YYYY"
  const getCurrentMonth = () => {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const year = now.getFullYear();
    return `${month}/${year}`;
  };

  const exportCSV = () => {
    let csvContent = "";
    if (isInvoiceNotAvailable) {
      csvContent = "Měsíc,Revenue\n";
      filteredBillingInfo
        .slice()
        .reverse()
        .forEach((item) => {
          csvContent += `${item.month},${Number(item.revenue)}\n`;
        });
    } else {
      csvContent = "Měsíc,Revenue,Fakturace\n";
      filteredBillingInfo
        .slice()
        .reverse()
        .forEach((item) => {
          csvContent += `${item.month},${Number(item.revenue)},${Number(
            item.fakturace
          )}\n`;
        });
    }
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "PermoniQ.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <article className="app-content">
      <div className={styles.headingBox}>
        <h1 className={styles.mainHeading}>{t("appInvoice.heading")}</h1>
        <button
          className="btn"
          style={{ marginBottom: "0" }}
          onClick={(e) => {
            e.target.blur();
            exportCSV();
          }}
        >
          {t("appInvoice.exportCsvBtn")}
        </button>
      </div>
      <section className={"helpSection"}>
        {selectedShop && selectedShop.billing_info ? (
          <div>
            <table className={styles.billingTable}>
              <thead>
                <tr>
                  <th
                    className={`${styles.tableHeader} ${styles.tableHeaderLeft}`}
                  >
                    {t("appInvoice.month")}
                  </th>
                  <th
                    className={`${styles.tableHeader} ${styles.tableHeaderRight}`}
                  >
                    {t("appInvoice.revenue")}
                  </th>
                  {!isInvoiceNotAvailable && (
                    <th
                      className={`${styles.tableHeader} ${styles.tableHeaderRight}`}
                    >
                      {t("appInvoice.invoice")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {filteredBillingInfo.slice().reverse().map((item, index) => (
                  <tr key={index}>
                    <td className={styles.tableCell}>
                      {item.month === getCurrentMonth()
                        ? "K dnešnímu dni"
                        : item.month}
                    </td>
                    <td className={styles.tableCellRight}>
                      {revenueFormatter.format(Number(item.revenue))}
                    </td>
                    {!isInvoiceNotAvailable && (
                      <td className={styles.tableCellRight}>
                        {fakturaceFormatter.format(Number(item.fakturace))}
                      </td>
                    )}
                  </tr>
                ))}
                <tr className={styles.tableRowTotal}>
                  <td className={styles.tableCell}>{t("appInvoice.total")}</td>
                  <td className={styles.tableCellRight}>
                    {revenueFormatter.format(totalRevenue)}
                  </td>
                  {!isInvoiceNotAvailable && (
                    <td className={styles.tableCellRight}>
                      {fakturaceFormatter.format(totalFakturace)}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
            {isInvoiceNotAvailable && (
              <p className={styles.message}>
                {t("appInvoice.invoiceNotAvailable")}
              </p>
            )}
          </div>
        ) : (
          <p>{t("appInvoice.noInvoiceData")}</p>
        )}
      </section>
    </article>
  );
};

export default AppInvoice;
