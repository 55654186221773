// logout.js
import Cookies from 'js-cookie';
import clearUserDetailStorage from "../../api/AxiosConfig"

export const logout = (navigate, lng) => {
    // Remove cookies
    sessionStorage.setItem("loading", "true")
    Cookies.remove('rTkn_4zF7P');
    Cookies.remove('aTkn_0yD9K');
    Cookies.remove('utk_98x76');
    Cookies.set("access_denied")

    // Clear any user details stored in storage
    clearUserDetailStorage();

    // Navigate to the login page for the specified language
    navigate(`/${lng}/login/`);

    // Reload the page to ensure all states are reset
    // window.location.reload(true);
};
