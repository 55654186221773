import { useState } from "react";

import styles from "../css/AppContent.module.css";

import { FaRegCopy } from "react-icons/fa";
import { TiThumbsUp } from "react-icons/ti";

const InfoBoxItem = ({ label, value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      })
      .catch((err) => {
        console.error("Chyba při kopírování: ", err);
      });
  };

  return (
    <div className={styles.infoBox}>
      <span>
        {label}: <strong>{value}</strong>
      </span>
      <div className={styles.copyBox}>
        {copied ? (
          <TiThumbsUp className={`${styles.copyIcon} ${styles.shake}`} />
        ) : (
          <FaRegCopy className={styles.copyIcon} onClick={handleCopy} />
        )}
      </div>
    </div>
  );
};

export default InfoBoxItem;
