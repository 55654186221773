import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import he from "he";

import { AppContext } from "../context/AppContext";
import sendEvent from "../utils/functions/sendEvent";

const renderHTML = (html) => {
  return { __html: he.decode(html) };
};

const SetupSection = () => {
  const { t } = useTranslation();
  const [hasGoogleAccounts, setHasGoogleAccounts] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const { openModal, isLogged } = useContext(AppContext);

  const handleCheckboxChange = () => {
    setHasGoogleAccounts(!hasGoogleAccounts);
  };

  const toggleTooltip = (event) => {
    event.stopPropagation();
    setIsTooltipVisible(!isTooltipVisible);
  };

  const closeTooltip = (event) => {
    event.stopPropagation();
    setIsTooltipVisible(false);
  };

  return (
    <section className="section section--q" id={t("paths.howToSetUp")}>
      <article className="container">
        <h2
          className="title"
          dangerouslySetInnerHTML={renderHTML(t("howToSetUp.title"))}
        ></h2>
        <div className="how" id="how">
          <div className="box center">
            <h3
              dangerouslySetInnerHTML={renderHTML(
                t("howToSetUp.questions.googleAccounts.question")
              )}
            ></h3>
            <p>(Google Ads, Google Merchant Center, Google Analytics 4)</p>
            <p>
              <label className="switcher js-how">
                <input
                  type="checkbox"
                  name="checkbox"
                  checked={hasGoogleAccounts}
                  onChange={handleCheckboxChange}
                />
                <span
                  className="switcher__label is-checked"
                  dangerouslySetInnerHTML={renderHTML(
                    t("howToSetUp.questions.googleAccounts.answers.yes")
                  )}
                ></span>
                <span
                  className="switcher__label is-unchecked"
                  dangerouslySetInnerHTML={renderHTML(
                    t("howToSetUp.questions.googleAccounts.answers.no")
                  )}
                ></span>
                <span className="switcher__back"></span>
              </label>
            </p>
          </div>
          <div className="box is-connected center">
            <p
              className={hasGoogleAccounts ? "how__yes" : "how__no is-hidden"}
              dangerouslySetInnerHTML={renderHTML(
                t("howToSetUp.steps.shareAccess")
              )}
            ></p>
            <p
              className={!hasGoogleAccounts ? "how__yes" : "how__no is-hidden"}
              dangerouslySetInnerHTML={renderHTML(
                t("howToSetUp.steps.requestData")
              )}
            ></p>
          </div>
          <div className="box is-connected">
            <ul className="list">
              <li>
                <span>{t("howToSetUp.steps.cssProgram")}</span>
                <a
                  href="https://www.advisio.cz/css-program/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=setup_section"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  CSS programu
                </a>{" "}
                <span>{t("howToSetUp.steps.dataplusMeasure")}</span>
                <a
                  href="https://dataplus.advisio.cz/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=setup_section"
                  target="_blank"
                  rel="noopener"
                  className="link"
                >
                  DataPlus
                </a>
                {"."}
                <span
                  className={`tooltip ${isTooltipVisible ? "is-open" : ""}`}
                  onClick={toggleTooltip}
                >
                  <span className="tooltip__icon"></span>
                  <span className="tooltip__body">
                    <span
                      dangerouslySetInnerHTML={renderHTML(
                        t("howToSetUp.tooltip")
                      )}
                    ></span>
                    <span className="tooltip__close" onClick={closeTooltip}>
                      &times;
                    </span>
                  </span>
                </span>
              </li>
              <li
                dangerouslySetInnerHTML={renderHTML(
                  t("howToSetUp.steps.createCampaignObjects")
                )}
              ></li>
              <li
                dangerouslySetInnerHTML={renderHTML(
                  t("howToSetUp.steps.launch")
                )}
              ></li>
            </ul>
            {!isLogged && (
              <p>
                <a
                  onClick={() => {
                    sendEvent("click_try-free");
                  }}
                  href={`#${t("paths.tryFree")}`}
                  className="btn"
                  dangerouslySetInnerHTML={renderHTML(
                    t("howToSetUp.ctaTryFree")
                  )}
                ></a>
                <a
                  href={`#${t("paths.consultation")}`}
                  className="btn btn--empty js-modal"
                  onClick={() => {
                    sendEvent("click_consultation");
                    openModal();
                  }}
                  dangerouslySetInnerHTML={renderHTML(
                    t("howToSetUp.ctaConsultation")
                  )}
                ></a>
              </p>
            )}
          </div>
        </div>
      </article>
    </section>
  );
};

export default SetupSection;
