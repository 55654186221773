import React, { useEffect, useContext } from "react";

import { AppContext } from "../context/AppContext";
import sendEvent from "../utils/functions/sendEvent";
import ModalLoading from "./ModalLoading";
import SimpleAlert from "./SimpleAlert";
import { useTranslation } from "react-i18next";

const AppProducts = ({ pageEvent, mainHeading, children, childrenBox }) => {
  const { t } = useTranslation();

  const {
    isUserDataLoading,
    msgForSend,
    isAlertVisible,
    alertMessage,
    closeAlert,
  } = useContext(AppContext);

  useEffect(() => {
    sendEvent(pageEvent);
  }, []);

  return (
    <>
      {isUserDataLoading && <ModalLoading message={msgForSend} />}

      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <article className="app-content">
        {mainHeading && <h1>{mainHeading}</h1>}
        {children}
      </article>
    </>
  );
};

export default AppProducts;
