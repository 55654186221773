import React from 'react';

const ButtonBtn = ({ className, children, ...props }) => {
  return (
    <div style={{
      display: "flex",
      justifyContent: "right",
      marginBlock: "10px"
    }}>
      <button className={`btn ${className}`} {...props}>
        {children || 'Tlačítko'}
      </button>
    </div>
  );
};

export default ButtonBtn;
